import React from "react";
import Triglyphe from "./triglyphe";

const Triglyphes = (props) => {
  const { name, dimension, ...others } = props;
  return (
    <group name={name}>
      {[...Array(40)].map((item, index) => (
        <Triglyphe
          key={index}
          index={index}
          name={`${dimension}-triglyphe_${index + 1}`}
          rotation={[0, (Math.PI / 20) * (index + 1), 0]}
          dimension={dimension}
          {...others}
        />
      ))}
    </group>
  );
};

export default Triglyphes;
