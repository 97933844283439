import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useQueriesAPI from "../../hooks/useQueriesAPI";
import useQueries from "../../hooks/useQueries";
import { Chip, Avatar } from "@mui/material";
import { getAnnotedFragments } from "../../../outils";

const Couches = (props) => {
  const { couche } = props;

  const {
    data: dataDefinitions,
    isSuccess: isSuccessDefinitions,
    isLoading: isLoadingDefinitions,
  } = useQueriesAPI(`palettes/${props.couche}`);

  const {
    data: dataFragments,
    isSuccess: isSuccessFragments,
    isLoading: isLoadingFragments,
  } = useQueries(`/json/annotationsParFragment.json`);

  const couches = {
    iconographie: { title: "iconographie", suffixe: "00" },
    materialite: { title: "matérialité", suffixe: "ff" },
  };

  const MyChip = (props) => {
    const { hex, descripteur } = props;
    const navigate = useNavigate();

    const fragments = getAnnotedFragments(
      `${hex}${couches[couche].suffixe}`,
      dataFragments[couche]
    );

    const handleClick = (e, hex) => {
      e.preventDefault();
      let code = hex.slice(1);
      navigate(`/fragments/${couche}/${code}`); // Navigate to the new URL
    };

    return (
      <Chip
        avatar={
          fragments?.length ? (
            <Avatar sx={{ bgcolor: `slategrey` }}>{fragments?.length}</Avatar>
          ) : null
        }
        sx={{ m: 0.5 }}
        label={descripteur}
        variant="outlined"
        onClick={(e) => handleClick(e, hex)}
        clickable
        disabled={!fragments?.length}
      />
    );
  };

  return (
    isSuccessDefinitions &&
    isSuccessFragments && (
      <>
        <div className="entete">{couches[couche].title}</div>
        <div className="list-annotations">
          {dataDefinitions
            .filter((x) => x.descripteur !== null)
            .map(
              (item, index) => (
                <MyChip key={index} {...item} />
              )

              // item.nom
            )}
        </div>
      </>
    )
  );
};

export default Couches;
