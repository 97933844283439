import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function ArchitraveInterieur(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "/modeles/tholos/architrave_interieur.glb"
  );

  return (
    <group ref={group} {...props} dispose={null} name="architrave-interieur">
      <mesh
        name="architrave003"
        castShadow
        receiveShadow
        geometry={nodes.architrave003.geometry}
        material={materials.marbre}
        position={[0, 6.694, 0]}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/architrave_interieur.glb");
