import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Couvrement(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modeles/tholos/couvrement.glb");

  return (
    <group ref={group} {...props} dispose={null} name="couvrement">
      <mesh
        name="couvrement001"
        castShadow
        receiveShadow
        geometry={nodes.couvrement001.geometry}
        material={materials.marbre}
        position={[0, 6.694, 0]}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/couvrement.glb");
