import React from "react";
import { Avatar, Badge } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import useModelStore from "../../../../stores/useModelStore";

const BadgeSearch = () => {
  const { filteredFragments } = useModelStore();
  return (
    <Badge badgeContent={filteredFragments.length} max={999} color="primary">
      <Avatar>
        <ManageSearchIcon color="default" />
      </Avatar>
    </Badge>
  );
};

export default BadgeSearch;
