import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Corniche(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modeles/tholos/corniche.glb");

  return (
    <group ref={group} {...props} dispose={null} name="corniche">
      <mesh
        name="corniche002"
        castShadow
        receiveShadow
        geometry={nodes.corniche002.geometry}
        material={materials.marbre}
        position={[-6.692, 7.339, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/corniche.glb");
