import React, { useState, useEffect, Suspense } from "react";
import { Pagination } from "@mui/material";
import useUiStore from "../../../../stores/useUiStore";
import useModelStore from "../../../../stores/useModelStore";

const PaginationFragments = (props) => {
  //   const { pagesCount } = props;
  const { paramsPagination, setParamsPagination } = useUiStore();

  const { filteredFragments } = useModelStore();

  const { grid } = paramsPagination;

  const [pagesCount, setPagesCount] = useState();

  useEffect(() => {
    let pagesCount = Math.ceil(filteredFragments.length / grid);
    pagesCount === 0 ? 1 : pagesCount;
    let currentPage = paramsPagination?.page === 0 ? 1 : paramsPagination?.page;
    pagesCount < currentPage
      ? setParamsPagination({
          ...paramsPagination,
          page: pagesCount === 0 ? 1 : pagesCount,
        })
      : null;

    setPagesCount(pagesCount);
  }, [grid, filteredFragments.length]);

  const [page, setPage] = useState(1);

  const handlePage = (e, value) => {
    let newValue = value;

    setParamsPagination({
      ...paramsPagination,
      page: newValue,
      start: grid * newValue - grid,
      end: grid * newValue,
    });
    setPage(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {
        pagesCount > 1 ? (
          <Pagination
            count={pagesCount}
            variant="outlined"
            page={
              paramsPagination?.page === 0 ? 1 : paramsPagination?.page
              // pagesCount < paramsPagination?.page
              //   ? pagesCount
              //   : paramsPagination?.page
            }
            onChange={handlePage}
          />
        ) : null
        //  (
        //   <Pagination
        //     count={1}
        //     variant="outlined"
        //     page={1}
        //     onChange={handlePage}
        //   />
        // )
      }
    </div>
  );
};

export default PaginationFragments;
