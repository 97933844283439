import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useHistoryBack = () => {
  const navigate = useNavigate();

  return useEffect(() => {
    const handleBack = (e) => {
      if (e.key === "Backspace") {
        navigate(-1);
      }
    };
    window.addEventListener("keydown", handleBack);
    return () => {
      window.removeEventListener("keydown", handleBack);
    };
  }, []);
};

export default useHistoryBack;
