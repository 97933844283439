import React from "react";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import { Drawer, Box, Typography, Stack, Avatar, Badge } from "@mui/material";

const Head = (props) => {
  const { text, variant } = props;

  const options = {
    replace(domNode) {
      let { attribs, children, name } = domNode;

      if (!attribs) {
        return;
      }

      if (name === "hi") {
        switch (attribs.rend) {
          case "italic":
            return <em>{domToReact(children, options)}</em>;
        }
      }
    },
  };

  let parsedText = parse(text, options);

  return <Typography variant={variant}>{parsedText}</Typography>;
};

export default Head;
