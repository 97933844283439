import { useMemo } from "react";
import { setRGBtoHex } from "../../outils";

function getImgData(ctx, size, uv) {
  // const imgData = ctx.getImageData(0, 0, ctx.width, ctx.height);

  //const scale = size / ctx.canvas.height;

  // console.log(size, ctx.canvas.height);

  //ctx.scale(scale, scale);

  // console.log(ctx.canvas);

  // const imgData = ctx.getImageData(0, 0, size, size);

  const imgData = ctx.getImageData(0, 0, size, size);

  let x = Math.floor(uv.x * size);
  let y = Math.floor(uv.y * size);
  let index = (y * imgData.width + x) * 4;
  let r = imgData.data[index];
  let g = imgData.data[index + 1];
  let b = imgData.data[index + 2];
  let a = imgData.data[index + 3];

  const color = setRGBtoHex(r, g, b);
  return color;

  // PB de précision avec THREE.Color
  // const color = new THREE.Color(`rgb(${r}, ${g}, ${b})`);
  // return color.getHexString();
}

export default getImgData;
