import React, { useState, useEffect } from "react";
import { Box, Fab, Badge, Tooltip } from "@mui/material";

import TuneIcon from "@mui/icons-material/Tune";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import RefreshIcon from "@mui/icons-material/Refresh";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOffIcon from "@mui/icons-material/LocationOff";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocationOffOutlinedIcon from "@mui/icons-material/LocationOffOutlined";

import NotesIcon from "@mui/icons-material/Notes";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DescriptionIcon from '@mui/icons-material/Description';

import useUiStore from "../../../../stores/useUiStore";

const FragmentFabs = (props) => {
  const {
    handleSettings,
    handleFitToSphere,
    toogleAnnotations,
    setOpenNotice,
    openNotice,
    annotationsOn,
  } = props;

  const [triggerReframe, setTriggerReframe] = useState(false);
  const paramsFragment = useUiStore((state) => state.paramsFragment);

  const backgroundColor = "#FFFFFF66";

  const handleFullScreenChange = () => {
    Boolean(document.fullscreenElement)
      ? document.exitFullscreen()
      : document.body.requestFullscreen();
  };

  const handleReframe = () => {
    setTriggerReframe(!triggerReframe);
    useUiStore.setState(
      (state) => (state.paramsFragment.reframe = { value: triggerReframe })
    );
  };

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }} id="fabs">
      <Tooltip title="Réglages">
        <Fab
          size="small"
          // title="Réglages"
          onClick={handleSettings(true)}
          sx={{ backgroundColor: backgroundColor }}
        >
          <TuneIcon />
        </Fab>
      </Tooltip>

      <Tooltip
        title={
          Boolean(document.fullscreenElement)
            ? "Sortir du plein écran"
            : "Plein écran"
        }
      >
        <Fab
          size="small"
          // title={
          //   Boolean(document.fullscreenElement)
          //     ? "Sortir du plein écran"
          //     : "Plein écran"
          // }
          sx={{ backgroundColor: backgroundColor }}
          onClick={handleFullScreenChange}
        >
          {Boolean(document.fullscreenElement) ? (
            <FullscreenExitIcon />
          ) : (
            <FullscreenIcon />
          )}
        </Fab>
      </Tooltip>
      <Tooltip title="Recentrer">
        <Fab
          size="small"
          // title="Recentrer"
          onClick={handleFitToSphere}
          sx={{ backgroundColor: backgroundColor }}
        >
          <CenterFocusWeakIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Annotations">
        <Fab
          size="small"
          // title="Recentrer"
          onClick={toogleAnnotations}
          sx={{ backgroundColor: backgroundColor }}
        >
          {annotationsOn ? (
            <LocationOnOutlinedIcon />
          ) : (
            <LocationOffOutlinedIcon />
          )}
        </Fab>
      </Tooltip>
      <Tooltip title="Notice">
        <Fab
          // disabled={openNotice}
          size="small"
          // title="Recentrer"
          onClick={() => setOpenNotice(!openNotice)}
          sx={{ backgroundColor: openNotice ? "#FFFFFF" : backgroundColor }}
        >
          <DescriptionOutlinedIcon />
         {/* {openNotice ? <DescriptionIcon /> : <DescriptionOutlinedIcon />} */}
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default FragmentFabs;
