import React, { useState } from "react";

import { Switch, FormControlLabel, FormGroup } from "@mui/material";

import useUiStore from "../../../../stores/useUiStore";

const FragmentSwitches = () => {
  const { paramsFragment, setParamsFragment } = useUiStore();

  const MySwitch = (props) => {
    const { field, value, ...previousProps } = props;
    const { label } = previousProps;

    const [localValue, setLocalValue] = useState(value);

    const handleValue = (e) => {
      setLocalValue(e.target.checked);

      setParamsFragment({
        ...paramsFragment,
        [field]: { ...previousProps, value: !value },
      });
    };

    return (
      <FormControlLabel
        labelPlacement="start"
        control={
          <Switch
            size="small"
            checked={localValue}
            onChange={handleValue}
            sx={{ mx: 1 }}
          />
        }
        label={label}
      />
    );
  };

  return (
    <FormGroup row>
      {Object.keys(paramsFragment)
        .filter((item) => paramsFragment[item].type === "switch")
        .map((item, index) => (
          <MySwitch key={index} field={item} {...paramsFragment[item]} />
        ))}
    </FormGroup>
  );
};

export default FragmentSwitches;
