import React from "react";
import Head from "./head";
import Paragraphe from "./paragraphe";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const BibliographieAccordion = (props) => {
  return (
    <Accordion
      key={props[`@_xml:id`]}
      id={props[`@_xml:id`]}
      type={props[`@_type`]}
    >
      <AccordionSummary>
        {props.head ? <Head text={props.head} variant="overline" /> : null}
      </AccordionSummary>
      <AccordionDetails>
        {props.bibl
          ? props.bibl.map((b, index) => (
              <Paragraphe
                key={b[`@_xml:id`]}
                id={b[`@_xml:id`]}
                text={b[`#text`]}
              />
            ))
          : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default BibliographieAccordion;
