import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import useModelStore from "../../../stores/useModelStore";
import useDefinitionStore from "../../../stores/useDefinitionStore";

import NoticeDrawer from "../notices/ui/noticeDrawer";

import { checkIfUrlExists } from "../../../outils";

import NoticeMarcade from "../notices/noticeMarcade";
import NoticeMarcadeTEI from "../notices/noticeMarcadeTEI";
import NoticeMarcadeFastXmlParser from "../notices/noticeMarcadeFastXmlParser";
import { ThemeContext } from "@emotion/react";

import CanvasFragment from "./canvasFragment";
import CanvasCouche from "./canvasCouche";
import FragmentFabs from "./ui/fragmentFabs";
import FragmentSettingsCard from "./ui/fragmentSettingCard";
import FragmentTabs from "./ui/fragmentTabs";

const Fragment = () => {
  const params = useParams();
  const { numero } = params;
  const navigate = useNavigate();

  const [openSettings, setOpenSettings] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [fitToSphere, setFitToSphere] = useState(false);
  const [annotationsOn, setAnnotationsOn] = useState(false);

  const currentCouche = useDefinitionStore((state) => state.currentCouche);
  const setCurrentCouche = useDefinitionStore(
    (state) => state.setCurrentCouche
  );

  const couche = {
    materialite: "matérialité",
    iconographie: "iconographie",
  };

  const handleSettings = (value) => (e) => {
    setOpenSettings(value);
  };

  const handleNotice = () => {
    setOpenNotice(!openNotice);
  };

  const handleFitToSphere = () => {
    setFitToSphere(!fitToSphere);
  };

  const handleTabs = (e, newValue) => {
    setCurrentCouche(newValue);
  };

  const toogleAnnotations = () => {
    setAnnotationsOn(!annotationsOn);
  };

  const baseUrl = import.meta.env.VITE_SERVER_API;

  const fragments = useModelStore((state) => state.fragments);

  const fragment = fragments.find(
    (frag) => frag.numero_inventaire_apo === numero
  );

  let isFragment;
  // let isNotice = checkIfUrlExists(`/xml/marcade_${fragment}.xml`);

  fragment?.gltf && fragment?.gltf_5k
    ? (isFragment = true)
    : (isFragment = false);

  const width = 800;

  return fragment ? (
    <>
      <div
        id="reglages-fragments"
        sx={{ visibility: openSettings ? "visible" : "hidden" }}
      >
        <FragmentSettingsCard
          open={openSettings}
          handleSettings={handleSettings}
        />
      </div>
      <NoticeDrawer
        open={openNotice}
        setOpenNotice={setOpenNotice}
        width={width}
        fragment={fragment?.numero_inventaire_3d}
      />

      {/* <CanvasCouche /> */}
      <div className="entete">n°{numero}</div>
      {!openNotice ? (
        <div className="sous-entete">{couche[currentCouche]}</div>
      ) : null}

      <CanvasFragment
        name={isFragment ? fragment.numero_inventaire_3d : null}
        rotationFragment={isFragment ? fragment.rotation_xyz : null}
        width={openNotice ? width : 0}
        fitToSphere={fitToSphere}
        handleFitToSphere={handleFitToSphere}
        setFitToSphere={setFitToSphere}
        setOpenNotice={setOpenNotice}
        couche={currentCouche}
        annotationsOn={annotationsOn}
      />
      <footer>
        <div className="footer">
          <span className="alignLeft">
            <FragmentFabs
              handleSettings={handleSettings}
              handleFitToSphere={handleFitToSphere}
              toogleAnnotations={toogleAnnotations}
              setOpenNotice={setOpenNotice}
              openNotice={openNotice}
              annotationsOn={annotationsOn}
            />
          </span>

          <span className="alignRight"></span>
        </div>
      </footer>

      {!openNotice ? (
        <div
          style={{
            flexGrow: 1,
            position: "absolute",
            bottom: "0px",
            left: "50%",
            transform: "translate(-50%, -0%)",
            display: "inline",
            zIndex: 10,
          }}
        >
          <FragmentTabs handleChange={handleTabs} value={currentCouche} />
        </div>
      ) : null}
    </>
  ) : null;
};

export default Fragment;
