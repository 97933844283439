import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useCallback,
  Suspense,
} from "react";

import { useNavigate } from "react-router-dom";

import * as THREE from "three";
import {
  useGLTF,
  Html,
  CameraControls,
  shaderMaterial,
} from "@react-three/drei";
import { useThree } from "@react-three/fiber";

import {
  useCanvas,
  useOnBeforeCompile,
  useOnBeforeRender,
} from "../../components/hooks";

import useModelStore from "../../stores/useModelStore";
import useDefinitionStore from "../../stores/useDefinitionStore";
import useThesoStore from "../../stores/useThesoStore";

import {
  getImgData,
  getPixDefinition,
  getPalette,
  getDefinitions,
} from "../../components/CanvasElement";

import {
  vertexShaderReplacements,
  fragmentShaderReplacements,
} from "../../shaders/replacements";

import { label, list } from "../ui";
import { useControls } from "leva";

import IndexedPlane from "./indexedPlaneFiber"; // deux architectures possible, avec DREI ou FIBER

export default function Model_sansLeva(props) {
  const { modelFileName, layers, currentLayer, couche } = props;

  const server_api = `${import.meta.env.VITE_SERVER_API}`;
  const navigate = useNavigate();

  const setCanvasSize = useModelStore((state) => state.setCanvasSize);
  const canvasSize = useModelStore((state) => state.canvasSize);

  const { iconographie: defIconographie, materialite: defMaterialite } =
    useDefinitionStore();

  const opentheso = useThesoStore((state) => state.opentheso);
  const myTheso = opentheso.filter((obj) => obj.notation);

  const canvasRef = useRef(document.getElementById("picking-canvas"));

  const context = useRef(
    canvasRef.current.getContext("2d", { willReadFrequently: true })
  );

  // LAYERS
  const { uTexBase, uTexBlank, uTexIconographie, uTexMaterialite } = layers;
  // console.log(uTexBase);

  let calque;

  const setLayers = () => {
    if (currentLayer === "base") {
      calque = uTexBlank;
    } else {
      calque = [uTexBase, uTexIconographie, uTexMaterialite].find(
        (item, index) => item.name.split("_")[1] === currentLayer
      );
    }
  };

  var pathToModel = `/modeles/${modelFileName}/${modelFileName}.glb`;
  var pathToDraco = "/draco/";

  const [hover, setHover] = useState();
  const [myDefinitions, setMyDefinitions] = useState();

  const mouseUV = useRef(new THREE.Vector2());

  /**
   *
   * DOUBLE CLICK
   */

  const DoubleClickHandler = (e) => {
    const { uv } = e;

    const size = calque.source.data.height;
    pixHEX.current = getImgData(context.current, size, uv);
    console.log(
      getPixDefinition(pixHEX.current, definitions.current),
      pixHEX.current
    );

    const thesoID = myTheso.find(
      (obj) => obj.notation[`#text`] === `#${pixHEX.current}${couche}`
    );

    const idc = thesoID.identifier[`#text`];
    navigate(`/thesaurii/${currentLayer}/${idc}`);
  };

  /**
   *
   * ON MOUSE OVER
   */

  const onMouseOver = (e) => {
    e.stopPropagation();
    setHover(true);
    document.body.style = "cursor : crosshair";

    const { uv } = e;

    mouseUV.current = uv;

    const size = calque.source.data.height;

    pixHEX.current = getImgData(context.current, size, uv);

    // console.log("second :", pixHEX.current);
    // let def = getPixDefinition(pixHEX.current, definitions.current);

    let def =
      typeof getPixDefinition(pixHEX.current, definitions.current) === "string"
        ? (definition.current = getPixDefinition(
            pixHEX.current,
            definitions.current
          ))
        : null;

    // console.log(def);

    label(def);
  };

  /**
   *
   * ON MOUSE OUT
   */

  const onMouseOut = (e) => {
    e.stopPropagation();
    setHover(false);
    document.body.style = "cursor : auto;";
    definition.current = "";
    label(definition.current, layer.current);
  };

  // // FAKE CONTROLS
  const { myBaseColor } = {
    myBaseColor: "#ffffff",
  };

  // CONTROLS
  const { mixFactor } = useControls("Modèle", {
    mixFactor: {
      label: "annotation",
      value: 1.0,
      min: 0.0,
      max: 1.0,
      step: 0.01,
    },
  });

  // Loader
  const model = useGLTF(pathToModel, pathToDraco);
  const { nodes } = model;

  const listMeshes = Object.values(nodes).filter((node) => node.isMesh);
  const group = useRef();
  const meshRef = useRef();

  const cameraControlsRef = useRef();

  useEffect(() => {
    cameraControlsRef.current.fitToSphere(group.current, true);
  }, [modelFileName]);

  // const canvasRef = useRef(document.getElementById("picking-canvas"));

  // const context = useRef(
  //   canvasRef.current.getContext("2d", { willReadFrequently: true })
  // );

  setLayers();

  // REF

  const definitions = useRef();
  const definition = useRef();
  const layer = useRef();
  const pixHEX = useRef();

  useCanvas(context, calque);

  const textureRef = useRef();
  const materialRef = useRef();

  //
  const OBR = useOnBeforeRender(uTexBase.uuid, modelFileName);

  //

  // ShaderReplacement before compile

  const uniforms = {
    uTexLayer: { value: calque },
    uTexBase: { value: uTexBase },
    uTexBlank: { value: uTexBlank },
    uMixFactor: { value: mixFactor },
    uEmissive: { value: new THREE.Color(myBaseColor) },
  };
  const dependencies = [calque.uuid, mixFactor, myBaseColor, uTexBase];

  const OBC = useOnBeforeCompile(
    vertexShaderReplacements,
    fragmentShaderReplacements,
    uniforms,
    dependencies
  );

  const propsPlane = {
    calque,
    uTexBase,
    uTexBlank,
    mixFactor,
    myBaseColor,
    mouseUV,
  };

  //  Material
  // const material = useMemo(() => {
  //   const m = new THREE.MeshStandardMaterial();
  //   m.onBeforeCompile = OBC;

  //   return m;
  // }, [OBC, modelFileName]);

  useEffect(
    () =>
      void (materialRef.current.dispose(),
      (materialRef.current.needsUpdate = true),
      (materialRef.current.onBeforeCompile = OBC)),
    [OBC]
  );

  //

  function setLayerToDef(calque) {
    layer.current = calque.name.split("_")[1].toString();

    switch (layer.current) {
      case "iconographie":
        definitions.current = defIconographie;
        // definitions.current = fetchDefinitions(iconographie);
        // console.log(fetchDefinitions(layer.current));
        break;
      case "materialite":
        definitions.current = defMaterialite;
        // definitions.current = fetchDefinitions(materialite);
        break;
      default:
        definitions.current = null;
    }

    // switch (layer.current) {
    //   case "blank":
    //     definitions.current = null;
    //     break;
    //   default:
    //     definitions.current = fetchDefinitions(layer.current);
    //     console.log(definitions.current);
    // }

    // if (layer.current === "blank") {
    //   setMyDefinitions(null);
    // } else {
    //   fetchDefinitions(layer.current);
    // }
  }

  // const imgData = useMemo(
  //   () =>
  //     context.current.getImageData(
  //       0,
  //       0,
  //       calque.source.data.height,
  //       calque.source.data.height
  //     ),
  //   [calque, modelFileName]
  // );

  //
  // Set Datas according current layer
  useEffect(() => {
    setLayerToDef(calque);
    let palette = getPalette(context.current, canvasSize);

    let currentDefinitions = getDefinitions(palette, definitions.current);
    //console.log("palette", palette);
    list(currentDefinitions, layer.current);
    !hover ? label("", layer) : null;
  }, [calque.uuid, modelFileName, canvasSize]);

  return (
    <group
      {...props}
      ref={group}
      name={modelFileName}
      dispose={null}
      onDoubleClick={DoubleClickHandler}
      onPointerMove={onMouseOver}
      onPointerOver={() => setHover(true)}
      onPointerOut={onMouseOut}
    >
      <CameraControls
        ref={cameraControlsRef}
        minDistance={0.2}
        // maxDistance={3}
      />
      {/* <IndexedPlane {...propsPlane} /> */}
      {listMeshes.map((mesh, index) => (
        <mesh
          ref={meshRef}
          castShadow
          receiveShadow
          key={index}
          name={mesh.name}
          geometry={mesh.geometry}
          position={mesh.position}
          rotation={mesh.rotation}
          //onBeforeRender={OBR}
        >
          <meshStandardMaterial
            name={`${modelFileName}_mat`}
            ref={materialRef}
            color={myBaseColor}
            // normalMap={normalMap ? normalMap : null}
            onBeforeCompile={OBC}
            needsUpdate
          />
        </mesh>
      ))}
    </group>
  );
}
