import React, { useEffect, useRef, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { MathUtils } from "three";

import { useProgress } from "@react-three/drei";

const loadingBarElement = document.querySelector(".loading-bar");

const Overlay = () => {
  //const active = useProgress((state) => state.active);
  const progress = useProgress((state) => state.progress);

  const meshRef = useRef();
  const matRef = useRef();

  const uniforms = useMemo(
    () => ({
      uAlpha: { value: 1.0 },
    }),
    []
  );
  let ratio = 0;

  // useEffect(() => {
  //   ratio = progress / 100;

  //   progress >= 100
  //     ? window.setTimeout(() => {
  //         (loadingBarElement.style.transform = "scaleX(0)"),
  //           // (loadingBarElement.style.transformOrigin = "top right"),
  //           //(loadingBarElement.style.transition ="transform 1.5s ease-in-out");

  //           loadingBarElement.classList.add("ended"),
  //           (loadingBarElement.style.transform = "");
  //       }, 500)
  //     : (loadingBarElement.classList.remove("ended"),
  //       (loadingBarElement.style.transform = `scaleX(${ratio})`));
  //   // (loadingBarElement.style.transformOrigin = "top left"),
  //   loadingBarElement.style.transition = "transform 0.5s";
  // }, [progress]);

  const fragmentShader = `
    uniform float uAlpha;
    void main()
    {
      gl_FragColor = vec4(0.32,0.32,0.32, uAlpha);
    }`;
  const vertexShader = `
    void main()
    {
      gl_Position = vec4(position, 1.0);
    }`;

  useFrame((state, delta) => {
    progress === 100
      ? (meshRef.current.material.uniforms.uAlpha.value = MathUtils.damp(
          meshRef.current.material.uniforms.uAlpha.value,
          0.0,
          1.0,
          delta * 0.75
        ))
      : null;
  });

  console.log(progress);

  return (
    <mesh name="Overlay" ref={meshRef}>
      <planeGeometry args={[2, 2]} name="overlay-plane" />
      <shaderMaterial
        ref={matRef}
        name="overlay-material"
        transparent={true}
        uniforms={uniforms}
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        uniformsNeedUpdate
      />
    </mesh>
  );
};

export default Overlay;
