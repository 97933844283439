import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useLayoutEffect,
  Suspense,
} from "react";
import { useThree } from "@react-three/fiber";
import { useProgress } from "@react-three/drei";
import useUiStore from "../../../stores/useUiStore";
import useModelStore from "../../../stores/useModelStore";
import Cell from "./cell";

const Grid = (props) => {
  const { viewport } = useThree();
  const { cellSize, fragments } = props;

  const { setParamsPagination, paramsPagination } = useUiStore();
  const { filter, filteredFragments } = useModelStore();
  const { page, start, end } = paramsPagination;

  const rows = Math.floor(viewport.height / cellSize) - 2;
  const columns = Math.floor(viewport.width / cellSize) - 2;
  const grid = rows * columns;

  // const mySlicedFragments = useRef();
  // mySlicedFragments.current = fragments.slice(0, grid);
  //mySlicedFragments.current = fragments;

  // const Loader = () => {
  //   const { active, progress, errors, item, loaded, total } = useProgress();
  //   // console.log(active, progress, errors, item, loaded, total);
  // };

  useEffect(() => {
    setParamsPagination({
      ...paramsPagination,
      grid: grid,
      // page: page === 0 ? 1 : page,
    });
  }, [grid]);

  function setGrid(index, columns, cellSize) {
    let rang = Math.floor(index / columns);
    let place = index - rang * columns;
    let coef = cellSize * 1.2;
    let dX = (coef / 2) * columns - coef / 2;
    let dY = coef;

    let x = place * coef - dX;
    let y = -rang * coef + dY;
    let z = 0;

    return [x, y, z];
  }

  // useEffect(() => {
  //   mySlicedFragments.current = fragments.slice(start, end);
  // }, [start, end, grid, page]);

  // useEffect(() => {
  //   myFragments.current = filteredFragments;
  // }, [JSON.stringify(filter)]);

  // const myCells = useMemo(() => {
  //   // return fragments.slice(grid * page - grid, grid * page);
  //   return mySlicedFragments.current.slice(start, end);
  // }, [page, start, end, JSON.stringify(filter)]);
  // useEffect(() => {
  //   mySlicedFragments.current = fragments.slice(start, end);
  // }, [JSON.stringify(paramsPagination)]);

  return (
    <group name="Grille">
      {fragments.slice(grid * page - grid, grid * page).map((item, index) => (
        <Suspense fallback={null} key={item.numero_inventaire_3d}>
          <Cell
            key={item.numero_inventaire_3d}
            name={item.numero_inventaire_apo}
            filename={item.numero_inventaire_3d}
            preview={item.gltf_5k}
            sujet={item.sujet}
            position={setGrid(index, columns, cellSize)}
            cellSize={cellSize}
            rotationFragment={item.rotation_xyz}
            translationFragment={item.translation_xyz}
            filter={filter}
          />
        </Suspense>
      ))}
    </group>
  );
};

export default Grid;
