import React, { useState } from "react";
import useModelStore from "../../stores/useModelStore";
import { useControls } from "leva";

const Canvas2d = (props) => {
  const { visibility } = useControls("Canvas 2D", {
    visibility: {
      label: "Visible",
      value: false,
    },
  });
  const { id, size } = props;

  const canvasSize = useModelStore((state) => state.canvasSize);

  return (
    <canvas
      id={id}
      style={{
        visibility: visibility ? "visible" : "hidden",
        height: `${size}px`,
        width: `${size}px`,
      }}
      height={canvasSize}
      width={canvasSize}
    ></canvas>
  );
};

export default Canvas2d;
