varying vec2 vUv;
uniform sampler2D uCouche;
uniform vec3 uColor;
uniform float uNoticeActive;


void main() {

  vec4 couche = texture2D( uCouche, vUv );  

  gl_FragColor = mix(vec4(couche.r,couche.g,couche.b,1.0),vec4(uColor, 1.0), uNoticeActive);
}