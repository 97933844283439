import React from "react";
import Fabs from "./fabs";

const Footer = (props) => {
  return (
    <footer style={{ pointerEvents: "none" }}>
      <div className="footer">
        <span className="alignLeft">
          <Fabs {...props} />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
