import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";

import App from "./app";
import "./style.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const root = ReactDOM.createRoot(document.querySelector("#root"));
const env = import.meta.env.VITE_NODE_ENV;

const ClientProvider = () => {
  const client = new QueryClient();
  return (
    <QueryClientProvider client={client}>
      <App />
    </QueryClientProvider>
  );
};

const ViewDev = () => {
  return (
    <BrowserRouter>
      <ClientProvider />
    </BrowserRouter>
  );
};

const ViewProd = () => {
  return (
    <HashRouter>
      <ClientProvider />
    </HashRouter>
  );
};

const views = {
  development: ViewDev,
  production: ViewProd,
};

const CurrentView = views[env];

root.render(
  <React.StrictMode>
    <CurrentView />
  </React.StrictMode>
);
