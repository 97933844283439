import React, { useMemo } from "react";
import { useTexture } from "@react-three/drei";
import { NearestFilter, LinearSRGBColorSpace, SRGBColorSpace } from "three";
import { checkNormalFiles, checkIfUrlExists } from "../../../outils";
import { LinearEncoding } from "@react-three/drei/helpers/deprecated";

export const setTextures = (rootToModel, coucheActive) => {
  const names = {
    materialite: "materialite",
    iconographie: "iconographie",
  };

  /**
   *  CHECKS
   */

  const { extension, hasNormal } = useMemo(
    () => checkNormalFiles(rootToModel),
    [rootToModel]
  );
  //   const [hasDiffuse, hasEmissive, hasCouche] = [
  //     `${rootToModel}_base.jpg`,
  //     `${rootToModel}_materialite.png`,
  //     `${rootToModel}_${coucheActive}.png`,
  //   ].map((url) => checkIfUrlExists(url));

  const [hasDiffuse, hasEmissive, hasIconographie, hasMaterialite] = useMemo(
    () =>
      [
        `${rootToModel}_base.jpg`,
        `${rootToModel}_materialite.png`,
        `${rootToModel}_iconographie.png`,
        `${rootToModel}_materialite.png`,
      ].map((url) => checkIfUrlExists(url)),
    [rootToModel]
  );

  /**
   * USETEXTURE
   */

  const { map, normalMap, emissiveMap, couche, materialite, iconographie } =
    useTexture({
      map: hasDiffuse
        ? `${rootToModel}_base.jpg`
        : `/modeles/placeholders/diffuse_grid.jpg`,
      normalMap: hasNormal
        ? `${rootToModel}_normal.${extension}`
        : `/modeles/placeholders/normal_blank.png`,
      emissiveMap: hasEmissive
        ? `${rootToModel}_materialite.png`
        : `/modeles/placeholders/materialite_blank.png`,
      materialite: hasMaterialite
        ? `${rootToModel}_materialite.png`
        : `/modeles/placeholders/couche_blank.png`,
      iconographie: hasIconographie
        ? `${rootToModel}_iconographie.png`
        : `/modeles/placeholders/couche_blank.png`,
    });

  /**
   *  PROPS
   */

  let couches = [materialite, iconographie];

  let maps = [map, normalMap, emissiveMap, ...couches];

  maps.every(
    (m) => (
      (m.flipY = false),
      (m.generateMipmaps = true),
      (m.minFilter = NearestFilter),
      (m.magFilter = NearestFilter)
    )
  );

  couches.every(
    (c, i) => (
      (c.colorSpace = LinearSRGBColorSpace),
      (c.encoding = LinearEncoding),
      // (c.colorSpace = SRGBColorSpace),
      (c.name = `couche_${i}`)
    )
  );

  map.name = "diffuse";
  normalMap.name = "normales";
  emissiveMap.name = "emissive";
  emissiveMap.colorSpace = LinearSRGBColorSpace;

  return { map, normalMap, emissiveMap, materialite, iconographie };
};
