import React from "react";
import { useNavigate } from "react-router-dom";
import { getAnnotedFragments } from "../../../outils";
import { Box, Chip, Typography } from "@mui/material";

import useAnnotationStore from "../../../stores/useAnnotationStore";

const FragmentsParConcept = (props) => {
  const { notation, theme } = props;
  const navigate = useNavigate();

  const annotations = useAnnotationStore(
    (state) => state.annotationsParFragment[theme]
  );

  const fragments = getAnnotedFragments(notation, annotations);

  const handleClick = (e, id) => {
    let fragment = id.replace("-", ",");
    navigate(`/fragments/${fragment}`);
  };

  return (
    fragments.length > 0 && (
      <Box sx={{ m: 1 }}>
        <Typography color="primary" variant="h6">
          {fragments.length > 1
            ? `Fragments portant cette annotation (${fragments.length})`
            : "Fragment portant cette annotation"}
        </Typography>
        {fragments.map((fragment, index) => (
          <Chip
            key={index}
            sx={{ m: 0.5 }}
            label={fragment.replace("-", ",")}
            variant="outlined"
            title={`vers le fragment ${fragment.replace("-", ",")}`}
            onClick={(e) => handleClick(e, fragment)}
            clickable
          ></Chip>
        ))}
      </Box>
    )
  );
};

export default FragmentsParConcept;
