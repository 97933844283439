import React from "react";
import { Fab, Stack } from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";

const SideBar = (props) => {
  const { handleText } = props;
  const backgroundColor = "#FFFFFF66";
  return (
    <div className="leftBar">
      <Stack
        direction="column"
        sx={{
          pointerEvents: "none",
          "& > :not(style)": { m: 1, pointerEvents: "auto" },
        }}
      >
        {/* <Fab size="small" sx={{ backgroundColor: backgroundColor }}>
          <SlowMotionVideoOutlinedIcon />
        </Fab> */}
        <Fab
          size="small"
          onClick={handleText}
          sx={{ backgroundColor: backgroundColor }}
        >
          <NotesIcon />
        </Fab>
      </Stack>
    </div>
  );
};

export default SideBar;
