import React, { Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Toolbar, Typography, Box, Stack, Button } from "@mui/material";
import PaletteLayer from "./paletteLayer";

import { getFragmentsAnnotations } from "../../../outils";
import useDefinitionStore from "../../../stores/useDefinitionStore";

const Element = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [triggered, setTriggered] = useState(false);
  const [annotations, setAnnotations] = useState(null);
  const params = useParams();

  const definitions = useDefinitionStore((state) => {
    return {
      iconographie: state.iconographie,
      materialite: state.materialite,
    };
  });

  const fragment = params.numero;

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      const data = await getFragmentsAnnotations(fragment);

      if (isSubscribed) {
        setAnnotations(data);
        setHasLoaded(true);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [triggered]);

  return (
    <>
      <Toolbar variant="dense" />
      <div>
        <div id="annotations">
          <Typography variant="h5">
            Table des couleurs des couches du fragment {params.numero}
          </Typography>

          <Box>
            {!hasLoaded ? (
              <span>chargements et calculs en cours ... patience ...</span>
            ) : (
              <Suspense fallback={<span>chargement en cours ...</span>}>
                {hasLoaded &&
                  Object.keys(annotations).map((lyr, index) => (
                    <Stack
                      key={index}
                      spacing={2}
                      sx={{ border: 0, borderRadius: 1, p: 1, my: 1 }}
                    >
                      <Typography variant="h6">{lyr}</Typography>

                      {Object.keys(annotations[lyr]).map(
                        (frag, i) =>
                          definitions[`${lyr}`] && (
                            <PaletteLayer
                              key={i}
                              frag={frag}
                              annotations={annotations[lyr][frag]}
                              lyr={lyr}
                              definitions={definitions[`${lyr}`]}
                            />
                          )
                      )}
                    </Stack>
                  ))}
              </Suspense>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default Element;
