import React, { useState, useMemo } from "react";
import { TextField, Autocomplete, Box } from "@mui/material";

const removeAccents = (str) =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

function sortAlpha(a, b) {
  if (removeAccents(a) < removeAccents(b)) return -1;
  else if (removeAccents(a) > removeAccents(b)) return 1;
  else return 0;
}

const SelectColor = (props) => {
  const { palette, handleSelection } = props;

  // const colorList = palette
  //   .filter((item) => item.descripteur)
  //   .map((item) => item.descripteur);

  const colorSet = useMemo(() => {
    return [
      ...new Set(
        palette
          .filter((item) => item.descripteur)
          .map((item) => item.descripteur)
      ),
    ].sort(sortAlpha);
  }, []);

  const [currentColor, setCurrentColor] = useState();
  const [value, setValue] = useState();

  const handleSelectColor = (e, updatedValue) => {
    // setCurrentColor(updatedValue);
    console.log("onchange :", updatedValue);
  };

  const handleInputChange = (e, updatedValue) => {
    console.log("onInputChange :", updatedValue);

    // setCurrentColor();
    // setValue(updatedValue);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mx: 1 }}
    >
      <Autocomplete
        id="SelectColor"
        autoSelect
        // disableClearable
        // options={colorSet.sort(sortAlpha)}
        options={colorSet}
        //isOptionEqualToValue={(option) => option.descripteur}
        // getOptionLabel={(option) => option.file}
        // renderOption={(option) => option.label}
        // value={currentColor}
        // inputValue={value.label}
        //onChange={handleSelectColor}
        onInputChange={handleSelection}
        sx={{ width: 260, position: "middle" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="descripteur"
            margin="dense"
            size="medium"
          />
        )}
      />
    </Box>
  );
};

export default SelectColor;
