import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Crepis(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modeles/tholos/crepis.glb");

  return (
    <group ref={group} {...props} dispose={null} name="crepis">
      <mesh
        name="crepis001"
        castShadow
        receiveShadow
        geometry={nodes.crepis001.geometry}
        material={materials.crepis}
        position={[0, 0.203, 0]}
        scale={15.418}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/crepis.glb");
