function truncateSentence(str, num) {
  let sentence = str.split(" ");
  if (sentence.length <= num) {
    return str;
  }
  return sentence.slice(0, num).join(" ") + "...";
}

export default truncateSentence;

//truncateString("A-tisket a-tasket A green and yellow basket", 8);
