import React from "react";
import { Tooltip } from "@mui/material";
import useFetch from "../../../hooks/useFetch";

import BulleArchimageIIIF from "./bulleArchimageIIIF";

const TooltipArchimageIIIF = (props) => {
  const { id, text, target } = props;

  const res = useFetch({
    url: `https://archimage.efa.gr/action.php?kroute=document_export_json_public&id=${id}`,
  });

  let data;
  res?.data ? (data = res.data) : null;

  return (
    <Tooltip
      key={id}
      title={
        res.data ? (
          <BulleArchimageIIIF {...data} target={target} />
        ) : (
          "chargement en cours"
        )
      }
      arrow
      leaveDelay={250}
    >
      <mark className="archimage-iiif" id={`archimage-iiif-${id}`}>
        {text}
      </mark>
    </Tooltip>
  );
};

export default TooltipArchimageIIIF;
