import React, { useRef } from "react";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";

import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader";

const PointCloud = (props) => {
  const group = useRef();
  const { DEG2RAD } = THREE.MathUtils;
  const pcd = useLoader(PCDLoader, "/pointclouds/tholos_assemblage_4cm.pcd");

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      name="nuage de points"
      rotation={[-Math.PI / 2, 0, -13.3307 * DEG2RAD]}
      position={[0, -0.072061, 0]}
    >
      <primitive object={pcd} />
    </group>
  );
};

export default PointCloud;
