/**
 *
 * @param {Array<{decor_ID: string, name: string, position: number[], rotation:number[]}>} fragments - liste d'objets décrivant les fragments
 * @param {number[]} shuffledList - liste d'indices réordonnée aléatoirement
 * @param {string} name - nom à attribuer au groupe de fragment
 * @param {boolean} toggle - mode aléatoire activé ?
 * @returns {Array<{decor_ID: string, name: string, position: number[], rotation:number[]}>} liste d'objets décrivant les fragments, avec un ID de placement aléaoire
 */

function getRandom(fragments, shuffledList, name, toggle) {
  if (!fragments || !shuffledList || !name) {
    console.log("pb ici");
    return;
  }

  let myArray = fragments.map((m, i) => {
    let index = shuffledList[i];
    let { decor_ID, ...others } = m;
    let type = decor_ID.split("_")[0];

    let newObj = { decor_ID: `${type}_${index}`, ...others };
    let result = toggle ? newObj : m;
    return result;
  });

  return { randomArray: myArray, name: name };
}

export default getRandom;
