import React from "react";
import { Box, Stack, Fab } from "@mui/material";

const FakeFabs = ({ number }) => {
  return (
    <Stack direction="row" visibility={"hidden"} id="fake-fabs">
      <Box flexDirection="row" sx={{ "& > :not(style)": { m: 1 } }}>
        {[...new Array(number)].map((item, index) => (
          <Fab
            key={index}
            size="small"
            disabled
            sx={{ backgroundColor: "orange" }}
          ></Fab>
        ))}
      </Box>
    </Stack>
  );
};

export default FakeFabs;
