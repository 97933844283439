import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";

import { getParams } from "../../../outils";

import { Link as RouterLink } from "react-router-dom";
import useOpenthesoAPI from "../../hooks/useOpenThesoAPI";

const BroadConcept = (props) => {
  const { idc } = getParams(props[`@_resource`]);

  const params = { idc: idc, idt: "th653", direction: "top" };

  const arbo = useOpenthesoAPI(params);

  const LinkRouter = (props) => {
    return (
      <Typography
        variant="overline"
        {...props}
        component={RouterLink}
        underline="none"
      ></Typography>
    );
  };

  return arbo ? (
    <Breadcrumbs separator="\">
      {arbo.map((segment, index) => {
        const last = index === arbo.length - 1;

        const to = `./../${segment.id}`;
        return last ? (
          <LinkRouter to={to} key={to}>
            {segment.label}
          </LinkRouter>
        ) : (
          <LinkRouter to={to} key={to}>
            {segment.label}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  ) : null;
};

export default BroadConcept;
