import React, { useRef } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import useUiStore from "../../../stores/useUiStore";

const MyLight = () => {
  const { paramsFragment } = useUiStore();

  const lightRef = useRef();

  const vec = new THREE.Vector3();

  const { radius, phi, theta, intensity } = {
    radius: 1,
    phi: -75,
    theta: 115,
    intensity: 0.35,
  };

  const coordSun = new THREE.Vector3();
  coordSun.setFromSphericalCoords(
    radius,
    THREE.MathUtils.degToRad(phi),
    THREE.MathUtils.degToRad(theta)
  );

  Number.prototype.map = function (in_min, in_max, out_min, out_max) {
    return (
      ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
  };

  useFrame((state, delta) => {
    paramsFragment?.lit?.value
      ? lightRef.current.position.lerp(
          vec.setFromSphericalCoords(
            radius,
            THREE.MathUtils.degToRad(-state.mouse.y.map(1, -1, 15, 165)),
            THREE.MathUtils.degToRad(state.mouse.x.map(-1, 1, 90, 270))
          ),
          0.2
        )
      : lightRef.current.position.lerp(
          vec.setFromSphericalCoords(
            radius,
            THREE.MathUtils.degToRad(phi),
            THREE.MathUtils.degToRad(theta)
          ),
          0.1
        );
  });

  return (
    <directionalLight
      ref={lightRef}
      castShadow
      shadow-mapSize={[512, 512]}
      position={[coordSun.x, coordSun.y, coordSun.z]}
      intensity={intensity}
    />
  );
};
export default MyLight;
