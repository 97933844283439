function getPixDefinition(pixHEX, definitions) {
  if (!definitions) {
    return { message: "pas de fichier de définitions" };
  } else {
    let myHex = `#${pixHEX}`;
    const definition = definitions.find((item) => myHex === item.hex);

    return !definition
      ? {
          hex: pixHEX,
          message: "n'a pas de definition, ou la définition ne correspond pas",
        }
      : definition.descripteur;
    // Attention au renvoi d'objet pour les évaluations de dépendances dans useEffect. Preferer le renvoi d'une str ?
    // {
    //     hex: pixHEX,
    //     message: "définition trouvée",
    //     definition: definition.descripteur,
    //   };
  }
}
export default getPixDefinition;
