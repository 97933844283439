import { useRef, Suspense, useState, useEffect } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { ContactShadows } from "@react-three/drei";

import useModelStore from "../../stores/useModelStore";

import CreateLayers from "../model/createLayers";
import { Model_sansLeva, PlaceHolder, CustomLoader } from "../model";

export default function Viewer_sansLeva({ name }) {
  const { scene } = useThree();
  scene.name = name;

  const { radius, phi, theta, intensity } = {
    radius: 1,
    phi: -90,
    theta: 180,
    intensity: 1,
  };

  const modelFileName = useModelStore(
    (state) => state.currentFragment.numero_inventaire_3d
  );
  const currentLayer = useModelStore((state) => state.currentLayer);

  const layers = CreateLayers();

  const coordSun = new THREE.Vector3();
  coordSun.setFromSphericalCoords(
    radius,
    THREE.MathUtils.degToRad(phi),
    THREE.MathUtils.degToRad(theta)
  );

  return (
    <>
      <directionalLight
        castShadow
        shadow-mapSize={[512, 512]}
        position={[coordSun.x, coordSun.y, coordSun.z]}
        intensity={intensity}
      />

      <ambientLight intensity={0.5} />
      <ContactShadows
        name="ContactShadows"
        position={[0, -0.15, 0]}
        opacity={0.35}
        scale={0.75}
        blur={5}
        far={4}
        resolution={128}
      />
      <Suspense fallback={<CustomLoader />}>
        <Model_sansLeva
          modelFileName={modelFileName}
          layers={layers}
          currentLayer={currentLayer.value}
          couche={currentLayer.code}
        />
      </Suspense>
    </>
  );
}
