import React from "react";
import SelectLayer from "./selectLayer";
import SelectModel from "./selectModel";

const SelectFields = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <span>
        <SelectModel />
      </span>
      <span>
        <SelectLayer />
      </span>
    </div>
  );
};

export default SelectFields;
