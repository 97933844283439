import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { Canvas, useThree } from "@react-three/fiber";
import {
  EffectComposer,
  Bloom,
  DepthOfField,
} from "@react-three/postprocessing";
// import { BlurPass } from "postprocessing";

import {
  Stage,
  CameraControls,
  Environment,
  Center,
  Bounds,
  useProgress,
  Effects,
} from "@react-three/drei";

import Overlay from "../../ui/loader/overlay";

import FragmentPreview from "./fragmentPreview";
import FragmentDummy from "./fragmentDummy";
import FragmentModel from "./fragmentModel";
import Annotations from "./annotations/annotations";

import MyLight from "./myLight";

const loadingBarElement = document.querySelector(".loading-bar");

const CanvasFragment = (props) => {
  const {
    name,
    width,
    fitToSphere,
    couche,
    rotationFragment,
    annotationsOn,
    setOpenNotice,
  } = props;
  const [count, setCount] = useState(0);
  const progress = useProgress((state) => state.progress);
  const item = useProgress((state) => state.item);
  //console.log(progress);

  let ratio = 0;
  let score = 0;
  useEffect(() => {
    ratio = progress / 100;
    loadingBarElement.classList.remove("ended");
    loadingBarElement.style.transform = `scaleX(${ratio})`;

    ratio >= 1
      ? window.setTimeout(() => {
          loadingBarElement.classList.add("ended"),
            (loadingBarElement.style.transform = "");
        }, 500)
      : null;

    // progress >= 100
    //   ? window.setTimeout(() => {
    //       (loadingBarElement.style.transform = "scaleX(0)"),
    //         // (loadingBarElement.style.transformOrigin = "top right"),
    //         //(loadingBarElement.style.transition ="transform 1.5s ease-in-out");

    //         loadingBarElement.classList.add("ended"),
    //         (loadingBarElement.style.transform = "");
    //     }, 250)
    //   : (loadingBarElement.classList.remove("ended"),
    //     (loadingBarElement.style.transform = `scaleX(${ratio})`));
    // // (loadingBarElement.style.transformOrigin = "top left"),
    // loadingBarElement.style.transition = "transform 0.5s";
  }, [progress]);

  const MyScene = () => {
    const { scene, camera } = useThree();
    scene.name = name;
  };

  const Effects = () => {
    return (
      <EffectComposer>
        <Bloom
          intensity={4}
          radius={0.75}
          luminanceThreshold={0.25}
          luminanceSmoothing={0.5}
          height={600}
          opacity={0.15}
          blurPass={undefined}
        />
      </EffectComposer>
    );
  };

  return (
    <div
      className="webgl"
      id="scene3D-fragment"
      style={{ width: `calc(100% - ${width}px)` }}
    >
      <Canvas
        // flat
        legacy={true}
        // linear={true}
        shadows
        dpr={[1, 2]}
        camera={{
          fov: 20,
          near: 0.01,
          far: 40,
          position: [0, 0, 0.33],
        }}
      >
        <Overlay />
        <MyScene />
        <MyLight />

        {name ? (
          <>
            <FragmentModel
              filename={name}
              fitToSphere={fitToSphere}
              couche={couche}
              rotationFragment={rotationFragment}
              annotationsOn={annotationsOn}
              setOpenNotice={setOpenNotice}
            />
            {/* <Annotations /> */}
          </>
        ) : (
          <FragmentDummy filename={name} />
        )}

        <Environment
          path="/images/hdr/"
          files="golf_course_sunrise_500_lower.hdr"
          environmentIntensity={1}
          backgroundIntensity={1}
        >
          <color
            attach="environment"
            args={[new THREE.Color(1.0, 0.0, 0.0).multiplyScalar(0.1)]}
          />
        </Environment>

        {/* <Effects /> */}
      </Canvas>
    </div>
  );
};
export default CanvasFragment;
