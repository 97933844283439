const multiFilter = (data, filter) => {
  const cleanFilterKeys = (obj) => {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop]) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  };

  const filterKeys = Object.keys(cleanFilterKeys(filter));

  let result;

  result = data.filter((eachObj) => {
    return filterKeys.every((eachKey) => {
      if (String(filter[eachKey]).length != String(eachObj[eachKey]).length) {
        return false;
      }
      if (!filter[eachKey].length) {
        return true; // passing an empty filter means that filter is ignored.
      }
      return filter[eachKey].includes(`${eachObj[eachKey]}`);
    });
  });

  // console.log(filterKeys, result);

  return result;
};

export default multiFilter;
