import React, { useState, useEffect, useMemo } from "react";
import { XMLParser } from "fast-xml-parser";
import * as jp from "jsonpath";

import useModelStore from "../../../stores/useModelStore";

import Bibliographie from "./bibliographie";
import Head from "./head";
import Title from "./title";
import BibliographieAccordion from "./bibliographieAccordion";
import Section1 from "./section1";
import { Drawer, Box, Typography, Stack, Avatar, Badge } from "@mui/material";
import { checkIfUrlExists } from "../../../outils";

const NoticeMarcadeFastXmlParser = (props) => {
  const { fragment } = props;

  const [xml, setXml] = useState(null);

  // fragment = "placeholder";

  // currentFragment?.file ? (isFragment = true) : (isFragment = false);
  // console.log(isFragment);

  // isFragment ? fragment =  currentFragment.file : "11657"

  // let fragment = "11657";
  // currentFragment?.file ? (isFragment = true) : (isFragment = false);

  // isFragment ? (fragment = currentFragment.file) : "placeholder";

  let isNotice = useMemo(() =>
    // checkIfUrlExists(`/xml/marcade_${fragment}.xml`, [fragment])
    checkIfUrlExists(`/xml/notices/${fragment}.xml`, [fragment])
  );
  let numero;

  isNotice ? (numero = fragment) : (numero = "placeholder");

  /**
   * XML PARSER
   */

  const xmlParserOptions = {
    ignoreAttributes: false,
    // ignoreNameSpace: true,
    trim: true,
    // parseAttributeValue: true,
    stopNodes: ["*.p", "*.head"],
    processEntities: true,
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (["children", "div", "p"].indexOf(name) !== -1) return true;
    },
    // htmlEntities: true,
  };
  const parser = new XMLParser(xmlParserOptions);

  /**
   * LOADER
   */

  useEffect(() => {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("load", () => {
      let parsedData = parser.parse(xhr.response);
      setXml(parsedData);
    });
    // xhr.open("GET", `/xml/marcade_${numero}.xml`);
    xhr.open("GET", `/xml/notices/${numero}.xml`);
    xhr.send();
  }, []);

  /**
   * JSONPATH SYNTAX TRIALS
   */
  // xml ? (titreNotice = jp.query(xml, `$..div[?(@.head=="Titre notice")]`)) : null;
  // xml ? (texteNotice = jp.query(xml, `$..div[?(@.head=="Texte notice")]`)) : null;
  // xml ? (section2 = jp.query(xml, `$..div[?(@.@_type=="section2")]`)) : null;

  // xml ? console.log(xml.TEI.text.body.div[0].div[1].p[2][`#text`]) : null;

  let title;
  let section1;
  let bibliography;

  xml ? (title = jp.query(xml, `$..[?(@.@_rend=="title-main")]`)) : null;

  xml ? (section1 = jp.query(xml, `$..[?(@.@_type=="section1")]`)) : null;
  xml
    ? (bibliography = jp.query(xml, `$..[?(@.@_type=="bibliography")]`))
    : null;

  // xml ? console.log(title[0].p) : null;
  // xml ? console.log(title[0]) : null;
  return (
    <Box
      sx={{ p: 2 }}
      // style={{ overflowY: "scroll", maxHeight: "80vh", padding: "20px" }}
      id="notice-marcade"
    >
      {/* {xml && title ? (
        <Title text={title[0][`#text`]} id={title[0][`@_rend`]} />
      ) : null} */}
      {xml && title ? <Head text={title[0][`#text`]} variant="h5" /> : null}
      {xml && section1
        ? section1.map((section, index) => (
            <Section1 key={section[`@_xml:id`]} {...section} />
          ))
        : null}

      {xml && bibliography
        ? bibliography.map((bib, index) => (
            <BibliographieAccordion key={bib[`@_xml:id`]} {...bib} />
          ))
        : null}
    </Box>
  );
};

export default NoticeMarcadeFastXmlParser;
