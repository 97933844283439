function createGimpGpl(colors, filename, layer, ext) {
  let colorsRGB = colors.map((c) => c.rgb);
  let colorsHEX = colors.map((c) => c.hex);
  let fragmentName = filename.replace("-", ",");

  //   let space = " ";
  //   let tab = "   ";
  let space = " ";
  let tab = "\t";
  let linebreak = "\n";

  let line1 = "GIMP Palette";
  let line2 = `Name: ${filename}_${layer}`;
  let line3 = `Columns: ${colors.length}`;
  let line4 = "#";
  let line5 = `# couleurs indexees de la couche ${layer} du fragment ${fragmentName}`;
  let line6 = "# Projet Tholos";
  let line7 = "# https://tholos.huma-num.fr";
  let line8 = "#";

  let header = [line1, line2, line3, line4, line5, line6, line7, line8];

  const colorComponent = (component) => {
    return component.toString().padStart("3", " ");
  };

  let linesColors = [];
  for (let i = 0; i < colors.length; i++) {
    let rgb = colors[i].rgb.map((comp) => colorComponent(comp)).join(space);
    let lineCol = [rgb, colors[i].hex].join(tab);
    linesColors.push(lineCol);
  }

  let output = [...header, ...linesColors].join(linebreak);

  var blob = new Blob([output], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}_${layer}.${ext}`;
  a.click();
  URL.revokeObjectURL(url);
}
export default createGimpGpl;
