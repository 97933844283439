import React, { useEffect, useState } from "react";
import { XMLParser } from "fast-xml-parser";
import * as jp from "jsonpath";

const useStyledXML = (xml, xslt) => {
  const [styledXML, setStyledXML] = useState(null);

  const xmlUrl = `/xml/${xml}.xml`;
  const xslUrl = `/xml/${xslt}.xslt`;

  /**
   * Functions
   */

  function httpRequest(method, url, content, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.addEventListener("readystatechange", function () {
      if (xhr.readyState !== 4 || xhr.status !== 200) {
        return;
      }
      if (!!callback && typeof callback === "function") {
        callback(xhr);
      }
    });

    xhr.send(content);
  }

  /**
   * XML PARSER
   */

  const xmlParserOptions = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    trim: true,
    stopNodes: ["*.p", "*.head", "*.label"],
    processEntities: true,
    ignoreDeclaration: true,
    ignorePiTags: true,
    removeNSPrefix: true,
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (["children", "div", "p"].indexOf(name) !== -1) return true;
    },
    // transformTagName: (tagName) => setTagName(tagName),
    // attributeValueProcessor: (name, val, jPath) => {
    //   console.log(name, val);
    // },

    // tagValueProcessor: (tagName, tagValue, jPath, hasAttributes, isLeafNode) =>
    //   (tagValue = processTagValue(tagValue, jPath)),
  };
  const xmlParser = new XMLParser(xmlParserOptions);

  /**
   * HOOK
   */

  /**
   *  XML AND XSLT
   */

  useEffect(() => {
    httpRequest("GET", xmlUrl, void 0, function (xml) {
      const xmlObject = xml.responseXML;
      httpRequest("GET", xslUrl, void 0, function (xsl) {
        const xslObject = xsl.responseXML;
        const xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xslObject);
        const resultDocument = xsltProcessor.transformToFragment(
          xmlObject,
          document
        );
        const xmlSerializer = new XMLSerializer();
        const serializedData = xmlSerializer.serializeToString(resultDocument);
        let parsedData = xmlParser.parse(serializedData);
        let myStyledXML = jp.query(parsedData, `$..[?(@.type=="section1")]`);
        setStyledXML(myStyledXML);
      });
    });
  }, []);

  return styledXML;
};
export default useStyledXML;
