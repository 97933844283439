import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ButtonClose = (props) => {
  const { onClick } = props;
  return (
    <IconButton onClick={onClick} title="Fermer">
      <CloseIcon />
    </IconButton>
  );
};

export default ButtonClose;
