function getDefinitions(palette, definitions) {
  if (!definitions) {
    //console.log("pas de def");
    return [];
  } else {
    // console.log("exemple de def courante:", definitions[0].hex.slice(1));

    return definitions.filter((def) =>
      palette.find((hex) => hex === def.hex.slice(1))
    );
  }
}
export default getDefinitions;
