import React from "react";
import useQueries from "./components/hooks/useQueries";
import useQueriesAPI from "./components/hooks/useQueriesAPI";

const Queries = () => {
  const { data: materialite, isSuccess: isSuccessMaterialite } = useQueriesAPI(
    "palettes/materialite"
  );

  const { data: iconographie, isSuccess: isSuccessIconographie } =
    useQueriesAPI("palettes/iconographie");

  const { data: fragments, isSuccess: isSuccessFragments } = useQueries(
    "/json/annotationsParFragment.json"
  );

  //   isSuccessFragments &&
  //     isSuccessIconographie &&
  //     isSuccessMaterialite &&
  //     console.log(fragments, iconographie, materialite);

  return;
};

export default Queries;
