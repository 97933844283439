import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Switches from "./switches";

const SettingsCard = (props) => {
  const { open, handleSettings } = props;

  const MyCard = () => {
    return (
      <ClickAwayListener onClickAway={handleSettings(false)}>
        <Card sx={{ maxWidth: "250px" }}>
          <CardHeader
            title="Réglages"
            subheader="Planches de fragments"
            action={
              <IconButton onClick={handleSettings(false)} title="Fermer">
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Switches />
          </CardContent>
        </Card>
      </ClickAwayListener>
    );
  };

  return open ? <MyCard /> : null;
};

export default SettingsCard;
