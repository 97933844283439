import React from "react";
import { ContactShadows, Environment } from "@react-three/drei";

function Lights() {
  const DirectLight = () => {
    return (
      <directionalLight
        castShadow
        shadow-mapSize={[1024, 1024]}
        shadow-bias={0.00001}
        shadow-normalBias={0.03}
        position={[12.5, 25, 25]}
        intensity={0.5}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-12, 12, 12, -12, 10, 50]}
        />
      </directionalLight>
    );
  };

  return (
    <group name="Lights">
      <DirectLight />

      {/* <ambientLight intensity={0.1} /> */}
      <ContactShadows
        name="ContactShadows"
        // position={[0, -0.15, 0]}
        opacity={0.35}
        scale={0.75}
        blur={2}
        far={4}
        resolution={1024}
        frames={1}
      />
      <Environment
        blur={1}
        files="/images/hdr/venice_sunset_500.hdr"
        //preset="sunset"
      />
    </group>
  );
}

export default Lights;
