import React, { useRef, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRoute, useLocation, Route } from "wouter";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { useCursor, CameraControls, useGLTF, Html } from "@react-three/drei";

import useUiStore from "../../../stores/useUiStore";

import { shuffle, getRandom, setCameraPosition } from "../../../outils";
import Tiles from "./tholos3dTileSet";

import {
  Crepis,
  Architrave,
  Couverture,
  Corniche,
  Mur,
  Couvrement,
  ArchitraveInterieur,
  Colonnade,
  Metopes,
  Triglyphes,
  Acroteres,
} from "./architecture";

import { FragmentDecor } from "./decor";

import { stations, fragmentsAcro, fragmentsGM, fragmentsPM } from "./data";
import { navigate } from "wouter/use-browser-location";

const Tholos = () => {
  const [, params] = useRoute("/:id");
  const [, newParams] = useRoute(":parent/:id");
  const [location, setLocation] = useLocation();
  const [toggleRandom] = useState(false);

  const paramsTholos = useUiStore((state) => state.paramsTholos);

  let last = location.split("/").slice(-1)[0];

  // console.log(params, newParams);

  useGLTF.setDecoderPath("/draco/");

  const [isMyFirstLoad, setIsMyFirstLoad] = useState(true);

  const cameraControlsRef = useRef();

  const { camera } = useThree();

  const tholosRef = useRef();
  const clickedRef = useRef();
  const clickedObjectRef = useRef();
  const grandesMetopesRef = useRef();
  const petitesMetopesRef = useRef();
  const acroteresRef = useRef();
  const HtmlRef = useRef();
  const [hovered, setHovered] = useState();
  const [dimmed, setDimmed] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const { DEG2RAD, RAD2DEG } = THREE.MathUtils;

  useCursor(hovered);

  /**
   * Randomize Metopes placement
   */

  const random = useUiStore((state) => state.random);

  let [arrayGM, arrayPM, arrayAcro] = useMemo(
    () =>
      [40, 40, 8].map((nb) =>
        shuffle([...Array(nb)].map((item, index) => index + 1))
      ),
    [random]
  );

  const decor = [
    { fragments: fragmentsGM, shuffledList: arrayGM, name: "GM" },
    { fragments: fragmentsPM, shuffledList: arrayPM, name: "PM" },
    {
      fragments: fragmentsAcro,
      shuffledList: arrayAcro,
      name: "Acro",
    },
  ].map((f) => getRandom(f.fragments, f.shuffledList, f.name, random));

  const [GM, PM, Acro] = decor;

  /**
   * HANDLERS
   */

  const handleClick = (e) => {
    clickedObjectRef.current = e.object;
    // console.log(e.object);

    function get3DPosition(mesh) {
      if (!mesh) {
        return;
      }

      let pos = new THREE.Vector3();
      const center = new THREE.Vector3();
      const box = new THREE.Box3().setFromObject(mesh);
      pos = box.getCenter(center);
      return pos;
    }

    const size = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const calculatePosition = (el = clickedObjectRef.current, camera, size) => {
      const tmpPos = new THREE.Vector3();
      const objectPos = tmpPos.setFromMatrixPosition(el.matrixWorld);
      objectPos.project(camera);
      const widthHalf = size.width / 2;
      const heightHalf = size.height / 2;
      return [
        Math.min(
          size.width - 100,
          Math.max(0, objectPos.x * widthHalf + widthHalf)
        ),
        Math.min(
          size.height - 50,
          Math.max(0, -(objectPos.y * heightHalf) + heightHalf)
        ),
      ];
    };

    // console.log(
    //   calculatePosition(e.object, camera, {
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    //   })
    // );

    let myPos = get3DPosition(e.object);
    // console.log(myPos);

    let myGroup = e?.eventObject?.name;

    // HtmlRef.current.innerText = myGroup;

    // HtmlRef.current.calculatePosition = calculatePosition;

    // HtmlRef.current.calculatePosition =
    //   (e.object,
    //   camera,
    //   { width: window.innerWidth, height: window.innerHeight });

    e.stopPropagation();
    setLocation(
      clickedRef.current === myGroup
        ? ""
        : myGroup === "tholos"
        ? ""
        : `/${myGroup}`
    );
  };

  const handleLocalClick = (e) => {
    let myParent = e?.eventObject?.parent?.name;

    if (params?.id === myParent) {
      e.stopPropagation();
      // console.log(e?.eventObject.name, myParent);
      // console.log(clickedObjectRef.current);

      // setLocation(`${location}/${e?.eventObject.name}`);
      // setLocation(`/${myParent}/${e?.eventObject.name.split("_")[1]}`);

      let controls = cameraControlsRef.current;

      controls.maxDistance = 6;
      controls.minDistance = 2;

      let station = stations?.find(
        (f) => f.id === e.eventObject.name.split("_")[0]
      );

      let index = e.eventObject.name.split("_")[1];

      controls.fitToSphere(e?.object, true);
      let currentPos = new THREE.Vector3();
      let tmpPos = new THREE.Vector3();
      tmpPos = controls.getPosition(currentPos);
      console.log(tmpPos);
    }
  };

  const handlePointerMissed = (e) => {
    e.stopPropagation();
    setLocation("");
  };

  const handlePointerOver = (e) => {};

  /**
   * HOOK
   */

  useEffect(
    () => {
      let posTar = [...stations[0].position, ...stations[0].target];

      function placeCamera(station, methode) {
        let controls = cameraControlsRef.current;
        let posTar = [...station.position, ...station.target];
        switch (methode) {
          case "lookAt":
            let position = [];

            // console.log(clickedObjectRef.current, clickedRef.current.name);

            !!clickedObjectRef.current &&
            clickedObjectRef.current.parent.collection ===
              clickedRef.current.name
              ? (position = setCameraPosition(
                  clickedObjectRef?.current,
                  station.directionDistance
                ))
              : (position = posTar);

            let newPosTar = [
              position[0],
              posTar[1],
              position[2],
              ...station.target,
            ];

            controls.setLookAt(...newPosTar, true);
            const { phi } = controls.getSpherical();

            controls.maxPolarAngle = phi * 1.03;
            controls.minPolarAngle = phi * 0.97;

            controls.maxDistance = !station?.maxDistance
              ? 50
              : station.maxDistance;
            controls.minDistance = !station?.minDistance
              ? 1
              : station.minDistance;

            break;
          case "polarTo":
            controls.setPosition(...station.position, true);
            // controls.rotatePolarTo(station.phi, true);
            break;
          case "rotateAzimuthTo":
            let angle = (Math.PI / 20) * (1 + parseInt(station?.index));

            controls.rotateAzimuthTo(angle, true);

            break;
        }
        return;
      }

      if (isMyFirstLoad && !clickedRef.current) {
        cameraControlsRef.current.maxDistance = 50;
        setTimeout(() => {
          cameraControlsRef.current.setLookAt(...posTar, true);
        }, 1500);
        setIsMyFirstLoad(false);
      }

      // clickedRef.current = tholosRef.current.getObjectByName(params?.id);
      clickedRef.current = tholosRef.current.getObjectByName(last);

      let station = stations?.find((f) => f.id === clickedRef?.current?.name);
      station
        ? console.log(`station en cours : ${station.id}`)
        : (station = stations[0]);

      if (!isMyFirstLoad && !clickedRef.current) {
        setTimeout(() => {
          cameraControlsRef.current.setLookAt(...posTar, true);
          cameraControlsRef.current.maxPolarAngle = Math.PI * 0.55;
          cameraControlsRef.current.minPolarAngle = 0;
          cameraControlsRef.current.minDistance = 20;
          cameraControlsRef.current.maxDistance = 50;
        });
      }

      if (station?.id === clickedRef?.current?.name) {
        let posTar = [...station.position, ...station.target];

        isMyFirstLoad
          ? (setTimeout(() => {
              placeCamera(station, "lookAt");
            }, 1500),
            setIsMyFirstLoad(false))
          : placeCamera(station, "lookAt");
      }
    },
    // [params?.id]
    [last]
  );

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        console.log("Close");
        setLocation("");
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <Route path="/" nest>
      <CameraControls
        ref={cameraControlsRef}
        enabled
        azimuthRotateSpeed={0.25}
        polarRotateSpeed={0.25}
        minDistance={20}
        maxDistance={50}
        maxPolarAngle={Math.PI * 0.55}
      />
      <group
        ref={tholosRef}
        name="monument"
        onPointerMissed={handlePointerMissed}
        onPointerOver={(e) => e.stopPropagation()}
        visible={paramsTholos.reconstitution.value}
      >
        <Crepis transparent opacity={opacity} />

        <group name="bâti extérieur">
          <Colonnade />
          <Architrave />
          <Triglyphes
            name="grands-triglyphes"
            dimension="grand"
            geometry={`Grands_Triglyphes001`}
          />

          <Metopes
            name="grandes-metopes"
            dimension="grande"
            geometry={`Grandes_Metopes001`}
            title="Grandes Métopes"
            ref={grandesMetopesRef}
            handleClick={handleClick}
            handleLocalClick={handleLocalClick}
            fragmentsMetopes={GM.randomArray}
          />
        </group>
        <group name="bâti intérieur">
          <Mur />
          <Couvrement />
          <ArchitraveInterieur />
          <Triglyphes
            name="petits-triglyphes"
            dimension="petit"
            geometry={`Petits_Triglyphes001`}
          />

          <Metopes
            name="petites-metopes"
            dimension="petite"
            geometry={`Petites_Metopes001`}
            title="Petites Métopes"
            ref={petitesMetopesRef}
            handleClick={handleClick}
            handleLocalClick={handleLocalClick}
            fragmentsMetopes={PM.randomArray}
          />
        </group>
        <group name="metope-cheval-cabre"></group>
        <group name="metope-colonne"></group>
        <group name="metope-croupe-chevaline"></group>
        <group name="metope-pied-droit"></group>
        {/* <group name="decor">
          {decor.map((d, i) => (
            <group name={d.name} key={i}>
              {d.randomArray.map((fragment, idx) => (
                <FragmentDecor key={idx} {...fragment} />
              ))}
            </group>
          ))}
        </group> */}
        <Corniche />
        <Couverture />
        <Acroteres
          name="acroteres"
          title="Acrotères"
          geometry={"nike_wf"}
          ref={acroteresRef}
          handleClick={handleClick}
          handleLocalClick={handleLocalClick}
          fragmentsAcroteres={Acro.randomArray}
        />
      </group>
      <group name="decor">
        {decor.map((d, i) => (
          <group name={d.name} key={i}>
            {d.randomArray.map((fragment, idx) => (
              <FragmentDecor key={idx} {...fragment} />
            ))}
          </group>
        ))}
      </group>

      <Tiles
        url="/tilesets/tileset_tholos_assemblage.json"
        showTraversability={false}
        name="tileset_tholos_assemblage"
        visibility={paramsTholos.anastyloseSite.value}
      />

      {/* <Html ref={HtmlRef}></Html> */}
    </Route>
  );
};

export default Tholos;
