import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { useNavigate } from "react-router-dom";
import { Icosahedron, Circle, useCursor } from "@react-three/drei";

import useUiStore from "../../../stores/useUiStore";

const Fragments = () => {
  const circleRef = useRef();
  const [positions, setPosition] = useState(null);
  const [hovered, setHovered] = useState();
  const [opacity, setOpacity] = useState(1);

  const paramsTholos = useUiStore((state) => state.paramsTholos);

  const navigate = useNavigate();

  useCursor(hovered);

  const overFragments = (e) => {
    setHovered(true);
    setOpacity(0.5);
  };
  const outFragments = (e) => {
    setOpacity(1);
    setHovered(false);
  };

  const handleClick = () => {
    navigate("/fragments");
  };

  useEffect(() => {
    let myGeom = circleRef.current;
    let myPositions = myGeom.geometry.getAttribute("position");
    let myPoints = [];
    for (let i = 0; i < myPositions.count; i++) {
      let x = myPositions.getX(i);
      let y = myPositions.getY(i);
      let z = myPositions.getZ(i);
      myPoints.push([x, y, z]);
    }
    setPosition(myPoints);
  }, []);
  return (
    <group
      castShadow={false}
      receiveShadow={false}
      name="fragments"
      visible={paramsTholos.decor.value}
    >
      <Circle
        ref={circleRef}
        position={[0, 1, 0]}
        rotation={[-Math.PI / 2, 0, Math.PI / 20]}
        args={[6.25, 20]}
      >
        <meshBasicMaterial wireframe visible={false} />
      </Circle>
      {positions ? (
        <group
          onPointerOver={overFragments}
          onPointerOut={outFragments}
          onClick={handleClick}
          name="pseudo-fragments"
          position={[0, 1, 0]}
          rotation={[-Math.PI / 2, 0, Math.PI / 20]}
        >
          {positions.slice(1, -1).map((obj, index) => (
            <Icosahedron
              key={index}
              position={[obj[0], obj[1], obj[2]]}
              args={[0.33, 0]}
            >
              <meshBasicMaterial
                wireframe
                color={hovered ? "orange" : "white"}
              />
            </Icosahedron>
          ))}
        </group>
      ) : null}
    </group>
  );
};

export default Fragments;
