export const fragmentsAcro = [
  {
    decor_ID: "acrotere_1",
    name: "4352",
    title: "Acrotère, torse et épaule gauche",
    position: [0, 0.2, 0],
    rotation: [10, 0, 0],
  },
  {
    decor_ID: "acrotere_2",
    name: "8804",
    title: "Acrotère, jambes",
    position: [0, -0.25, -0.1],
    rotation: [15, 0, 0],
  },
];
