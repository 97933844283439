import React, { useState, useEffect, useRef } from "react";
import { Toolbar } from "@mui/material";
import { useRoute, useLocation, Route, Router } from "wouter";
import useStyledXML from "../../hooks/useStyledXML";
import * as jp from "jsonpath";

import useHashLocation from "../../hooks/useHashLocation";

import CardText from "./ui/cardText";

const TholosTextes = (props) => {
  const env = import.meta.env.VITE_NODE_ENV;

  const RouteTextes = () => {
    const [, params] = useRoute("tholos/:id");

    const [location, setLocation] = useLocation();
    const [text, setText] = useState("");

    const { textVisible, closeText } = props;

    // console.log(params, location);

    const txt = useStyledXML("texteGeneral", "tableOfContent");

    useEffect(() => {
      if (params?.id && txt) {
        let [res] = jp.query(txt, `$..[?(@.subtype=="${params?.id}")]`);

        setText(res);
      }
      if (!params?.id && txt) {
        let [res] = jp.query(txt, `$..[?(@.subtype=="elements")]`);

        setText(res);
      }
    }, [params?.id, txt]);

    // console.log("text:", text);

    return (
      <Route path="/tholos" nest>
        {textVisible && (
          <>
            <Toolbar variant="dense" />

            <div className="cardText">
              {text ? <CardText {...text} closeText={closeText} /> : null}
            </div>
          </>
        )}
      </Route>
    );
  };

  const RouteProd = () => {
    return (
      <Router hook={useHashLocation}>
        <RouteTextes />
      </Router>
    );
  };

  const RouteDev = () => {
    return (
      <Router>
        <RouteTextes />
      </Router>
    );
  };

  const routes = {
    development: RouteDev,
    production: RouteProd,
  };

  const CurrentRoute = routes[env];

  return <CurrentRoute />;
};

export default TholosTextes;
