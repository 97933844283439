import React, { useEffect, useMemo, useLayoutEffect } from "react";
import useModelStore from "../../stores/useModelStore";

const useCanvas = (context, calque) => {
  if (calque.source.data) {
    const { height, width } = calque.source.data;
    console.log(height, width);

    const setCanvasSize = useModelStore((state) => state.setCanvasSize);
    const currentLayer = useModelStore((state) => state.currentLayer);
    const currentModel = useModelStore((state) => state.currentModel);

    /**
     * USEEFFECT
     */

    return useEffect(() => {
      context.current.reset();
      // context.current.clearRect(0, 0, width, height);

      typeof calque.source.data.src === "string"
        ? (setCanvasSize(height),
          context.current.drawImage(calque.source.data, 0, 0, width, height))
        : context.current.fillRect(0, 0, width, height);
      //context.current.reset()

      // context.current.restore();
    }, [
      calque.source.uuid,
      height,
      width,
      currentModel.file,
      currentLayer.value,
    ]);
  } else {
    console.log("pas de source pour canvas 2d");
  }
};

export default useCanvas;
