import { useProgress, Html } from "@react-three/drei";

function CustomLoader() {
  const { active, progress, errors, item, loaded, total } = useProgress();
  // console.log(item);
  return (
    <Html center className="loader">
      {item.split("/").slice(-1)}
      <br />
      {loaded} éléments sur {total} chargés
      <br />
      {Math.round(progress)} %
    </Html>
  );
}

export default CustomLoader;
