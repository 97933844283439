import React, { useState, useEffect, useRef } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Section3 from "./section3";
import Head from "./head";

import Paragraphe from "./paragraphe";

import { isArray } from "../../../outils";

const Section2Accordion = (props) => {
  const { handleChange, expanded } = props;


  return (
    <Accordion
      key={props[`@_xml:id`]}
      id={props[`@_xml:id`]}
      type={props[`@_type`]}
      expanded={expanded === props[`@_xml:id`]}
      onChange={handleChange(props[`@_xml:id`])}
    >
      <AccordionSummary>
        {props.head ? <Head text={props.head} variant="overline" /> : null}
      </AccordionSummary>

      <AccordionDetails>
        {props.p && isArray(props.p) ? (
          props.p.map((paragraphe, i) => (
            <Paragraphe
              key={paragraphe[`@_xml:id`]}
              text={paragraphe[`#text`]}
              id={paragraphe[`@_xml:id`]}
            />
          ))
        ) : props.p ? (
          <Paragraphe
            key={props.p[`@_xml:id`]}
            text={props.p[`#text`]}
            id={props.p[`@_xml:id`]}
          />
        ) : null}
        {props.div
          ? props.div.map((div, id) => <Section3 key={id} {...div} />)
          : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default Section2Accordion;
