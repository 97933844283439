import { XMLParser } from "fast-xml-parser";

const parserOptions = {
  trim: true,
};
const parser = new XMLParser();

async function getMultipleOpentheso(listParams) {
  let path = "https://opentheso.huma-num.fr/opentheso/api/";

  const fetchPromises = listParams.map((params) =>
    fetch(`${path}${params.idt}.${params.idc}`)
  );

  let result = [];

  Promise.all(fetchPromises)
    .then((responses) =>
      Promise.all(responses.map((response) => response.text()))
    )
    .then((texts) =>
      Promise.all(texts.map((text) => parser.parse(text, parserOptions)))
    )
    .then((raws) =>
      Promise.all(raws.map((raw) => raw[`rdf:RDF`][`rdf:Description`]))
    )
    .then((datas) =>
      Promise.all(
        datas.map((data) => {
          let myData = {
            idc: data[`dcterms:identifier`],
            creator: data[`dcterms:creator`],
            definition: data[`skos:definition`],
            label: data[`skos:prefLabel`],
            notation: data[`skos:notation`],
          };
          result.push(myData);
        })
      )
    )

    .catch((error) => {
      console.log(error);
    });

  return result;
}

export default getMultipleOpentheso;
