const fragmentShaderReplacements = [
  {
    from: "#include <common>",
    to: /*glsl*/ `
          #include <common>
          uniform sampler2D uTexBase;
          uniform sampler2D uTexLayer;
          uniform sampler2D uTexSource;
          uniform sampler2D uTexBlank;     
          uniform vec3 uEmissive;
          uniform float uMixFactor;
        `,
  },
  {
    from: "#include <color_pars_fragment>",
    to: /*glsl*/ `
          #include <color_pars_fragment>
          
            varying vec2 vUv;
            uniform sampler2D map;      
          
        `,
  },
  {
    from: "#include <color_fragment>",
    to: /*glsl*/ `
          #include <color_fragment> 
          {
            vec4 texLayer = texture2D(uTexLayer, vUv);
            vec4 texSource = texture2D(uTexSource, vUv);
            vec4 texBase = texture2D(uTexBase, vUv);
            vec4 texBlank = texture2D(uTexBlank, vUv);
  
            vec4 relight = vec4(uEmissive, 1.0); 
            diffuseColor.rgb = mix((texBase.rgb), (texBase.rgb * texLayer.rgb ), uMixFactor);
          }
        `,
  },
];

export default fragmentShaderReplacements;
