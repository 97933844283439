import * as React from "react";

import { Chip, Avatar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const TagConcept = (props) => {
  const { prefLabel, identifier, down, fragments } = props;

  const navigate = useNavigate();

  const idc = identifier[`#text`];

  const handleClick = () => {
    navigate(down ? `./${idc}` : `./../${idc}`);
    // console.log(idc);
  };

  //   const RouterLink = () => {
  //     return <Link to={`/${idc}`} />;
  //   };

  return (
    <Chip
      sx={{ m: 0.5 }}
      avatar={
        fragments?.length ? (
          <Avatar sx={{ bgcolor: `slategrey` }}>{fragments.length}</Avatar>
        ) : null
      }
      label={prefLabel[`#text`]}
      variant="outlined"
      // component={Link}
      // to={down ? `./${idc}` : `./../${idc}`}
      onClick={handleClick}
      clickable
    />
  );
};

export default TagConcept;
