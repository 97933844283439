import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { CssBaseline } from "@mui/material";
import UI from "./components/ui/ui";

import { Leva } from "leva";
import Views from "./views";
import Queries from "./queries";

import zIndex from "@mui/material/styles/zIndex";

const darkTheme = createTheme({
  typography: {
    fontFamily: ["Roboto Condensed", "Open Sans"].join(","),
    fontSize: 15,
  },
  palette: {
    mode: "dark",
    background: { default: "#525252" },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="leva">
        <Leva fill />
      </div>
      <Views />
      <Queries />
      <UI />
    </ThemeProvider>
  );
}
export default App;
