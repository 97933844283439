import React from "react";
import { Link } from "react-router-dom";

const Thesaurii = () => {
  let themes = [
    { label: "matéralité", value: "materialite", id: 1 },
    { label: "iconographie", value: "iconographie", id: 2 },
  ];

  return (
    <div>
      {/* <div id="retour" style={{ margin: "5px" }}>
        <Link to="/">retour</Link>
      </div>
      <header>Thésaurii disponibles</header> */}
      <div id="list">
        <ul>
          {themes.map((item, index) => (
            <li key={index}>
              <Link to={`/thesaurii/${item.value}`}>{item.label}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Thesaurii;
