import React from "react";
import { Box, Typography, Divider, Stack, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InputIcon from "@mui/icons-material/Input";

import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import { useNavigate } from "react-router-dom";

const BulleTheso = (props) => {
  const { idc } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/thesaurii/definition/${idc}`);
  };
  return (
    <Box sx={{ p: 1 }}>
      {props.prefLabel[`#text`] && (
        <>
          <Stack>
            <Typography variant="overline" gutterBottom>
              <span>{props.prefLabel[`#text`]}</span>
            </Typography>
          </Stack>

          <Divider />
        </>
      )}

      {props.definition && props.definition[`#text`] ? (
        <>
          <Stack sx={{ pt: 1 }}>
            <Typography variant="body2" paragraph align="justify">
              <span>{props.definition[`#text`]}</span>
            </Typography>
          </Stack>
          <Divider />
        </>
      ) : null}
      <Stack sx={{ pt: 1 }} direction="row" alignItems="end">
        <Button
          size="small"
          startIcon={<InputIcon fontSize="small" />}
          onClick={handleClick}
        >
          similaire
        </Button>
        <Button
          size="small"
          startIcon={<OpenInNewIcon fontSize="small" />}
          target="_blank"
          href={props.target}
          // variant="text"
        >
          opentheso
        </Button>
      </Stack>
    </Box>
  );
};

export default BulleTheso;
