import { useRef, Suspense, useState, useEffect } from "react";
import * as THREE from "three";
import {
  useProgress,
  Html,
  Billboard,
  Plane,
  ContactShadows,
} from "@react-three/drei";

import { useControls } from "leva";
import { Perf } from "r3f-perf";
import useModelStore from "../../stores/useModelStore";

import { Model, PlaceHolder, CustomLoader } from "../model";

import { getURLParameter, setDegreeToRadians } from "../../outils";

export default function Viewer() {
  const { perfVisible, BackgroundColor } = useControls("Moniteur", {
    perfVisible: {
      label: "Performances",
      value: false,
    },
    // rotate : {
    //     value : 0.5,
    //     min : 0,
    //     max : 1,
    //     step : 0.01
    // }
  });

  const {
    sunPosition,
    lightPosition,
    sphericalPosition,
    radius = 1,
    phi,
    theta,
    intensity,
  } = useControls("Eclairage", {
    // sunPosition : {
    //     label : 'Soleil',
    //     value : [1,2,3]
    // },
    // lightPosition : {
    //     label : 'Position éclairage',
    //     value : {x : 0, y : 0}
    // },
    // sphericalPosition : {
    //     label : 'sphériques',
    //     value : {phi : 0, theta : 0}
    // },
    // radius : {
    //     value : 1,
    //     min : 1,
    //     max : 100,
    //     step : 5
    // },
    phi: {
      label: "hauteur φ",
      value: -90,
      min: -180,
      max: -0,
      step: 0.5,
    },
    theta: {
      label: "azimuth θ",
      value: 180,
      min: 0,
      max: 360,
      step: 1,
    },
    intensity: {
      label: "intensité",
      value: 1,
      min: 0,
      max: 2,
      step: 0.1,
    },
  });

  const plan = useRef();

  const modelFileName = useModelStore((state) => state.currentModel.file);
  const layer = useModelStore((state) => state.currentLayer.value);
  const couche = useModelStore((state) => state.currentLayer.code);

  // var modelFileName = getURLParameter("objet", "4312-2");
  // console.log(modelFileName);
  // const previewModel = useGLTF(`./modeles/${modelFileName}_5K.glb`)

  const [previewModel, setPreviewModel] = useState();

  const coordSun = new THREE.Vector3();
  // const sphericalSun = new THREE.Spherical()
  // sphericalSun.set(radius, setDegreeToRadians(phi), setDegreeToRadians(theta))
  coordSun.setFromSphericalCoords(
    radius,
    THREE.MathUtils.degToRad(phi),
    THREE.MathUtils.degToRad(theta)
  );

  return (
    <>
      {perfVisible ? <Perf position="top-left" minimal={false} /> : null}

      <directionalLight
        castShadow
        shadow-mapSize={[512, 512]}
        position={[coordSun.x, coordSun.y, coordSun.z]}
        intensity={intensity}
      />

      <ambientLight intensity={0.5} />
      <ContactShadows
        name="ContactShadows"
        position={[0, -0.15, 0]}
        opacity={0.35}
        scale={0.75}
        blur={5}
        far={4}
        resolution={128}
      />
      <Suspense
        fallback={
          // null
          <CustomLoader />
        }
        // {previewModel != null ?
        //     <PlaceHolder modelFileName={modelFileName} size={[0.15,0.15,0.15]}/>
        //     :
        //     null
        // }
      >
        <Model
          modelFileName={modelFileName}
          currentLayer={layer}
          couche={couche}
        />
      </Suspense>
    </>
  );
}
