import { Vector3, Box3 } from "three";
/**
 *
 * @param {*} mesh
 * @param {number} distance
 * @returns {number[]}
 */

function setCameraPosition(mesh, distance) {
  const deplacement = new Vector3();

  if (mesh) {
    let pos = new Vector3();
    const direction = new Vector3();
    const center = new Vector3();
    const box = new Box3().setFromObject(mesh);
    pos = box.getCenter(center);
    const origin = new Vector3(0, pos.y, 0);
    direction.subVectors(pos, origin);
    deplacement.addScaledVector(direction, distance);
  }

  return [deplacement.x, deplacement.y, deplacement.z];
}

export default setCameraPosition;
