export const fragmentsPM = [
  {
    decor_ID: "petite-metope_38",
    name: "6590",
    title: "Métope à l'hydre de Lerne",
    position: [-0.06, -0.1, 0],
    rotation: [0, 0, 0],
  },
  {
    decor_ID: "petite-metope_39",
    name: "11662",
    position: [-0.13, -0.15, 0],
    rotation: [0, 0, 0],
  },
  {
    decor_ID: "petite-metope_40",
    name: "937",
    position: [-0.08, 0, 0.01],
    rotation: [0, 0, 0],
  },
];
