import React, { useState, useEffect } from "react";
import { Slider, Stack, Typography, FormControlLabel } from "@mui/material";
import { useFrame } from "@react-three/fiber";
import useUiStore from "../../../../stores/useUiStore";

const FragmentSlider = (props) => {
  const { title, param } = props;
  const paramsFragment = useUiStore((state) => state.paramsFragment);
  const setParamsFragment = useUiStore((state) => state.setParamsFragment);
  const { value, ...otherParams } = paramsFragment[param];

  const [localValue, setLocalValue] = useState(value);

  // const handleCommittedValue = (e, newValue) => {
  //   setParamsFragment({
  //     ...paramsFragment,
  //     [`${param}`]: { ...otherParams, value: newValue },
  //   });
  // };

  // const handleValue = (e, newValue) => {
  //   setLocalValue(newValue);
  // };

  useEffect(() => {
    setParamsFragment({
      ...paramsFragment,
      [`${param}`]: { ...otherParams, value: localValue },
    });
  }, [localValue]);

  const handleValue = (e, newValue) => {
    setLocalValue(newValue);
    // setParamsFragment({
    //   ...paramsFragment,
    //   [`${param}`]: { ...otherParams, value: newValue },
    // });
  };

  // useFrame((state, delta) => {

  // })

  return (
    <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
      <Typography gutterBottom>{title}</Typography>
      <Slider
        size="small"
        onChange={handleValue}
        // onChangeCommitted={handleCommittedValue}
        value={localValue}
        min={0.0}
        max={1.0}
        step={0.01}
      ></Slider>
    </Stack>
  );
};

export default FragmentSlider;
