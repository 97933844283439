import React from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Toolbar,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import TableDesMatieres from "../../chapitres/tableDesMatieres/tableDesMatieres";

const DrawerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 5*24px)",
  height: "calc(100% - 5*24px)",
  bgcolor: "background.paper",
  padding: "12px 0px 12px 0px",
  borderRadius: "12px",
};

const voletStyle = {
  height: "80vh",

  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#00000050",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "00000050",
    backgroundColor: "#555",
    borderRadius: "2px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#888",
  },
};

const DrawerToc = (props) => {
  const { open, handleClose } = props;
  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box
      // sx={modalStyle}
      >
        <Toolbar variant="dense">
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="button">
              Le décor sculpté de la Tholos
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <Grid container direction="row" alignItems="stretch">
          <Grid item>
            <Box sx={{ m: 1 }}>
              <TableDesMatieres style={voletStyle} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default DrawerToc;
