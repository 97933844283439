const vertexShaderReplacements = [
  {
    from: "#include <uv_pars_vertex>",
    to: /*glsl*/ `
            #include <uv_pars_vertex>
            varying vec2 vUv;
          `,
  },
  {
    from: "#include <uv_vertex>",
    to: /*glsl*/ `
            #include <uv_pars_vertex>
            {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
            }
          `,
  },
];

export default vertexShaderReplacements;
