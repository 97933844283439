const parserOptions = {
  ignoreAttributes: false,
  trim: true,
  ignoreDeclaration: true,
  ignorePiTags: true,
  removeNSPrefix: true,
  alwaysCreateTextNode: true,
};

export default parserOptions;
