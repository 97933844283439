import { useQuery } from "@tanstack/react-query";

const baseUrl = import.meta.env.VITE_SERVER_API;

const useQueryDefinitions = (path, fn) => {
  return useQuery({
    queryKey: [`${path}`],
    queryFn: async () => {
      const res = await fetch(`${baseUrl}/${path}`, {
        // credentials: "include",
      });
      if (!res.ok) {
        throw new Error("pb côté serveur");
      }
      return res.json();
    },

    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    onSuccess: fn,
  });
};
export default useQueryDefinitions;
