import React, { useState } from "react";

import TholosCanvas from "./tholosCanvas";
import TholosTextes from "./tholosTextes";
import Footer from "./ui/footer";
import SideBar from "./ui/sideBar";
import SettingsCard from "./ui/settingCard";

const Tholos3D = () => {
  const [textVisible, setTextVisible] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const openText = () => {
    setTextVisible(true);
  };

  const closeText = () => {
    setTextVisible(false);
  };

  const handleSettings = (value) => (e) => {
    setOpenSettings(value);
  };

  const fabsHandlers = {
    handleText: openText,
  };

  const VanillaTooltip = () => {
    return (
      <div className="point point-0">
        <div className="labelBulle">1</div>
        <div className="text">test</div>
      </div>
    );
  };

  return (
    <>
      <TholosCanvas />
      <TholosTextes textVisible={textVisible} closeText={closeText} />
      {/* <VanillaTooltip /> */}
      <div
        id="reglages-fragments"
        sx={{ visibility: openSettings ? "visible" : "hidden" }}
      >
        <SettingsCard open={openSettings} handleSettings={handleSettings} />
      </div>

      <SideBar {...fabsHandlers} />
      <Footer openSettings={openSettings} handleSettings={handleSettings} />
    </>
  );
};

export default Tholos3D;
