import React, { useEffect, useRef, useState, forwardRef } from "react";
import * as THREE from "three";
import { useGLTF, useAnimations, Html } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useRoute } from "wouter";
import { useNavigate } from "react-router-dom";
import { objectPosition } from "three/examples/jsm/nodes/Nodes.js";

import Label from "../ui/label";

export default function Metope(props) {
  const {
    name,
    hovered,
    handleLocalClick,
    collection,
    url,
    geometry,
    fragmentsMetopes,
    title,
  } = props;
  const group = useRef();
  const matRef = useRef();
  const meshRef = useRef();
  const htmlRef = useRef();
  const { nodes, materials } = useGLTF(url);

  const [localHovered, setLocalHovered] = useState();
  const [data, setData] = useState();

  const navigate = useNavigate();

  const onPointerOver = (e) => {
    if (match) {
      let myMetope = fragmentsMetopes.find((f) => f.decor_ID === name);
      myMetope && setData(myMetope);
    }

    // console.log(match);
    // let myFragment = fragmentsMetopes.find((f) => f.decor_ID === name);
    // console.log(myFragment.name);
    setLocalHovered(true);
  };
  const onPointerOut = (e) => {
    setLocalHovered(false);
  };

  const handleDoubleClick = (e) => {
    if (!data) {
      return;
    }
    navigate(`/fragments/${data?.name.replace("-", ",")}`);
  };

  const [match, params] = useRoute(`/?/${collection}`);

  const grey = new THREE.Color("grey");
  const goldenrod = new THREE.Color("goldenrod");
  const marble = new THREE.Color(0xa29a99);

  useFrame((state, delta) => {
    matRef.current.color.lerp(
      hovered && !match
        ? goldenrod
        : localHovered && match
        ? goldenrod
        : marble,
      0.3
    );
  });

  let visible = fragmentsMetopes.map((f) => f.decor_ID).includes(name)
    ? false
    : true;

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      onClick={handleLocalClick}
      onDoubleClick={handleDoubleClick}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    >
      <mesh
        ref={meshRef}
        name={`${name}_mesh`}
        transparent
        opacity={0.5}
        castShadow
        receiveShadow
        geometry={nodes[`${geometry}`].geometry}
        position={[0, 6.694, 0]}
        rotation={[-Math.PI, Math.PI / 10, -Math.PI]}
        visible={true}
      >
        <meshStandardMaterial
          name={`${name}_mat`}
          ref={matRef}
          transparent={true}
          opacity={visible ? 1 : 0.5}
          // visible={visible}
        />
      </mesh>

      {localHovered && match && data ? (
        <Label {...data} />
      ) : localHovered && !match ? (
        <Label name={name} title={title} />
      ) : null}
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/grande_metope.glb");
