import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function ColonneDorique(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modeles/tholos/colonne_dorique.glb");

  return (
    <group ref={group} {...props} dispose={null} name="colonne">
      <mesh
        name="doriquecolonneabaque001"
        castShadow
        receiveShadow
        geometry={nodes.doriquecolonneabaque001.geometry}
        material={materials.marbre}
        position={[0, 4.66207, 0]}
        rotation={[0, 0.15708, 0]}
      />
      <mesh
        name="doriquecolonneechine001"
        castShadow
        receiveShadow
        geometry={nodes.doriquecolonneechine001.geometry}
        material={materials.marbre}
        position={[0, 4.65708, 0]}
        rotation={[0, 0.15708, 0]}
      />
      <mesh
        name="doriquecolonnefut001"
        castShadow
        receiveShadow
        geometry={nodes.doriquecolonnefut001.geometry}
        material={materials.marbre}
        position={[0, 0.18187, 0]}
        rotation={[0, 0.15708, 0]}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/colonne_dorique.glb");
