import React from "react";

import { useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  Button,
  Box,
  Chip,
  Avatar,
  Link,
  Tooltip,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";

import useThesoStore from "../../../stores/useThesoStore";

import { createPhotoshopAct, createGimpGpl } from "../../../outils";

const PaletteLayer = (props) => {
  const { frag, annotations, lyr, definitions } = props;

  const navigate = useNavigate();

  const theso = useThesoStore((state) =>
    state.thesaurii[lyr].filter((t) => t.notation)
  );

  const paletteFile = [
    { ext: "gpl", label: "Palette GIMP" },
    { ext: "8bct", label: "Palette Photoshop Mac OS" },
    { ext: "act", label: "Palette Photoshop Windows" },
  ];

  const handleClick = (e, id) => {
    let fragment = id.replace("-", ",");
    navigate(`/fragments/${fragment}`);
  };

  const handleClickHex = (idc) => {
    navigate(`/thesaurii/${lyr}/${idc}`);
    // console.log(idc);
  };

  const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return [r, g, b];
  };

  function getValidHex() {
    let list = [];
    annotations.map((hexa, ind) =>
      definitions.map((m) => `${m.hex}`).includes(`${hexa.slice(0, -2)}`)
        ? list.push({ rgb: hex2rgb(hexa.slice(0, -2)), hex: hexa.slice(0, -2) })
        : null
    );
    return list;
  }

  const handleCreatePalette = (ext) => {
    let colors = getValidHex();

    switch (ext) {
      case "gpl":
        createGimpGpl(colors, frag, lyr, ext);
        break;
      default:
        createPhotoshopAct(colors, frag, lyr, ext, function (err, dataUrl) {
          console.log("done");
        });
    }
  };

  const getTheso = (hexa, theso) => {
    let res = theso.find((t) => t.notation[`#text`] === `${hexa}`);

    return { label: res?.prefLabel[`#text`], idc: res?.identifier[`#text`] };
  };

  return (
    <Stack
      spacing={1}
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: "primary.main",
        p: 1,
        mb: 2,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography
            variant="h6"
            color="primary"
            title={`vers le fragment ${frag.replace("-", ",")}`}
            onClick={(e) => handleClick(e, frag)}
            sx={{
              flexGrow: 1,
            }}
          >
            {frag}
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          {paletteFile.map((palette, ind) => (
            <Button
              key={ind}
              endIcon={<DownloadIcon />}
              sx={{ ml: 1 }}
              size="small"
              variant="outlined"
              title={palette.label}
              onClick={() => handleCreatePalette(palette.ext)}
            >
              *.{palette.ext}
            </Button>
          ))}
        </Box>
      </Stack>
      <Box>
        {annotations.map((hexa, ind) =>
          definitions &&
          definitions
            .map((m) => `${m.hex}`)
            .includes(`${hexa.slice(0, -2)}`) ? (
            <Tooltip
              key={ind}
              title={theso ? getTheso(hexa, theso).label : null}
              arrow
              placement="top"
            >
              <Chip
                key={ind}
                sx={{ mr: 1, my: 0.5 }}
                // size="small"
                variant="outlined"
                label={hexa.slice(0, -2)}
                color="default"
                avatar={
                  <Avatar sx={{ bgcolor: `${hexa.slice(0, -2)}` }}> </Avatar>
                }
                clickable
                onClick={() => handleClickHex(getTheso(hexa, theso).idc)}
              />
            </Tooltip>
          ) : (
            <Chip
              key={ind}
              sx={{ mr: 1, my: 0.5 }}
              size="small"
              label={hexa.slice(0, -2)}
              color="default"
            />
          )
        )}
      </Box>
    </Stack>
  );
};

export default PaletteLayer;
