import { setRGBtoHex } from "../../outils";
import { useMemo } from "react";

//getPalette(ctx, size)

function getPalette(ctx, size) {
  const imageData = ctx.getImageData(0, 0, size, size);
  // console.log(size, imageData.data.length);

  // Attention, Chrome integre la définition du colorSpace, mais pas Firefox.
  // Dans Blender, créer un document sRGB blanc (pour eviter que la transparence soit affichée en couleur noir) avec alpha à 0.
  // On peut exporter les images depuis Photoshop, en couleurs indexées et en sRGB IEC 61966-2.1
  // Les équivalents dans GIMP ou Krita ne fonctionnent pas et l'interpretation des couleurs n'est pas fidèle

  // S'assurer que les couches icono et matérialité offrent les mêmes dimensions en pixel

  let palette = [];

  // if (imageData.data.length < 5) {
  //   // console.log(imageData.data.length);

  //   return;
  // }

  // accélerer le calcul
  let stepList = {
    "@_256": 1,
    "@_512": 1,
    "@_1024": 2,
    "@_2048": 2,
  };

  let step = stepList[`@_${size}`];

  const RGBValues = [];
  for (let i = 0; i < imageData.data.length; i += 4) {
    let r = imageData.data[i];
    let g = imageData.data[i + 1];
    let b = imageData.data[i + 2];
    let a = imageData.data[i + 3];
    a < 255 ? null : RGBValues.push(setRGBtoHex(r, g, b));
  }
  palette = [...new Set(RGBValues)];

  return palette;
}
export default getPalette;
