import React, { useState, useEffect, useLayoutEffect } from "react";
import { Tooltip } from "@mui/material";
import useThesoStore from "../../../../stores/useThesoStore";
import { shallow } from "zustand/shallow";

import BulleTheso from "./bulleTheso";

const TooltipTheso = (props) => {
  const { idc, idt, text, target } = props;

  // const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [myData, setMyData] = useState(null);

  // const theso = useThesoStore.getState().theso;

  /**
   *  A MODIFIER : j'ai créé artificiellement un délai pour permettre le chargement des données
   */

  // useEffect(() => {
  //   useThesoStore.subscribe(
  //     (state) => state.isLoaded,
  //     (isLoaded, prevIsLoaded) =>
  //       isLoaded != prevIsLoaded
  //         ? setTimeout(() => setLoading(false), 1000)
  //         : null,
  //     { equalityFn: shallow, fireImmediately: true }
  //   );
  // }, []);

  const thesaurus = useThesoStore((state) => state.opentheso);

  useEffect(() => {
    let concept = thesaurus.find(
      (c) => c.identifier[`#text`] === parseInt(idc)
    );
    setMyData(concept);
  }, [thesaurus.length, idc]);

  //useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const result = await fetch(
  //         "https://api.tvmaze.com/search/shows?q=heist"
  //       );
  //       const data = await result.json();
  //       setData(data);
  //       setLoading(false);
  //     } catch (error) {
  //       setError(error);
  //       setLoading(false);
  //     }
  //   }
  //   fetchData();
  // }, []);

  // let data;
  // theso.length
  //   ? (data = theso.find((obj, i) => `${obj.idc}` === `${idc}`))
  //   : null;

  // console.log(myData, data);

  return (
    <Tooltip
      key={idc}
      title={
        // !loading && theso.length && data ?
        myData ? <BulleTheso {...myData} target={target} idc={idc} /> : null
      }
      arrow
      leaveDelay={250}
    >
      <mark className="theso" id="opentheso" idc={idc} idt={idt}>
        {text}
      </mark>
    </Tooltip>
  );
};

export default TooltipTheso;
