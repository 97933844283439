import React, { useState, useEffect, useMemo, Suspense } from "react";

import { useParams, Link } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import SelectColor from "./selectColor";

import "./palettes.css";

function invertHex(hex) {
  return (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substr(1).toUpperCase();
}

function getTextColor(rgba) {
  //   rgba = rgba.match(/\d+/g);
  if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 120) {
    return "#000000";
  } else {
    return "#FFFFFF";
  }
}

const Palette = () => {
  const params = useParams();
  const { definition } = params;

  const baseUrl = import.meta.env.VITE_SERVER_API;

  const [myPalette, setMyPalette] = useState();
  const [colorCode, setColorCode] = useState();
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState();

  let palettes = [
    { label: "matéralité", value: "materialite" },
    { label: "iconographie", value: "iconographie" },
  ];

  let isPalette = palettes.some((item) => item.value === definition);
  let currentPalette;

  isPalette
    ? (currentPalette = palettes.find((item) => item.value === definition))
    : null;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSelection = (e, updatedValue) => {
    setSelection(updatedValue);
  };

  const selectedValues = useMemo(
    () =>
      myPalette
        ?.filter((item) => item.descripteur)
        .filter((item) =>
          selection ? item.descripteur.includes(selection) : item.descripteur
        ),
    [myPalette, selection]
  );

  useEffect(() => {
    let result;
    if (isPalette) {
      fetch(`${baseUrl}/palettes/${definition}`)
        .then((response) => response.json())
        .then((response) => setMyPalette(response))
        .catch((err) => console.log(err));
    }
  }, [definition]);

  const ColorBox = (props) => {
    const { hex, red, green, blue, descripteur } = props;

    let colorText = getTextColor([red, green, blue, 1]) + "E6";

    const handleClick = (e, item) => {
      navigator.clipboard.writeText(item.hex);
      setColorCode(item.descripteur);
      setOpen(true);
    };

    return (
      <abbr
        title={`copier ${hex} dans le presse-papier`}
        className="ColorBox-Tooltip"
      >
        <div
          style={{
            backgroundColor: `${hex}`,
          }}
          onClick={(e) => handleClick(e, props)}
          className="ColorBox"
        >
          <div className="ColorBox-Legende">
            <div
              className="ColorBox-ColorName"
              style={{
                color: `${colorText}`,
              }}
            >
              {hex.toUpperCase()}
            </div>
            <div
              style={{
                color: `${colorText}`,
              }}
              className="ColorBox-Descripteur"
            >
              {descripteur}
            </div>
          </div>
        </div>
      </abbr>
    );
  };

  return (
    <div>
      {/* <div id="retour" style={{ margin: "5px" }}>
        <Link to="/palettes">retour</Link>
      </div> */}
      {isPalette ? (
        <div>
          <Suspense fallback={<div>Chargement ...</div>}>
            {/* <header> Palette {currentPalette.label}</header> */}

            <div className="PaletteBox">
              {myPalette &&
                selectedValues
                  .filter((item, index) => item.descripteur)
                  .map((item, index) => <ColorBox key={index} {...item} />)}
            </div>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="info">
                {colorCode && `Couleur de ${colorCode.toUpperCase()} copiée !`}
              </Alert>
            </Snackbar>

            <footer>
              <div className="footer">
                {myPalette && (
                  <SelectColor
                    palette={myPalette}
                    handleSelection={handleSelection}
                  />
                )}

                <span className="alignLeft">
                  <a href={`${baseUrl}/palettes/${definition}`} target="blank">
                    lien externe vers l'api
                  </a>
                </span>
              </div>
            </footer>
          </Suspense>
        </div>
      ) : (
        <header>
          Pas de palette '{definition}', essayez '
          <Link to="/palettes/iconographie">iconographie</Link>' ou '
          <Link to="/palettes/materialite">materialite</Link>'.
        </header>
      )}
    </div>
  );
};

export default Palette;
