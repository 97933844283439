import { XMLParser } from "fast-xml-parser";

const parserOptions = {
  trim: true,
};
const parser = new XMLParser(parserOptions);

async function getOpentheso(params) {
  const { idc, idt } = params;

  let path = "https://opentheso.huma-num.fr/opentheso/api/";

  try {
    // const response = await fetch(`${path}${idt}.${idc}`);
    // const content = await response.text();
    // const rawData = parser.parse(content, parserOptions);
    // const data = rawData[`rdf:RDF`][`rdf:Description`];
    // const returnData = {
    //   definition: data[`skos:definition`],
    //   label: data[`skos:preflabel`],
    //   notation: data[`skos:notation`],
    // };

    const response = await fetch(`${path}${idt}.${idc}`)
      .then((res) => res.text())
      .then((text) => parser.parse(text))
      .then((data) => data[`rdf:RDF`][`rdf:Description`]);

    const returnData = {
      definition: response[`skos:definition`],
      label: response[`skos:preflabel`],
      notation: response[`skos:notation`],
    };

    return { ...params, ...returnData };
  } catch (e) {
    console.log(e);
  }
}

export default getOpentheso;
