import React from "react";
import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  Stack,
  Avatar,
  Badge,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import NoticeMarcadeFastXmlParser from "../noticeMarcadeFastXmlParser";

const MyDrawer = styled(Drawer)(() => ({
  overflow: "hidden",
  "& .MuiModal-backdrop": {
    backgroundColor: "rgba(0,0,0,0.0)",
  },
}));

const NoticeDrawer = (props) => {
  const { open, width, fragment, setOpenNotice } = props;
  return (
    <MyDrawer
      anchor="right"
      open={open}
      // hideBackdrop
      elevation={0}
      // disableAutoFocus
      // disableEnforceFocus
      // variant="temporary"
      variant="persistent"
      // sx={{ pointerEvents: "none" }}
    >
      <Toolbar variant="dense">
        {/* <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Notice

        </Typography> */}
        <IconButton
          size="small"
          // sx={{ flexGrow: 1 }}
          onClick={() => setOpenNotice(false)}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <Box
        sx={{
          width: `${width}px`,
        }}
      >
        <NoticeMarcadeFastXmlParser fragment={fragment} />
      </Box>
    </MyDrawer>
  );
};

export default NoticeDrawer;
