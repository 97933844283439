function list(defs, layer) {
  let el = document.getElementById("descripteurs");
  while (el.firstChild) {
    el.firstChild.remove();
  }

  defs.forEach((item) => {
    let hex = `<span style="font-size: x-small">${item.hex.toUpperCase()}</span>;`;
    let li = document.createElement("li");
    li.innerHTML = `<span style="font-size: small; background : ${
      item.hex
    }" >&nbsp; &nbsp;</span> <abbr title="${item.hex.toUpperCase()}">${
      item.descripteur
    }</abbr>`;
    // li.innerText = `${item.hex} : ${item.descripteur}`;

    el.appendChild(li);
  });
}
export default list;
