import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Couverture(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/modeles/tholos/couverture.glb");

  return (
    <group ref={group} {...props} dispose={null} name="couverture">
      <mesh
        name="toiture"
        castShadow
        receiveShadow
        geometry={nodes.toiture.geometry}
        material={materials.marbre}
        position={[6.61, 7.339, 1.047]}
        rotation={[Math.PI / 2, 0, -2.985]}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/couverture.glb");
