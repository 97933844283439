import React, { useState, useEffect } from "react";
import { Box, Fab, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useFullScreen } from "../../../hooks";

import TuneIcon from "@mui/icons-material/Tune";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const MyBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    zIndex: 1051,
  },
}));

const Fabs = (props) => {
  const { handleSettings, handleDrawer, fragmentsNumber } = props;
  const backgroundColor = "#FFFFFF66";

  // const [isFullScreen, setIsFullScreen] = useState();
  // useFullScreen(setIsFullScreen);

  const handleFullScreenChange = () => {
    Boolean(document.fullscreenElement)
      ? document.exitFullscreen()
      : document.body.requestFullscreen();
  };

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }} id="fabs">
      <Fab
        size="small"
        title="Réglages"
        onClick={handleSettings(true)}
        sx={{ backgroundColor: backgroundColor }}
      >
        <TuneIcon />
      </Fab>

      <MyBadge badgeContent={fragmentsNumber} max={999} color="primary">
        <Fab
          size="small"
          title="Recherche"
          onClick={handleDrawer(true)}
          sx={{ backgroundColor: backgroundColor }}
        >
          <ManageSearchIcon />
        </Fab>
      </MyBadge>

      <Fab
        size="small"
        title="Aléatoire"
        disabled
        sx={{ backgroundColor: backgroundColor }}
      >
        <AutorenewIcon />
      </Fab>
      <Fab
        size="small"
        title={
          Boolean(document.fullscreenElement)
            ? "Sortir du plein écran"
            : "Plein écran"
        }
        sx={{ backgroundColor: backgroundColor }}
        onClick={handleFullScreenChange}
      >
        {Boolean(document.fullscreenElement) ? (
          <FullscreenExitIcon />
        ) : (
          <FullscreenIcon />
        )}
      </Fab>
      <Fab
        size="small"
        title="Zoom in"
        disabled
        sx={{ backgroundColor: backgroundColor }}
      >
        <ZoomInIcon />
      </Fab>
      <Fab
        size="small"
        title="Zoom Out"
        disabled
        sx={{ backgroundColor: backgroundColor }}
      >
        <ZoomOutIcon />
      </Fab>
    </Box>
  );
};

export default Fabs;
