import React, { useState, useEffect } from "react";
import { Box, Fab, Badge } from "@mui/material";

import useUiStore from "../../../../stores/useUiStore";

import TuneIcon from "@mui/icons-material/Tune";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const Fabs = (props) => {
  const { handleSettings, openSettings } = props;

  const [fullscreen, setFullscreen] = useState(
    Boolean(document.fullscreenElement)
  );
  const backgroundColor = "#FFFFFF66";

  const updateRandom = useUiStore((state) => state.updateRandom);

  const handleFullScreenChange = () => {
    Boolean(document.fullscreenElement)
      ? (document.exitFullscreen(), setFullscreen(false))
      : (document.body.requestFullscreen(), setFullscreen(true));
  };

  return (
    <Box
      sx={{
        pointerEvents: "none",
        "& > :not(style)": { m: 1, pointerEvents: "auto" },
      }}
      id="fabs"
    >
      <Fab
        size="small"
        title="Réglages"
        onClick={handleSettings(true)}
        sx={{ backgroundColor: openSettings ? "#FFFFFF" : backgroundColor }}
      >
        <TuneIcon />
      </Fab>

      <Fab
        size="small"
        title="Placement aléatoire"
        onClick={updateRandom}
        sx={{ backgroundColor: backgroundColor }}
      >
        <AutorenewIcon />
      </Fab>
      <Fab
        size="small"
        title={
          Boolean(document.fullscreenElement)
            ? "Sortir du plein écran"
            : "Plein écran"
        }
        sx={{ backgroundColor: backgroundColor }}
        onClick={handleFullScreenChange}
      >
        {Boolean(document.fullscreenElement) ? (
          <FullscreenExitIcon />
        ) : (
          <FullscreenIcon />
        )}
      </Fab>
    </Box>
  );
};

export default Fabs;
