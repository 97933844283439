import React, { useEffect, useState, Suspense } from "react";
import { Toolbar, Button, Box, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import PublishIcon from "@mui/icons-material/Publish";

import { getFragmentsAnnotations } from "../../../outils";
import useAnnotationStore from "../../../stores/useAnnotationStore";
import useDefinitionStore from "../../../stores/useDefinitionStore";

import PaletteLayer from "./paletteLayer";

const Admin = () => {
  const [annotations, setAnnotations] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [triggered, setTriggered] = useState(false);

  const navigate = useNavigate();

  const setAnnotationsParFragment = useAnnotationStore(
    (state) => state.setAnnotationsParFragment
  );

  const definitions = useDefinitionStore((state) => {
    return {
      iconographie: state.iconographie,
      materialite: state.materialite,
    };
  });

  const JSONToFile = (obj, filename) => {
    const blob = new Blob([JSON.stringify(obj, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleTrigger = () => {
    location.reload();
  };

  const handleCreateJsonFile = () => {
    JSONToFile(annotations, "testJsonFile");
  };

  const handlePushJson = () => {
    setAnnotationsParFragment(annotations);
  };

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      const data = await getFragmentsAnnotations();

      if (isSubscribed) {
        setAnnotations(data);
        setHasLoaded(true);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [triggered]);

  return (
    <>
      <Toolbar variant="dense" />
      <div>
        <div id="annotations">
          <Typography variant="h5">Admin</Typography>

          <Box>
            {!hasLoaded ? (
              <span>chargements et calculs en cours ... patience ...</span>
            ) : (
              <Suspense fallback={<span>chargement en cours ...</span>}>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    onClick={handleTrigger}
                    startIcon={<RefreshIcon />}
                  >
                    Recharger
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={handleCreateJsonFile}
                    startIcon={<DownloadIcon />}
                  >
                    Télécharger
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handlePushJson}
                    startIcon={<PublishIcon />}
                  >
                    Mettre à jour
                  </Button>
                </Stack>

                {hasLoaded &&
                  Object.keys(annotations).map((lyr, index) => (
                    <Stack
                      key={index}
                      spacing={2}
                      sx={{ border: 0, borderRadius: 1, p: 1, my: 1 }}
                    >
                      <Typography variant="h6">{lyr}</Typography>

                      {Object.keys(annotations[lyr]).map(
                        (frag, i) =>
                          definitions[`${lyr}`] && (
                            <PaletteLayer
                              key={i}
                              frag={frag}
                              annotations={annotations[lyr][frag]}
                              lyr={lyr}
                              definitions={definitions[`${lyr}`]}
                            />
                          )
                      )}
                    </Stack>
                  ))}
              </Suspense>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default Admin;
