import React, {
  Suspense,
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { useProgress } from "@react-three/drei";
import { MathUtils } from "three";

import MyLight from "./myLight";
import Grid from "./grid";

const loadingBarElement = document.querySelector(".loading-bar");

const CanvasFragments = (props) => {
  const { name, fragments } = props;

  // useEffect(() => {
  //   console.log("CanvasFragment");
  // }, []);

  const MyScene = () => {
    const { scene } = useThree();
    scene.name = name;
    // useEffect(() => {
    //   console.log("MyScene");
    // }, []);
  };

  const Overlay = () => {
    const active = useProgress((state) => state.active);
    const progress = useProgress((state) => state.progress);
    // const itemsLoaded = useProgress((state) => state.loaded);
    // const totalItems = useProgress((state) => state.total);

    const meshRef = useRef();
    const matRef = useRef();

    const uniforms = useMemo(
      () => ({
        uAlpha: { value: 1.0 },
      }),
      []
    );
    let ratio = 0;

    // useCallback(() => {
    //   console.log("déclenché");
    // }, [active]);

    // useEffect(() => {
    //   console.log("Overlay");
    // }, []);

    useEffect(() => {
      ratio = progress / 100;
      progress >= 100
        ? window.setTimeout(() => {
            (loadingBarElement.style.transform = "scaleX(0)"),
              // (loadingBarElement.style.transformOrigin = "top right"),
              //(loadingBarElement.style.transition ="transform 1.5s ease-in-out");

              loadingBarElement.classList.add("ended"),
              (loadingBarElement.style.transform = "");
          }, 500)
        : (loadingBarElement.classList.remove("ended"),
          (loadingBarElement.style.transform = `scaleX(${ratio})`));
      // (loadingBarElement.style.transformOrigin = "top left"),
      loadingBarElement.style.transition = "transform 0.5s";
    }, [progress]);

    const fragmentShader = `
    uniform float uAlpha;
    void main()
    {
      gl_FragColor = vec4(0.32,0.32,0.32, uAlpha);
    }`;
    const vertexShader = `
    void main()
    {
      gl_Position = vec4(position, 1.0);
    }`;

    useFrame((state, delta) => {
      // console.log(progress);
      // !active
      progress === 100
        ? (meshRef.current.material.uniforms.uAlpha.value = MathUtils.damp(
            meshRef.current.material.uniforms.uAlpha.value,
            0.0,
            1.0,
            delta * 0.75
          ))
        : null;
    });

    return (
      <mesh name="Overlay" ref={meshRef}>
        <planeGeometry args={[2, 2]} name="overlay-plane" />
        <shaderMaterial
          ref={matRef}
          name="overlay-material"
          transparent={true}
          uniforms={uniforms}
          fragmentShader={fragmentShader}
          vertexShader={vertexShader}
          uniformsNeedUpdate
        />
      </mesh>
    );
  };

  return (
    <Canvas
      id="scene3D"
      flat
      shadows
      dpr={[1, 2]}
      camera={{
        fov: 5,
        near: 0.01,
        far: 60,
        position: [0, 0, 40],
      }}
      // style={{ backgroundColor: "#246d52" }}
    >
      <Overlay />
      <MyScene />

      <Suspense fallback={null}>
        <MyLight />
        <ambientLight intensity={0.25} />
        {fragments && <Grid {...props} cellSize={0.65} />}
      </Suspense>
    </Canvas>
  );
};
export default CanvasFragments;
