import React, { memo } from "react";
import { AccumulativeShadows, RandomizedLight } from "@react-three/drei";

const Shadows = memo(() => (
  <AccumulativeShadows
    temporal
    frames={100}
    color="#9d4b4b"
    colorBlend={0.5}
    alphaTest={0.9}
    scale={5}
    position={[0, -0.549, 0]}
  >
    <RandomizedLight
      amount={8}
      radius={6}
      position={[12.5, 25, 25]}
      mapSize={1024}
    />
  </AccumulativeShadows>
));

export default Shadows;
