import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Divider,
  IconButton,
  Box,
  Skeleton,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import NarrowConcept from "../narrowConcept";
import BroadConcept from "../broadConcept";

import { useNavigate } from "react-router-dom";

import { getParams } from "../../../../outils";

const CardConcept = (props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleTop = () => {
    let { idc } = getParams(props.broader[`@_resource`]);
    navigate(`./../${idc}`);
  };

  return (
    <Card>
      <CardHeader
        title={
          props.prefLabel[`#text`] ? (
            <Typography variant="h5">{props.prefLabel[`#text`]}</Typography>
          ) : null
        }
        subheader={props.broader ? <BroadConcept {...props.broader} /> : null}
        action={
          <>
            {props.broader ? (
              <IconButton onClick={handleTop}>
                <ArrowUpwardIcon />
              </IconButton>
            ) : null}
            <IconButton onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
          </>
        }
      />
      <CardContent>
        {props.definition ? (
          <Typography variant="body2">{props.definition[`#text`]}</Typography>
        ) : null}
      </CardContent>
      <CardActions>
        <Box>
          {props.narrower ? (
            Array.isArray(props.narrower) ? (
              props.narrower.map((obj, index) => (
                <NarrowConcept key={index} {...obj} />
              ))
            ) : (
              <NarrowConcept key="index" {...props.narrower} />
            )
          ) : null}
        </Box>
      </CardActions>
    </Card>
  );
};

export default CardConcept;
