function checkIfUrlExists(url) {
  var http = new XMLHttpRequest();
  http.open("HEAD", url, false);
  try {
    http.send();
  } catch (err) {
    console.log(err);
  }
  // http.send();
  return http.status != 404;
}
export default checkIfUrlExists;
