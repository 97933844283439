import checkIfUrlExists from "./checkIfUrlExists";

function checkNormalFiles(rootToModel) {
  let hasNormal = false;
  let extension = "";
  let test = {};
  ["jpg", "png"].map((ext, index) => {
    let res = checkIfUrlExists(`${rootToModel}_normal.${ext}`);
    test = { ...test, [`${ext}`]: res };
    return;
  });

  test?.jpg
    ? ((extension = "jpg"), (hasNormal = true))
    : test?.png
    ? ((extension = "png"), (hasNormal = true))
    : ((extension = "not"), (hasNormal = false));

  return { extension, hasNormal };
}

export default checkNormalFiles;
