import React, { useEffect, useState } from "react";

import useThesoStore from "../../../stores/useThesoStore";

import { getParams, getAnnotedFragments } from "../../../outils";
import TagConcept from "./ui/tagConcept";
import useAnnotationStore from "../../../stores/useAnnotationStore";

const NarrowConcept = (props) => {
  const [data, setData] = useState(null);

  console.log(props);
  // const { theme } = props;

  const { idc } = getParams(props[`@_resource`]);

  const thesaurus = useThesoStore((state) => state.opentheso);

  // let myAnnotations = useAnnotationStore(
  //   (state) => state.annotationsParFragment[`${theme}`]
  // );

  // myAnnotations ? console.log(myAnnotations) : null;

  useEffect(() => {
    let concept = thesaurus.find(
      (c) => c.identifier[`#text`] === parseInt(idc)
    );
    setData(concept);
  }, [thesaurus.length, idc]);

  return data ? <TagConcept {...data} down={false} /> : null;
};

export default NarrowConcept;
