import React, { useEffect, useState } from "react";
import { Toolbar, Grid, Chip, Stack, Box, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

import FragmentsParConcept from "./fragmentsParConcept";
import CardConcept from "./ui/cardConcept";
import useThesoStore from "../../../stores/useThesoStore";

const Concept = () => {
  const [data, setData] = useState(null);
  const params = useParams();

  const index = useThesoStore((state) => state.index);
  const thesaurus = useThesoStore((state) => state.opentheso);

  function getTheme(concept) {
    return Object.keys(index)
      .map((t) => {
        if (index[t].includes(parseInt(concept))) return t;
      })
      .filter((x) => x)[0];
  }

  let myTheme = params.theme ? params.theme : getTheme(params.concept);

  useEffect(() => {
    let concept = thesaurus.find(
      (c) => c.identifier[`#text`] === parseInt(params.concept)
    );
    setData(concept);
  }, [thesaurus.length, params.concept]);

  return data ? (
    <>
      <Toolbar variant="dense" />
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Stack>
            <CardConcept {...data} theme={myTheme} />

            {data?.notation && myTheme && (
              <FragmentsParConcept
                notation={data.notation[`#text`]}
                theme={myTheme}
              />
            )}
          </Stack>
        </Grid>

        <Grid item xs={2} />
      </Grid>
    </>
  ) : null;
};

export default Concept;
