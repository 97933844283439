import React, { useCallback, useMemo } from "react";

const useOnBeforeCompile = (
  vertexShaderReplacements,
  fragmentShaderReplacements,
  uniforms,
  dependencies
) => {
  return useCallback((shader) => {
    vertexShaderReplacements.forEach((rep) => {
      shader.vertexShader = shader.vertexShader.replace(rep.from, rep.to);
    });

    fragmentShaderReplacements.forEach((rep) => {
      shader.fragmentShader = shader.fragmentShader.replace(rep.from, rep.to);
    });
    shader.uniforms = { ...shader.uniforms, ...uniforms };
  }, dependencies);
};

export default useOnBeforeCompile;
