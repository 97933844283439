import React, { useEffect } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";

const Outils = () => {
  const List = () => {
    return (
      <ul>
        <li>
          <Link to={`/tholos`}>tholos</Link>
        </li>
        <li>
          <Link to={`/fragments`}>fragments</Link>
        </li>
        <li>
          <Link to={`/thesaurii`}>thésaurii</Link>
        </li>
        <li>
          <Link to={`/viewer_sansLeva`}>viewer provisoire</Link>
        </li>
        <li>
          <Link to={`/palettes`}>palettes d'encodage</Link>
        </li>
        <li>
          <Link to={`/admin`}>outils de vérification</Link>
        </li>
      </ul>
    );
  };

  return (
    <div id="list">
      <List />
    </div>
  );
};

export default Outils;
