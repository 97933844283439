/**
 *
 * OLD DESCRIPTION 2015
 *
 */
// There is no version number written in the file.
// The file contains 256 RGB colors.
// The first color in the table is index zero.
// There are three bytes per color in the order Red, Green, Blue.

/**
 * DESCRIPTION FROM https://www.adobe.com/devnet-apps/photoshop/fileformatashtml/
 *
 * 2019
 */

// There is no version number written in the file.
// The file is 768 or 772 bytes long and contains 256 RGB colors.
// The first color in the table is index zero.
// There are three bytes per color in the order red, green, blue.
// If the file is 772 bytes long there are 4 additional bytes remaining.
// Two bytes for the number of colors to use.
// Two bytes for the color index with the transparency color to use.
// If loaded into the Colors palette, the colors will be installed in the color swatch list as RGB colors.

/**
 *
 * @param {number[][]} colors
 * @param {*} callback
 */

function createPhotoshopAct(colors, filename, layer, ext, callback) {
  // let bytesNumber = 256 * 3 + 2 * 2;
  let colorsRGB = colors.map((c) => c.rgb);
  let bytesNumber = 256 * 3;
  var array = new Uint8Array(bytesNumber);
  var i, color;
  for (i = 0; i < 256 * 3; i++) {
    array[i] = 0;
  }
  // for (i = bytesNumber - 2; i < bytesNumber; i++) {
  //   array[i] = 0;
  // }

  for (i = 0; i < colorsRGB.length; i++) {
    color = colorsRGB[i];
    array[3 * i] = color[0]; // R
    array[3 * i + 1] = color[1]; // G
    array[3 * i + 2] = color[2]; // B
  }
  var blob = new Blob([array], { type: "application/octet-stream" });

  //
  // var reader = new FileReader();
  // reader.onload = function (ev) {
  //   var err = null;
  //   callback(err, ev.target.result);
  // };
  // reader.readAsDataURL(blob);

  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}_${layer}.${ext}`;
  a.click();
  URL.revokeObjectURL(url);

  //
}

//
// var colors = [[255, 0, 0], [0, 255, 0], [0, 0, 255]];
// createPhotoshopAct(colors, function(err, dataURL) {
//     console.log(dataURL);
// });

export default createPhotoshopAct;
