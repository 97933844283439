import React, { useEffect, useRef, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as THREE from "three";

import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF, CameraControls, useCursor, Html } from "@react-three/drei";

import { useOnBeforeCompile } from "../../hooks";
import {
  vertexShaderReplacements,
  fragmentShaderReplacements,
} from "../../../shaders/fragment";

import useUiStore from "../../../stores/useUiStore";

import { setTextures } from "./setTextures";
import RenderTarget from "./renderTarget";
import Annotations from "./annotations/annotations";

import useDefinitionStore from "../../../stores/useDefinitionStore";
import { setHexToRGB } from "../../../outils";

const FragmentModel = (props) => {
  const {
    filename,
    fitToSphere,
    couche,
    rotationFragment,
    annotationsOn,
    setOpenNotice,
  } = props;
  const { viewport, pointer, mouse } = useThree();
  const navigate = useNavigate();

  const [rotX, rotY, rotZ] = rotationFragment.map((item) =>
    THREE.MathUtils.degToRad(item)
  );

  // console.log(annotationsOn);

  const coucheActive = {
    materialite: 0.0,
    iconographie: 1.0,
  };

  const paramsFragment = useUiStore((state) => state.paramsFragment);
  const mixFactor = paramsFragment.diffuse.value;
  const nScaleFactor = paramsFragment.normale.value;

  const currentCouche = useDefinitionStore((state) => state.currentCouche);

  const currentColor = useDefinitionStore(
    (state) => state?.activeColor?.[currentCouche]
  );
  const previousColor = useDefinitionStore(
    (state) => state?.previousColor?.[currentCouche]
  );

  const setActiveColor = useDefinitionStore((state) => state.setActiveColor);

  const definitions = useDefinitionStore((state) => state[currentCouche]);

  const mouseActive = useDefinitionStore((state) => state.mouseActive);
  const noticeActive = useDefinitionStore((state) => state.noticeActive);
  const annotationActive = useDefinitionStore(
    (state) => state.annotationActive
  );
  const setMouseActive = useDefinitionStore((state) => state.setMouseActive);

  const [entered, setEntered] = useState(false);
  const [hovered, setHovered] = useState(false);

  const [popup, setPopup] = useState(false);
  const [delayedEvent, setDelayedEvent] = useState(false);

  const [tooltipPos, setTooltipPos] = useState([0, 0, 0]);

  useCursor(hovered);

  const [triggerFitToSphere, setTriggerFitToSphere] = useState();

  let opacity = useMemo(() => {
    return { value: 0.0 };
  }, [currentColor, previousColor]);

  /**
   *
   */

  var rootToModel = `/modeles/${filename}/${filename}`;
  var pathToModel = `${rootToModel}.glb`;
  var pathToDraco = "/draco/";

  /**
   *  REFs
   */
  const group = useRef();
  const meshRef = useRef();
  const matRef = useRef();
  const cameraControlsRef = useRef();
  const tooltipRef = useRef();

  /**
   * TEXTURES
   */

  const { map, normalMap, emissiveMap, materialite, iconographie } =
    setTextures(rootToModel);

  const normalScale = new THREE.Vector2(2, 2).multiplyScalar(nScaleFactor);
  const emissiveIntensity = 5.0;

  const couches = { materialite, iconographie };

  /**
   * COLORS
   */

  const diffuse = "#ffffff";
  const color = "#ffffff";
  const emissive = "#ffffff";
  const defaultColor = 0xffffff;

  /**
   * TIME
   */

  const time = useMemo(() => {
    return { value: 0.0 };
  }, []);

  const mouseUV = useMemo(() => {
    return { value: new THREE.Vector2(0.0, 0.0) };
  }, []);

  const currentCursor = useMemo(() => new THREE.Vector2(2000, 2000));
  const previousCursor = useMemo(() => new THREE.Vector2(2000, 2000));

  const alpha = useRef();

  useFrame((state, delta) => {
    const { clock } = state;

    time.value = clock.getElapsedTime();

    if (hovered || noticeActive || annotationActive) {
      opacity.value = THREE.MathUtils.lerp(opacity.value, 1.0, 0.15);
      // currentCursor.copy(mouse)
      // let cursorDistance = previousCursor.distanceTo(currentCursor)
      // alpha.current =  Math.min(cursorDistance * 0.1, 1)
      // alpha.current  <= 0.01 ? setPopup(true) : setPopup(false)
      // previousCursor.copy(currentCursor)
    }
  });

  /**
   *  MODEL LOADER
   */

  const model = useGLTF(pathToModel, pathToDraco);
  const { nodes } = model;

  const listMeshes = Object.values(nodes).filter((node) => node.isMesh);

  /**
   *  OBJECT CENTER
   */
  let radius, center;
  if (meshRef?.current?.geometry?.boundingSphere) {
    radius = meshRef.current.geometry.boundingSphere.radius;
    center = meshRef.current.geometry.boundingSphere.center;
  } else {
    radius = 0.3;
    center = { x: 0, y: 0, z: 0 };
  }

  /**
   * ZOOM TO OBJECT
   */

  useEffect(() => {
    cameraControlsRef.current.fitToSphere(group.current, true);
  }, [viewport.aspect, fitToSphere, triggerFitToSphere]);

  /**
   *  SHADER
   */

  const uniforms = {
    color: { value: new THREE.Color(color) },
    uActiveColor: {
      value: new THREE.Color(parseInt(`0x${currentColor}`)),
      // ||
      // new THREE.Color(defaultColor),
    },
    uPreviousColor: {
      value: new THREE.Color(parseInt(`0x${previousColor}`)),
      //  ||
      // new THREE.Color(defaultColor),
    },
    uTime: time,
    uDiffuse: { value: new THREE.Color(diffuse) },
    uEmissive: { value: new THREE.Color(emissive) },
    uShade: { value: new THREE.Color(0xcccccc) },
    map: { value: map },
    uMixFactor: { value: 1 - mixFactor },
    normalMap: { value: normalMap },
    normalScale: { value: normalScale },
    uEmissiveMap: { value: emissiveMap },
    uEmissiveIntensity: { value: emissiveIntensity },
    uMixCouche: { value: coucheActive[currentCouche] },
    uMaterialite: { value: materialite },
    uIconographie: { value: iconographie },
    uMouseUV: mouseUV,
    uMouseActive: { value: mouseActive ? 1.0 : 0.0 },
    uOpacity: opacity,
  };

  const dependencies = [
    mixFactor,
    map,
    nScaleFactor,
    color,
    defaultColor,
    time,
    emissive,
    diffuse,
    // coucheActive[currentCouche],
    currentCouche,
    currentColor,
    previousColor,
    materialite,
    iconographie,
    mouseUV.value,
    mouseActive,
    noticeActive,
    annotationActive,
    opacity,
  ];

  const defines = {
    // USE_COLOR: true,
    // USE_UV: false,
    USE_MAP: true,
    USE_NORMALMAP: true,
    USE_EMISSIVE: true,
    USE_EMISSIVEMAP: false,
    USE_TANGENT: false,
    USE_CLEARCOAT_NORMALMAP: false,
    TANGENTSPACE_NORMALMAP: true,
    OBJECTSPACE_NORMALMAP: false,
    FLAT_SHADED: false,
    FLIP_SIDED: false,
    DOUBLE_SIDED: false,
    USE_BUMPMAP: false,
    HIGH_PRECISION: false,
    DITHERING: false,
    USE_ENVMAP: false,
    ENVMAP_TYPE_CUBE: false,
    TONE_MAPPING: false,
  };

  const OBC = useOnBeforeCompile(
    vertexShaderReplacements,
    fragmentShaderReplacements,
    uniforms,
    dependencies
  );

  useEffect(
    () =>
      void (matRef.current.dispose(),
      (matRef.current.needsUpdate = true),
      (matRef.current.forceSinglePass = true),
      (matRef.current.onBeforeCompile = OBC)),

    [OBC]
  );

  /**
   *  HANDLERS
   **/

  const onMouseOver = (e) => {
    e.stopPropagation();
    setMouseActive(true);
    const { uv, pointer, point } = e;
    mouseUV.value = uv;

    switch (currentColor) {
      case "ffffff":
        // console.log("couleur neutre");
        setHovered(false);
        break;
      case "000000":
        // console.log("couleur neutre");
        setHovered(false);
        break;
      case "ff1493":
        // console.log("pas de couche");
        break;
      default:
        setHovered(true);
      // popup ? myPopup(e): console.log("no popup")
    }
  };

  const onMouseOut = (e) => {
    e.stopPropagation();
    tooltipRef.current.style.visibility = "hidden";
    tooltipRef.current.style.opacity = "0";
    tooltipRef.current.innerHTML = "";
    setActiveColor("ffffff", currentCouche);
    setMouseActive(false);
    setHovered(false);
  };

  const onPointerMissed = (e) => {
    e.stopPropagation();

    if (e.type === "dblclick") {
      setOpenNotice(false);
      // console.log("double click");
    }

    setTriggerFitToSphere(!triggerFitToSphere);
  };

  function myPopup(e) {
    let myDefinition = definitions
      .filter((d) => d.hex.slice(1, 7) === currentColor)
      .map((o) => {
        return {
          hex: o.hex,
          descripteur: o.descripteur,
        };
      });

    tooltipRef.current.style.visibility = hovered ? "visible" : "hidden";
    tooltipRef.current.style.opacity = hovered ? "1" : "0";
    tooltipRef.current.innerHTML = myDefinition[0].descripteur;

    let { point } = e;
    setTooltipPos([point.x, point.y, point.z]);
  }

  const onContextMenu = (e) => {
    let myDefinition = definitions
      .filter((d) => d.hex.slice(1, 7) === currentColor)
      .map((o) => {
        return {
          hex: o.hex,
          descripteur: o.descripteur,
        };
      });

    tooltipRef.current.style.visibility =
      hovered && myDefinition[0]?.descripteur != undefined
        ? "visible"
        : "hidden";
    tooltipRef.current.style.opacity = hovered ? "1" : "0";
    tooltipRef.current.innerHTML = myDefinition[0]?.descripteur
      ? myDefinition[0].descripteur
      : "";

    let { point } = e;
    setTooltipPos([point.x, point.y, point.z]);
  };

  const onDoubleClick = (e) => {
    e.stopPropagation();

    switch (currentColor) {
      case "ffffff":
        console.log("couleur neutre");
        break;
      case "000000":
        console.log("couleur neutre");
        break;
      case "ff1493":
        console.log("pas de couche");
        break;
      default:
        navigate(`/fragments/${currentCouche}/${currentColor}`);
    }
  };

  useGLTF.preload(pathToModel, pathToDraco);

  return (
    <>
      <group
        {...props}
        ref={group}
        name={filename}
        rotation={[rotX, rotZ, rotY]}
        onPointerMove={onMouseOver}
        onPointerOut={onMouseOut}
        onContextMenu={onContextMenu}
        onPointerMissed={onPointerMissed}
        onDoubleClick={onDoubleClick}
        onPointerEnter={() => setEntered(true)}
        onPointerLeave={() => setEntered(false)}
      >
        {listMeshes.map((mesh, index) => (
          <mesh
            ref={meshRef}
            castShadow
            receiveShadow
            key={index}
            name={mesh.name}
            geometry={mesh.geometry}
            position={mesh.position}
            rotation={mesh.rotation}
          >
            <meshStandardMaterial
              flatShading={true}
              name={`${filename}_mat`}
              ref={matRef}
              onBeforeCompile={OBC}
              defines={defines}
              forceSinglePass
              needsUpdate
            />
          </mesh>
        ))}

        <CameraControls ref={cameraControlsRef} />
        <RenderTarget
          map={couches[currentCouche]}
          mouseActive={mouseActive}
          mouse={mouseUV}
          couche={currentCouche}
        />
      </group>

      {annotationsOn && definitions.length > 0 ? (
        <Annotations
          {...props}
          center={center}
          radius={radius}
          setMouseActive={setMouseActive}
        />
      ) : null}

      <Html
        ref={tooltipRef}
        position={tooltipPos}
        style={{
          padding: "10px",
          margin: "10px",
          top: "-60px",
          borderRadius: "15px",
          backgroundColor: "rgba(0,0,0,0.25)",
          minWidth: "75px",
          visibility: "hidden",
          opacity: 0,
          transition: "visibility 0.5s, opacity 0.5s",
          width: "200px",
          left: "-100px",
          textAlign: "center",
          //   "&:hover": {
          //     visibility: 'visible'
          // }
        }}
        // className="labelBulle"
      ></Html>
    </>
  );
};

export default FragmentModel;
