import React from "react";
import { Grid } from "@react-three/drei";

const Ground = () => {
  const gridConfig = {
    cellSize: 0.5,
    cellThickness: 0.5,
    cellColor: "#6f6f6f",
    sectionSize: 5,
    sectionThickness: 1,
    sectionColor: "#9d4b4b",
    fadeDistance: 60,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true,
  };

  return (
    <Grid
      position={[0, -0.55, 0]}
      args={[10, 10]}
      {...gridConfig}
      name="Grille"
    />
  );
};

export default Ground;
