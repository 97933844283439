import React, { useState } from "react";
import { TextField, Autocomplete, Box } from "@mui/material";
import useModelStore from "../../stores/useModelStore";

const SelectLayer = () => {
  //const layers = useModelStore((state) => state.layers);
  const layers = [
    { label: "base", value: "base", code: "0f" },
    { label: "iconographie", value: "iconographie", code: "00" },
    { label: "matérialité", value: "materialite", code: "ff" },
  ];

  const currentLayer = useModelStore((state) => state.currentLayer);
  const setCurrentLayer = useModelStore((state) => state.setCurrentLayer);

  const [value, setValue] = useState(currentLayer);

  const handleSelectLayer = (e, updatedValue) => {
    setCurrentLayer(updatedValue);
  };

  const handleInputChange = (e, updatedValue) => {
    setCurrentLayer();
    setValue(updatedValue);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mx: 1 }}
    >
      <Autocomplete
        id="SelectModel"
        autoSelect
        disableClearable
        options={layers}
        isOptionEqualToValue={(option) => option.label}
        // getOptionLabel={(option) => option.file}
        // renderOption={(option) => option.label}
        value={currentLayer}
        // inputValue={value.label}
        onChange={handleSelectLayer}
        //onInputChange={handleInputChange}
        sx={{ width: 160, position: "middle" }}
        renderInput={(params) => (
          <TextField {...params} label="couche" margin="dense" size="medium" />
        )}
      />
    </Box>
  );
};

export default SelectLayer;
