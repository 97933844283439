import React from "react";
import { ToggleButton } from "@mui/material";

import ViewInArIcon from "@mui/icons-material/ViewInAr";

import useModelStore from "../../../../stores/useModelStore";

const Button3d = () => {
  const field = "gltf";
  const { filter, setFilter } = useModelStore();
  const handleChange = (e) => {
    setFilter({ ...filter, [field]: !filter[field] ? true : null });
  };
  return (
    <ToggleButton
      color="primary"
      title="modèles 3d"
      value="3d"
      size="small"
      selected={filter[field] ? true : false}
      onChange={handleChange}
    >
      <ViewInArIcon color={filter[field] ? "primary" : "disabled"} />
    </ToggleButton>
  );
};

export default Button3d;
