import * as THREE from "three";
import useDefinitionStore from "../../../stores/useDefinitionStore";

function getCode(color, previousColor, couche) {
  const definitions = useDefinitionStore.getState()[couche];

  const currentDefinition = color.getHexString();
  const previousDefinition = previousColor.getHexString();

  useDefinitionStore.setState((state) => ({
    ...state,
    activeColor: { ...state.activeColor, [couche]: currentDefinition },
    previousColor: {
      ...state.previousColor,
      [couche]: previousDefinition,
    },
  }));

  // console.log(
  //   definitions.find((d) => d.hex === `#${currentDefinition}`)?.descripteur
  // );

  return;
}

export default getCode;
