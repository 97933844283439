import React from "react";
import ColonneDorique from "./colonne";

const colonnes = [
  {
    name: "dorique.colonne.021",
    x: -6.261213302612305,
    y: 0.0,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 0.0,
  },
  {
    name: "dorique.colonne.022",
    x: -5.95476770401001,
    y: -1.934821367263794,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 0.3141592741012573,
  },
  {
    name: "dorique.colonne.023",
    x: -5.065427780151367,
    y: -3.680248975753784,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 0.6283185482025146,
  },
  {
    name: "dorique.colonne.024",
    x: -3.680248737335205,
    y: -5.065428256988525,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 0.9424777626991272,
  },
  {
    name: "dorique.colonne.025",
    x: -1.9348212480545044,
    y: -5.954768180847168,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 1.2566369771957397,
  },
  {
    name: "dorique.colonne.026",
    x: 4.76837158203125e-7,
    y: -6.261213302612305,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 1.5707963705062866,
  },
  {
    name: "dorique.colonne.027",
    x: 1.934821605682373,
    y: -5.954767227172852,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 1.884955644607544,
  },
  {
    name: "dorique.colonne.028",
    x: 3.6802492141723633,
    y: -5.065427780151367,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 2.1991147994995117,
  },
  {
    name: "dorique.colonne.029",
    x: 5.065428256988525,
    y: -3.680248498916626,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 2.5132741928100586,
  },
  {
    name: "dorique.colonne.030",
    x: 5.954768180847168,
    y: -1.9348211288452148,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 2.8274333477020264,
  },
  {
    name: "dorique.colonne.031",
    x: 6.261213302612305,
    y: 5.473726787386113e-7,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 3.1415929794311523,
  },
  {
    name: "dorique.colonne.032",
    x: 5.95476770401001,
    y: 1.9348218441009521,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 3.45575213432312,
  },
  {
    name: "dorique.colonne.033",
    x: 5.065427780151367,
    y: 3.6802494525909424,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 3.769911527633667,
  },
  {
    name: "dorique.colonne.034",
    x: 3.680248260498047,
    y: 5.065429210662842,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 4.084070682525635,
  },
  {
    name: "dorique.colonne.035",
    x: 1.9348207712173462,
    y: 5.954768180847168,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 4.398230075836182,
  },
  {
    name: "dorique.colonne.036",
    x: -1.0242098369417363e-6,
    y: 6.261213302612305,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 4.71238899230957,
  },
  {
    name: "dorique.colonne.037",
    x: -1.9348220825195312,
    y: 5.954767227172852,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 5.026548385620117,
  },
  {
    name: "dorique.colonne.038",
    x: -3.6802496910095215,
    y: 5.065427780151367,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 5.340707778930664,
  },
  {
    name: "dorique.colonne.039",
    x: -5.065429210662842,
    y: 3.6802480220794678,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 5.654867172241211,
  },
  {
    name: "dorique.colonne.040",
    x: -5.954768180847168,
    y: 1.9348206520080566,
    z: 0.12347489595413208,
    rotX: 0,
    rotY: 0,
    rotZ: 5.9690260887146,
  },
];

const Colonnade = () => {
  return (
    <group name="colonnade">
      {colonnes.map((item, index) => (
        <ColonneDorique
          key={index}
          position={[item.x, item.z, item.y]}
          rotation={[item.rotX, -item.rotZ, item.rotY]}
        />
      ))}
    </group>
  );
};

export default Colonnade;
