import React, { useMemo, useState } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { Html, useCursor } from "@react-three/drei";

import useAnnotationStore from "../../../../stores/useAnnotationStore";
import useDefinitionStore from "../../../../stores/useDefinitionStore";
import useQueryDefinitions from "../../../hooks/useQueryDefinitions";
import useQueries from "../../../hooks/useQueries";

import Annotation from "./annotation";

const Annotations = (props) => {
  const { couche, filename: name, center, radius, setMouseActive } = props;

  const blankAnnotations = ["#000000", "#ffffff", "#ff1493"];

  // const annotations = useAnnotationStore(
  //   (state) => state.annotationsParFragment
  // );
  // let mesAnnotations = annotations[couche][name];

  let { data: annots, isSuccess: annotsSuccess } = useQueries(
    `/json/annotationsParFragment.json`
  );

  let mesAnnotations = annotsSuccess ? annots[couche][name] : null;

  let { data: defs, isSuccess: defsSuccess } = useQueryDefinitions(
    `palettes/${couche}`
  );

  // let definitions = useDefinitionStore((state) => state[couche]);

  let definitions = defs;

  let mesDefinitions;

  if (mesAnnotations && defs) {
    mesDefinitions = definitions
      .filter((d) => mesAnnotations.map((a) => a.slice(0, 7)).includes(d.hex))
      .map((o) => {
        return {
          hex: o.hex,
          descripteur: o.descripteur,
        };
      });
  }

  const mesPositions = useMemo(() => {
    let positions = [];
    for (let i = 0; i < mesDefinitions?.length; i++) {
      const theta = THREE.MathUtils.randFloatSpread(360);
      const phi = THREE.MathUtils.randFloatSpread(360);
      let distance = radius * 1.65;

      let x = distance * Math.sin(theta) * Math.cos(phi);
      let y = distance * Math.sin(theta) * Math.sin(phi);
      let z = distance * Math.cos(theta);

      let point = new THREE.Vector3(x, y, z);

      let pos = [x, y, z];
      positions.push(point);
    }
    return positions;
  }, [mesDefinitions?.length]);

  return (
    <group position={[center.x, center.y, center.z]}>
      {mesDefinitions &&
        mesDefinitions.map((annotation, i) => (
          <Annotation
            key={i}
            position={mesPositions[i]}
            couche={couche}
            setMouseActive={setMouseActive}
            {...annotation}
          />
        ))}
    </group>
  );
};

export default Annotations;
