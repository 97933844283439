import React, { useCallback, useMemo } from "react";

const useOnBeforeRender = (calque, modelFileName) => {
  return useCallback(
    (renderer, scene, camera, geometry, material, group) => {
      material.uniformsNeedUpdate = true;
    },
    [calque, modelFileName]
  );
};

export default useOnBeforeRender;
