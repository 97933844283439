import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

// A SUPPRIMER ET A REMPLACER PAR UN USEQUERY DANS COMPOSANT LOCAL

const useAnnotationStore = create((set) => ({
  annotationsParFragment: {},
  setAnnotationsParFragment: (annotationsParFragment) =>
    set((state) => ({ ...state, annotationsParFragment })),
}));

fetch(`/json/annotationsParFragment.json`)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error("pb");
  })
  .then((annotations) =>
    useAnnotationStore.setState((state) => ({
      ...state,
      annotationsParFragment: annotations,
    }))
  )

  .catch((error) => {
    console.log(error);
  });

if (import.meta.env.VITE_NODE_ENV === "development") {
  mountStoreDevtool("AnnotationStore", useAnnotationStore);
}

export default useAnnotationStore;
