import { useEffect, useRef } from "react";
import { useLoader, useThree, useFrame } from "@react-three/fiber";
import {
  Loader,
  Sphere,
  Mesh,
  ShaderMaterial,
  MeshStandardMaterial,
  MeshBasicMaterial,
} from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import { TilesRenderer, DebugTilesRenderer } from "3d-tiles-renderer";

const Tiles = ({ url, showTraversability, name, visibility }) => {
  const { camera, gl, scene } = useThree();
  const tilesRef = useRef(null);

  useEffect(() => {
    initialize();
    return () => cleanup();
  }, [url, camera, gl, scene]);

  const initialize = () => {
    const tiles = new TilesRenderer(url);
    tilesRef.current = tiles;
    tiles.displayBoxBounds = false;
    tiles.setCamera(camera);
    tiles.setResolutionFromRenderer(camera, gl);
    tiles.fetchOptions.mode = "cors";
    tiles.errorTarget = 6;
    tiles.group.rotation.set(-Math.PI / 2, 0, (Math.PI / 7.5) * -0.55);
    // tiles.group.position.set(-0.08, 0.04, -0.1);
    // tiles.group.rotation.set(-Math.PI / 2.04, 0, (Math.PI / 7.5) * -0.52);
    tiles.group.name = name;
    scene.add(tiles.group);

    if (showTraversability) {
      // const material = new ShaderMaterial(slopeShader)
      const material = new MeshBasicMaterial({ flatShading: true });
      tiles.onLoadModel = (scene, tile) => {
        scene.traverse((child) => {
          if (child instanceof Mesh) {
            child.material = material;
          }
        });
      };
    }
  };

  const cleanup = () => {
    if (tilesRef.current) {
      scene.remove(tilesRef.current.group);
    }
  };

  useEffect(() => {
    if (tilesRef.current) {
      tilesRef.current.group.visible = visibility;
    }
  }, [visibility]);

  useFrame(() => {
    tilesRef.current?.update();
    camera.updateMatrixWorld();
  });

  return null;
};

export default Tiles;
