import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useCallback,
  Suspense,
} from "react";
import * as THREE from "three";
import { useGLTF, Html, CameraControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useControls } from "leva";

import {
  useCanvas,
  useOnBeforeCompile,
  useOnBeforeRender,
} from "../../components/hooks";
import {
  getImgData,
  getPixDefinition,
  getPalette,
  getDefinitions,
} from "../../components/CanvasElement";
import {
  SetTexture,
  SetTextureBlank,
  useSetTexture,
  LoadTexture,
} from "../../components/model";

import {
  vertexShaderReplacements,
  fragmentShaderReplacements,
} from "../../shaders/replacements";

import iconographie from "../../assets/json/iconographie.json";
import materialite from "../../assets/json/materialite.json";

import { label, list } from "../ui";

export default function Model(props) {
  const { modelFileName, currentLayer } = props;
  const { camera } = useThree();

  var pathToModel = `/modeles/${modelFileName}/${modelFileName}.glb`;
  var pathToDraco = "/draco/";

  const [hover, setHover] = useState();

  const mouseUV = useRef(new THREE.Vector2());

  const eventHandler = (e) => {
    const { uv } = e;
    const ctx = context.current;
    const size = calque.source.data.height;
    pixHEX.current = getImgData(ctx, size, uv);
    console.log(
      getPixDefinition(pixHEX.current, definitions.current),
      pixHEX.current
    );
  };

  const onMouseOver = (e) => {
    e.stopPropagation();
    setHover(true);
    // document.body.style = "cursor : grab";
    document.body.style = "cursor : crosshair";

    const { uv } = e;
    const ctx = context.current;
    const size = calque.source.data.height;

    pixHEX.current = getImgData(ctx, size, uv);

    let def =
      typeof getPixDefinition(pixHEX.current, definitions.current) === "string"
        ? (definition.current = getPixDefinition(
            pixHEX.current,
            definitions.current
          ))
        : null;

    label(definition.current, layer.current);
  };

  const onMouseOut = (e) => {
    e.stopPropagation();
    setHover(false);
    document.body.style = "cursor : auto;";
    definition.current = "";
    label(definition.current, layer.current);
  };

  // LAYER CREATION
  const uTexBlank = useMemo(
    () => SetTextureBlank(modelFileName),
    [modelFileName]
  );

  const uTextTest = useMemo(
    () => LoadTexture(modelFileName, "iconographie", "png", "linear"),
    [modelFileName]
  );

  // const uTexIconographie = useMemo(
  //   () => LoadTexture(modelFileName, "iconographie", "png", "linear"),
  //   [modelFileName]
  // );

  // const uTexMaterialite = useMemo(
  //   () => LoadTexture(modelFileName, "materialite", "png", "linear"),
  //   [modelFileName]
  // );
  // const uTexBase = useMemo(
  //   () => LoadTexture(modelFileName, "base", "jpg", "rgb"),
  //   [modelFileName]
  // );

  const [uTexIconographie, uTexMaterialite, uTexBase] = [
    { layer: "iconographie", ext: "png", encoding: "linear" },
    { layer: "materialite", ext: "png", encoding: "linear" },
    { layer: "base", ext: "jpg", encoding: "rgb" },
  ].map((item) =>
    SetTexture(modelFileName, item.layer, item.ext, item.encoding)
  );

  // const [uTexIconographie, uTexMaterialite, uTexBase] = [
  //   { layer: "iconographie", ext: "png", encoding: "linear" },
  //   { layer: "materialite", ext: "png", encoding: "linear" },
  //   { layer: "base", ext: "jpg", encoding: "rgb" },
  // ].map((item) =>
  //   useSetTexture(modelFileName, item.layer, item.ext, item.encoding)
  // );

  const [currentTextures, setCurrentTextures] = useState({
    uTexIconographie: uTexIconographie,
    uTexMaterialite: uTexMaterialite,
    uTexBase: uTexBase,
  });

  let textureTest = [{ layer: "iconographie", ext: "png", encoding: "linear" }];

  useLayoutEffect(() => {
    console.log(`changement de modèle : ${modelFileName}`);
  }, [modelFileName]);

  // CONTROLS
  const {
    wireframe,
    opacity,
    myBaseColor,
    flatShading,
    myBackgroundColor,
    mixFactor,
    calque,
  } = useControls("Modèle", {
    mixFactor: {
      label: "mix",
      value: 1.0,
      min: 0.0,
      max: 1.0,
      step: 0.01,
    },
    calque: {
      options: {
        base: uTexBlank,
        iconographie: uTexIconographie,
        matérialité: uTexMaterialite,
      },
    },
    myBaseColor: {
      label: "Couleur",
      value: "#ffffff",
    },
  });

  const [{ lyr }, setCalque] = useControls(
    () => ({
      lyr: {
        options: {
          base: uTexBlank,
          iconographie: uTexIconographie,
          matérialité: uTexMaterialite,
        },
      },
    }),
    [modelFileName]
  );

  //

  //useLayoutEffect(() => {}, []);

  //console.log(textureTest);

  // Loader
  const model = useGLTF(pathToModel, pathToDraco);
  const { nodes } = model;

  const listMeshes = Object.values(nodes).filter((node) => node.isMesh);
  const group = useRef();
  const meshRef = useRef();

  const cameraControlsRef = useRef();

  useEffect(() => {
    // const bBox = new THREE.Box3().setFromObject(group.current);
    // const bSphere = bBox.getBoundingSphere(new THREE.Sphere());

    cameraControlsRef.current.fitToSphere(group.current, true);
  }, [modelFileName]);

  const canvasRef = useRef(document.getElementById("picking-canvas"));

  //const canvasRef = useRef(document.createElement("canvas"));
  //canvasRef.current.height = 256;
  //canvasRef.current.width = 256;
  //canvasRef.current.setAttribute("id", "picking-canvas");
  //canvasRef.current.id = "picking-canvas";

  // console.log(canvasRef.current);

  const context = useRef(
    canvasRef.current.getContext("2d", { willReadFrequently: true })
  );

  const definitions = useRef();
  const definition = useRef();
  const layer = useRef();
  const pixHEX = useRef();

  useCanvas(context, calque, myBaseColor);

  const textureRef = useRef();
  const materialRef = useRef();

  //
  const OBR = useOnBeforeRender(calque.uuid, modelFileName);

  //

  // ShaderReplacement before compile
  const OBC = useOnBeforeCompile(
    vertexShaderReplacements,
    fragmentShaderReplacements,
    calque,
    uTexBase,
    uTexBlank,
    mixFactor,
    myBaseColor
  );

  //  Material
  const material = useMemo(() => {
    const m = new THREE.MeshStandardMaterial();
    m.onBeforeCompile = OBC;

    return m;
  }, [OBC, modelFileName]);

  // useLayoutEffect(
  //   () =>
  //     void ((materialRef.current.needsUpdate = true),
  //     (materialRef.current.onBeforeCompile = OBC)),
  //   [OBC, modelFileName]
  // );

  // useEffect(
  //   () =>
  //     void ((materialRef.current.needsUpdate = true),
  //     (materialRef.current.onBeforeCompile = OBC)),
  //   [OBC, modelFileName, uTexBase]
  // );

  // useLayoutEffect(
  //   () =>
  //     void ((materialRef.current.needsUpdate = true),
  //     (materialRef.current.onBeforeCompile = OBC)),
  //   [OBC, modelFileName]
  // );
  useEffect(
    () =>
      void (materialRef.current.dispose(),
      // (materialRef.current.uniformsNeedUpdate = true),
      (materialRef.current.needsUpdate = true),
      (materialRef.current.onBeforeCompile = OBC)),
    [OBC]
  );

  //
  function setLayerToDef(calque) {
    layer.current = calque.name.split("_")[1].toString();
    switch (layer.current) {
      case "iconographie":
        definitions.current = iconographie;
        break;
      case "materialite":
        definitions.current = materialite;
        break;
      default:
        definitions.current = null;
    }
  }

  //
  // Set Datas according current layer
  useEffect(() => {
    console.log(uTexIconographie);
    setLayerToDef(calque);
    let palette = getPalette(context.current, calque.source.data.height);
    let currentDefinitions = getDefinitions(palette, definitions.current);
    list(currentDefinitions, layer.current);
    !hover ? label("", layer) : null;
    console.log("calque :", calque.name, "icono : ", uTexIconographie.name);
  }, [calque.uuid, modelFileName]);

  useEffect(() => {
    setCalque((l) => state.l);
    console.log(
      "icono :",
      uTexIconographie.name,
      "mat :",
      uTexMaterialite.name,
      "lyr :",
      lyr.name
    );
  }, [modelFileName, lyr]);

  return (
    <group
      {...props}
      ref={group}
      name={modelFileName}
      dispose={null}
      onDoubleClick={eventHandler}
      onPointerMove={onMouseOver}
      onPointerOver={() => setHover(true)}
      //onPointerOver={() => (document.body.style = "cursor : grab")}
      // onPointerDown={() => (document.body.style = "cursor : grabbing;")}
      onPointerOut={onMouseOut}
    >
      <CameraControls ref={cameraControlsRef} />
      {listMeshes.map((mesh, index) => (
        <mesh
          ref={meshRef}
          castShadow
          receiveShadow
          key={index}
          name={mesh.name}
          geometry={mesh.geometry}
          position={mesh.position}
          rotation={mesh.rotation}
          onBeforeRender={OBR}
        >
          {/* <primitive
            attach="material"
            object={material}
            ref={textureRef}
          ></primitive> */}
          <meshStandardMaterial
            name={`${modelFileName}_mat`}
            ref={materialRef}
            color={myBaseColor}
            onBeforeCompile={OBC}
            needsUpdate
          />
        </mesh>
      ))}
    </group>
  );
}
