import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import useQueries from "../../hooks/useQueries";
import useQueriesAPI from "../../hooks/useQueriesAPI";

import { getAnnotedFragments } from "../../../outils";
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { CameraControls, Sphere, Text, useCursor } from "@react-three/drei";
import ModelPreview from "../fragments/modelPreview";

import MyLight from "../fragments/myLight";

const Code = (props) => {
  const { code } = useParams();
  const { couche } = props;
  const navigate = useNavigate();

  const coucheID = {
    materialite: "ff",
    iconographie: "00",
  };

  const notation = `#${code}${coucheID[couche]}`;

  /**
   *  useQueries
   */

  const {
    data: dataFragments,
    isSuccess: isSuccessFragments,
    isLoading: isLoadingFragments,
  } = useQueries(`/json/annotationsParFragment.json`);

  const {
    data: dataDefinitions,
    isSuccess: isSuccessDefinitions,
    isLoading: isLoadingDefinitions,
  } = useQueriesAPI(`palettes/${couche}`);

  const {
    data: fragments,
    isSuccess: isSuccess,
    isLoading: isLoading,
  } = useQueriesAPI(`fragments`);

  /**
   *
   */

  const annotations = isSuccessFragments && dataFragments[couche];
  const definitions = isSuccessDefinitions && dataDefinitions;

  const definition =
    isSuccessDefinitions &&
    dataDefinitions &&
    definitions?.find((d) => d.hex === `#${code}`);

  const res = annotations && getAnnotedFragments(notation, annotations);

  const currentFragments =
    isSuccess &&
    fragments?.filter((f, i) => res?.includes(f.numero_inventaire_3d));

  const Rig = () => {
    const cameraControlsRef = useRef();
    const group = useRef();
    const sphere = useRef();
    const { viewport, pointer } = useThree();

    const [trigger, setTrigger] = useState(false);

    const onPointerMissed = (e) => {
      e.stopPropagation();
      setTrigger(!trigger);
    };

    useEffect(() => {
      cameraControlsRef?.current.fitToSphere(sphere?.current, true);
    }, [viewport.aspect, trigger]);

    return (
      <>
        <group
          ref={group}
          onPointerMissed={onPointerMissed}
          // position={[-currentFragments.length / 4, 0, 0]}
        >
          <Sphere
            visible={true}
            ref={sphere}
            args={[currentFragments.length / 4, 16, 8]}
            position={[currentFragments.length / 4, 0, 0]}
          >
            <meshBasicMaterial wireframe visible={false} />
          </Sphere>
          {currentFragments &&
            currentFragments.map((f, i) => (
              <Frame
                key={i}
                index={i}
                length={currentFragments.length}
                filename={f.numero_inventaire_3d}
                cellSize={0.75}
                rotationFragment={f.rotation_xyz}
              />
            ))}
        </group>
        {/* <Text>Test</Text> */}
        <CameraControls ref={cameraControlsRef} />
      </>
    );
  };

  const Frame = (props) => {
    const { filename, cellSize, rotationFragment } = props;
    const [hovered, setHovered] = useState(false);

    let numero = filename.replace("-", ",");

    useCursor(hovered);

    const handleDoubleClick = (e) => {
      // let filename = e.eventObject.filename;
      // let numero = filename.replace("-", ",");

      navigate(`/fragments/${numero}`);
    };
    return (
      <group
        {...props}
        position={[props.index * 0.5, 0, 0]}
        onDoubleClick={handleDoubleClick}
        onPointerEnter={() => setHovered(true)}
        onPointerLeave={() => setHovered(false)}
      >
        <ModelPreview
          filename={filename}
          hovered={hovered}
          cellSize={cellSize}
          rotationFragment={rotationFragment}
        />

        <Text
          fontSize={0.075}
          anchorY="bottom"
          position={[0, -cellSize / 1.75, 0]}
          color={hovered ? "orange" : "grey"}
        >
          {numero}
        </Text>
      </group>
    );
  };

  const MyCanvas = () => {
    return (
      <Canvas
        id="scene3D"
        flat
        shadows
        dpr={[1, 2]}
        camera={{
          fov: 5,
          near: 0.01,
          far: 350,
          position: [0, 0, 40],
        }}
      >
        {/* <Suspense fallback={null}> */}
        <MyLight />
        <ambientLight intensity={0.25} />
        {/* </Suspense> */}

        <Rig />
      </Canvas>
    );
  };

  return (
    isSuccess &&
    isSuccessDefinitions &&
    isSuccessFragments && (
      <>
        {definition ? (
          <div className="entete">{definition.descripteur}</div>
        ) : null}
        <MyCanvas />
      </>
    )
  );
};

export default Code;
