import React, { useMemo, useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";

import useModelStore from "../../../../stores/useModelStore";

import { multiFilter } from "../../../../outils";

const SearchField = (props) => {
  const { id, options, field, width } = props;

  const { filter, setFilter, setCurrentInput } = useModelStore();

  const [value, setValue] = useState();
  const [input, setInput] = useState();

  function simplify(item, separator, index, length) {
    let leftPart = item.split(separator)[index];
    return String(leftPart).padStart(length, "0");
  }

  function sortAlpha(a, b) {
    if (a < b) return -1;
    else if (a > b) return 1;
    else return 0;
  }

  const myOptions = useMemo(() => {
    // return options.sort(sortAlpha);
  }, []);

  const handleSearchChange = (e, value) => {
    setFilter({ ...filter, [field]: value });
  };

  //////////////////////////////////////////////////////
  //
  // pas possible de rendre synchrone la saisie de
  // la requete et l'affichage des résultats
  //
  // useEffect(() => {
  //   // let myInput = { field: field, input: input };
  //   // setCurrentInput({ field: field, input: input });
  // }, [input]);

  // const handleInputChange = (e, value) => {
  //   let input = value;
  //   setInput(input);
  //   // console.log(value);
  //   // setCurrentInput(value);
  // };
  //////////////////////////////////////////////////////

  return (
    <Autocomplete
      id={id}
      autoSelect
      sx={{ width: `${width}px` }}
      options={options}
      noOptionsText="aucune valeur pour cette combinaison"
      clearText="effacer"
      onChange={(e, value) => handleSearchChange(e, value)}
      // onInputChange={(e, value) => handleInputChange(e, value)}
      value={filter[field]}
      renderInput={(params) => <TextField {...params} {...props} />}
    />
  );
};

export default SearchField;
