import React from "react";
import { Tabs, Tab } from "@mui/material";

const FragmentTabs = (props) => {
  const { value, handleChange } = props;
  const couches = [
    // { label: "base" },
    { label: "matérialité", value: "materialite" },
    { label: "iconographie", value: "iconographie" },
  ];
  return (
    <Tabs onChange={handleChange} value={value}>
      {couches.map((couche, index) => (
        <Tab key={index} label={couche.label} value={couche.value} />
      ))}
    </Tabs>
  );
};

export default FragmentTabs;
