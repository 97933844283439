import React from "react";
import * as THREE from "three";
import { Html } from "@react-three/drei";

const Label = (props) => {
  const { name } = props;

  function calculatePosition(el, camera, size) {
    let obj = el.parent.children[0];

    // console.log(el);

    const center = new THREE.Vector3();

    const box = new THREE.Box3().setFromObject(obj);
    const objectPos = box.getCenter(center);
    objectPos.project(camera);

    const widthHalf = size.width / 2;
    const heightHalf = size.height / 2;

    return [
      Math.min(
        size.width - 100,
        Math.max(0, objectPos.x * widthHalf + widthHalf)
      ),
      Math.min(
        size.height - 50,
        Math.max(0, -(objectPos.y * heightHalf) + heightHalf)
      ),
    ];
  }

  return (
    <Html
      calculatePosition={calculatePosition}
      center
      style={{ pointerEvents: "none" }}
      className="monument-annotation"
    >
      {props?.title || name}
    </Html>
  );
};

export default Label;
