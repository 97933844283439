import React, { useEffect, useState } from "react";
import { XMLParser } from "fast-xml-parser";

const parserOptions = {
  trim: true,
};
const parser = new XMLParser();

const useOpenthesoAPI = (params) => {
  const [theso, setTheso] = useState();
  const [loading, setLoading] = useState(false);

  const { idc, idt, direction } = params;

  let path = "https://opentheso.huma-num.fr/opentheso/api/";
  let url = `expansion/concept?theso=${idt}&id=${idc}&way=${direction}`;

  useEffect(() => {
    setLoading(() => true);

    try {
      fetch(`${path}${url}`)
        .then((res) => res.text())
        .then((text) => parser.parse(text, parserOptions))
        .then((data) => data[`rdf:RDF`][`rdf:Description`])
        .then((returnData) => {
          if (Array.isArray(returnData))
            setTheso(
              Object.keys(returnData).map((d) => {
                return {
                  id: returnData[d][`dcterms:identifier`],
                  label: returnData[d][`skos:prefLabel`],
                };
              })
            );
          else
            setTheso([
              {
                id: returnData[`dcterms:identifier`],
                label: returnData[`skos:prefLabel`],
              },
            ]);
        })
        .finally(setLoading(() => false));
    } catch (e) {
      console.log(e);
    }
  }, [idc, idt, direction]);

  return theso;
};

export default useOpenthesoAPI;
