import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, Link as RouterLink, useParams } from "react-router-dom";
import { useLocation as useWocation, useRoute as useWoute } from "wouter";
import useHashLocation from "../../hooks/useHashLocation";

import {
  Link,
  Breadcrumbs,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import { stations } from "../../chapitres/tholos/data";

import useQueriesAPI from "../../hooks/useQueriesAPI";

const MyBreadcrumbs = () => {
  const { pathname } = useLocation();
  const [loc] = useWocation();
  const [locHash] = useHashLocation();

  let segments = toSegments(pathname);

  const couches = {
    iconographie: { title: "iconographie", suffixe: "00" },
    materialite: { title: "matérialité", suffixe: "ff" },
  };

  const { data: materialite, isSuccess: isSuccessMaterialite } = useQueriesAPI(
    "palettes/materialite"
  );

  const { data: iconographie, isSuccess: isSuccessIconographie } =
    useQueriesAPI("palettes/iconographie");

  function toSegments(path) {
    return path.split("/").filter((x) => x);
  }

  function toCleanSegments(path, correspondances) {
    let pathArray = path
      .split("/")
      .filter((x) => x)
      .map((x) => correspondances.find((y) => y.id === x)?.title || x);

    return pathArray;
  }

  function convertSegmentHex(path, correspondances) {
    let oldSegments = toSegments(path);
    let length = oldSegments.length;

    if (length === 3) {
      let code = oldSegments[length - 1];
      let def = correspondances?.find((x) => x.hex === `#${code}`);

      let newSegments = [
        ...oldSegments.slice(0, length - 1),
        def?.descripteur || "rien",
      ];

      segments = newSegments;
      // console.log("new :", segments);
    } else {
      segments = oldSegments;
      // console.log("old :", segments);
    }

    return segments;
  }

  function initBreadcrumbs() {
    switch (segments[0]) {
      case "tholos":
        import.meta.env.VITE_NODE_ENV === "production"
          ? (segments = toCleanSegments(locHash, stations))
          : (segments = toCleanSegments(loc, stations));

        break;
      case "fragments":
        if (segments[1] === "materialite") {
          segments = convertSegmentHex(pathname, materialite);
        }
        if (segments[1] === "iconographie") {
          segments = convertSegmentHex(pathname, iconographie);
        }
        break;

      default:
        segments = toSegments(pathname);
    }
  }

  isSuccessIconographie && isSuccessMaterialite && initBreadcrumbs();

  const LinkHome = (props) => {
    return (
      <Button
        {...props}
        component={RouterLink}
        sx={{
          color: "white",
          pointerEvents: "auto",
        }}
        startIcon={<AccountBalanceIcon />}
      >
        Accueil
      </Button>
    );
  };

  const LinkRouter = (props) => {
    return (
      <Typography
        variant="button"
        sx={{ pointerEvents: "auto" }}
        {...props}
        component={RouterLink}
        underline="none"
      ></Typography>
    );
  };

  return (
    isSuccessIconographie &&
    isSuccessMaterialite && (
      <Breadcrumbs separator="|">
        <LinkHome to={"/"} />
        {segments.map((segment, index) => {
          const last = index === segments.length - 1;
          const to = `/${segments.slice(0, index + 1).join("/")}`;
          return last ? (
            <Typography key={to} variant="button">
              {segment}
            </Typography>
          ) : (
            <LinkRouter to={to} key={to}>
              {segment}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
    )
  );
};

export default MyBreadcrumbs;
