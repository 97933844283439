import React from "react";
import { Text } from "@react-three/drei";

const FragmentDummy = (props) => {
  const { filename } = props;
  return (
    <>
      <mesh
        // ref={octaMeshRef}
        name={`${filename}_octahedron`}
        visible={true}
      >
        <octahedronGeometry args={[0.04, 1]} />
        <meshBasicMaterial
          wireframe
          // ref={octaMatRef}
          transparent
          opacity={1}
        />
      </mesh>
      <Text>{filename}</Text>
    </>
  );
};
export default FragmentDummy;
