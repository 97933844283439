import { XMLParser } from "fast-xml-parser";
import { list } from "../components/ui";

const parserOptions = {
  trim: true,
};
const parser = new XMLParser();
// const path = "https://opentheso.huma-num.fr/opentheso/api/";

const fetchMultipleOpentheso = async (listParams) => {
  let result = [];
  let path = "https://opentheso.huma-num.fr/opentheso/api/";
  Promise.all(
    listParams.map(async (param) => {
      let url = `${path}${param.idt}.${param.idc}`;
      const resp = await fetch(url);
      if (!resp.ok) {
        throw new Error("pb coté fetch");
      }

      //   await console.log(resp.text());
      //   .then(
      //     (res) => res.text()
      //   );
      // .then((text) => parser.parse(text, parserOptions))
      // .then((raw) => raw[`rdf:RDF`][`rdf:Description`])
      // .then((data) => {
      //   let myData = {
      //     idc: data[`dcterms:identifier`],
      //     creator: data[`dcterms:creator`],
      //     definition: data[`skos:definition`],
      //     label: data[`skos:prefLabel`],
      //     notation: data[`skos:notation`],
      //   };
      //   result.push(myData);
      // });
      result.push(await resp.text());
      //   return await resp.text();
    })
  );
  await console.log(result);
  return result;
};

// async function fetchMultipleOpentheso(listParams) {
//   let path = "https://opentheso.huma-num.fr/opentheso/api/";

//   const fetchPromises = listParams.map((params) =>
//     fetch(`${path}${params.idt}.${params.idc}`)
//   );

//   let result = [];

//   Promise.all(fetchPromises)
//     .then((responses) =>
//       Promise.all(responses.map((response) => response.text()))
//     )
//     .then((texts) =>
//       Promise.all(texts.map((text) => parser.parse(text, parserOptions)))
//     )
//     .then((raws) =>
//       Promise.all(raws.map((raw) => raw[`rdf:RDF`][`rdf:Description`]))
//     )
//     .then((datas) =>
//       Promise.all(
//         datas.map((data) => {
//           let myData = {
//             idc: data[`dcterms:identifier`],
//             creator: data[`dcterms:creator`],
//             definition: data[`skos:definition`],
//             label: data[`skos:prefLabel`],
//             notation: data[`skos:notation`],
//           };
//           result.push(myData);
//         })
//       )
//     )

//     .catch((error) => {
//       console.log(error);
//     });

//   return result;
// }

export default fetchMultipleOpentheso;
