import React, { useState, Suspense } from "react";
import {
  TextField,
  Autocomplete,
  Box,
  createFilterOptions,
} from "@mui/material";
import useModelStore from "../../stores/useModelStore";

const SelectModel = (props) => {
  const modelsList = useModelStore((state) => state.modeles);
  const currentModel = useModelStore((state) => state.currentModel);
  const setCurrentModel = useModelStore((state) => state.setCurrentModel);

  const fragments = useModelStore((state) => state.fragments);
  const currentFragment = useModelStore((state) => state.currentFragment);
  const setCurrentFragment = useModelStore((state) => state.setCurrentFragment);

  const [value, setValue] = useState(currentModel);

  const handleSelectModel = (e, updatedValue) => {
    //setCurrentModel(updatedValue);
    setCurrentFragment(updatedValue);
  };

  const handleInputChange = (e, updatedValue) => {
    setCurrentModel();
    setValue(updatedValue);
  };

  // Alphabetic Sorting

  function simplify(item) {
    let leftPart = item.split(",")[0];
    return String(leftPart).padStart(5, "0");
  }

  function sortAlpha(a, b) {
    if (simplify(a.numero_inventaire_apo) < simplify(b.numero_inventaire_apo))
      return -1;
    else if (
      simplify(a.numero_inventaire_apo) > simplify(b.numero_inventaire_apo)
    )
      return 1;
    else return 0;
  }

  //

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mx: 1 }}
    >
      <Suspense fallback={null}>
        <Autocomplete
          id="SelectModel"
          autoSelect
          disableClearable
          //
          // options={modelsList.sort(sortAlpha2)}
          // isOptionEqualToValue={(option) => option.label}
          // value={currentModel}
          //
          options={fragments
            .filter((item) => item.gltf === true)
            .sort(sortAlpha)}
          getOptionLabel={(option) =>
            option.id ? option.numero_inventaire_apo : ""
          }
          isOptionEqualToValue={(option) =>
            option.id ? option.numero_inventaire_apo : ""
          }
          value={currentFragment}
          //
          // getOptionLabel={(option) => option.file}
          // renderOption={(option) => option.label}

          // inputValue={value.label}
          onChange={handleSelectModel}
          //onInputChange={handleInputChange}
          sx={{ width: 120, position: "middle" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="n° inv."
              margin="dense"
              size="medium"
            />
          )}
        />
      </Suspense>
    </Box>
  );
};

export default SelectModel;
