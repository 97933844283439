import React, { useState, useEffect } from "react";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeItem2, TreeItem2Content, TreeItem2Label } from "@mui/x-tree-view";

import useTableOfContent from "../../hooks/useTableOfContent";

const TableDesMatieres = (props) => {
  const { style } = props;
  const toc = useTableOfContent();

  const CustomTreeItem = React.forwardRef((props, ref) => {
    const { label, itemId, ...othersProps } = props;

    return (
      <TreeItem2
        ref={ref}
        {...othersProps}
        label={<div dangerouslySetInnerHTML={{ __html: label }} />}
        itemId={itemId}
        id={itemId}
      />
    );
  });

  return toc ? (
    <RichTreeView items={toc} slots={{ item: CustomTreeItem }} sx={style} />
  ) : null;
};

export default TableDesMatieres;
