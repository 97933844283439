import React from "react";

const Statistiques = (props) => {
  const { fragments } = props;

  return (
    <div id="label">
      <b>{fragments.length}</b> fragments dans la BDD.
      <br />
      <b>{fragments.filter((item) => item.photogrammetrie).length}</b> fragments
      photographiés.
      <br />
      <b>{fragments.filter((item) => item["MODELES 3D PLY"]).length}</b>{" "}
      fragments en 3D.
      <br />
      <b>{fragments.filter((item) => item.gltf === true).length}</b> fragments
      en 25K faces.
      <br />
      <b>{fragments.filter((item) => item.gltf_5k === true).length}</b> previews
      en 5K faces.
    </div>
  );
};

export default Statistiques;
