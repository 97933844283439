import React, { useMemo } from "react";
import {
  SetTexture,
  SetTextureBlank,
  LoadTexture,
  SetNormalMap,
} from "./setTextures";
import useModelStore from "../../stores/useModelStore";

const CreateLayers = () => {
  const modelFileName = useModelStore(
    (state) => state.currentFragment.numero_inventaire_3d
  );

  // LAYER CREATION
  const uTexBlank = useMemo(
    () => SetTextureBlank(modelFileName),
    [modelFileName]
  );

  const [uTexIconographie, uTexMaterialite, uTexBase] = [
    { layer: "iconographie", ext: "png", encoding: "linear" },
    { layer: "materialite", ext: "png", encoding: "linear" },
    { layer: "base", ext: "jpg", encoding: "rgb" },
  ].map((tex, index) =>
    SetTexture(modelFileName, tex.layer, tex.ext, tex.encoding)
  );

  // const normalMap = SetNormalMap(modelFileName, "normal", "png");
  return { uTexBase, uTexBlank, uTexIconographie, uTexMaterialite };
};

export default CreateLayers;
