import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Architrave(props) {
  const group = useRef();
  // const pathToDraco = `/draco/`;
  const { nodes, materials, animations } = useGLTF(
    "/modeles/tholos/architrave.glb"
  );
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null} name="architrave">
      <mesh
        name="architrave002"
        castShadow
        receiveShadow
        geometry={nodes.architrave002.geometry}
        material={materials.marbre}
        position={[0, 6.719, 0]}
        scale={0.669}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/architrave.glb");
