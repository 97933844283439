import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { subscribeWithSelector } from "zustand/middleware";

// remplacer les fetch par des useQueries dans le composant local

const baseUrl = import.meta.env.VITE_SERVER_API;

const useDefinitionStore = create((set) => ({
  activeColor: { materialite: "ffffff", iconographie: "ffffff" },
  setActiveColor: (color, couche) =>
    set((state) => ({
      activeColor: { ...state.activeColor, [couche]: color },
    })),
  previousColor: { materialite: "ffffff", iconographie: "ffffff" },
  setPreviousColor: (color, couche) =>
    set((state) => ({
      previousColor: { ...state.previousColor, [couche]: color },
    })),
  currentCouche: "materialite",
  setCurrentCouche: (couche) =>
    set((state) => ({ ...state, currentCouche: couche })),
  iconographie: {},
  setIconographie: (iconographie) =>
    set((state) => ({ ...state, iconographie })),
  noticeActive: false,
  setNoticeActive: (value) =>
    set((state) => ({ ...state, noticeActive: value })),
  annotationActive: false,
  setannotationActive: (value) =>
    set((state) => ({ ...state, annotationActive: value })),

  mouseActive: false,
  setMouseActive: (value) => set((state) => ({ ...state, mouseActive: value })),
}));

fetch(`${baseUrl}/palettes/iconographie`)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error({ name: "Error", message: "pas de retour" });
  })

  .then((iconographie) =>
    useDefinitionStore.setState((state) => ({
      ...state,
      iconographie,
    }))
  )
  .catch((error) => {
    console.log(error);
  });

fetch(`${baseUrl}/palettes/materialite`)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error({ name: "Error", message: "pas de retour" });
  })
  .then((materialite) =>
    useDefinitionStore.setState((state) => ({
      ...state,
      materialite,
    }))
  )
  .catch((error) => {
    console.log(error);
  });

if (import.meta.env.VITE_NODE_ENV === "development") {
  mountStoreDevtool("DefinitionStore", useDefinitionStore);
}

export default useDefinitionStore;
