import { Leva } from "leva";
import { useEffect, useState, useRef } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { useProgress, Html } from "@react-three/drei";
import Canvas2d from "../CanvasElement/canvas2d";
import Viewer from "./viewer.jsx";
import SelectFields from "./selectFields";
import SelectModel from "./selectModel";
import SelectLayer from "./selectLayer";

import CustomLoader from "../model/customLoader";

const today = new Date().toISOString().split("T")[0].replace(/\-/g, ".");

const Canvas3d = () => {
  return (
    <>
      {/* <Leva /> */}
      <header>Visionneuse 3D | Projet THOLOS.</header>
      <div id="label"></div>
      <div id="list">
        <ul id="descripteurs"></ul>
      </div>
      <Canvas
        id="scene3D"
        flat
        shadows
        dpr={[1, 2]}
        camera={{
          fov: 20,
          near: 0.01,
          far: 20,
          position: [0, 0.3, 1.5],
        }}
      >
        <Viewer />

        {/* <Suspense fallback={<CustomLoader />}>
        <Viewer />
      </Suspense> */}

        {/* <color args={['#2d4967']} attach="background"/>  */}
      </Canvas>
      <Canvas2d id="picking-canvas" size={256} />
      {/* <canvas id="picking-canvas" height="256" width="256" /> */}
      <footer>
        <div className="footer">
          <SelectFields />

          <span className="alignLeft">© EfA | ArScAn | archeo3D</span>
          <span className="alignRight">version {today}</span>
        </div>
      </footer>
    </>
  );
};

export default Canvas3d;
