import React from "react";
import { useParams, Outlet, Navigate } from "react-router-dom";
import Thesaurus from "./thesaurus";

const ThesaurusRouter = () => {
  const params = useParams();

  let themes = [
    { label: "matéralité", value: "materialite", id: 2 },
    { label: "iconographie", value: "iconographie", id: 1 },
  ];

  let isTheme = themes.map((t) => t.value).includes(params.theme);

  let currentTheme;

  isTheme
    ? (currentTheme = themes.find((t) => t.value === params.theme))
    : null;

  return isTheme ? (
    <Thesaurus theme={currentTheme} />
  ) : (
    <Navigate to="/thesaurii" />
  );
};

export default ThesaurusRouter;
