import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";

function checkIfImageExists(url) {
  var http = new XMLHttpRequest();
  http.open("HEAD", url, false);
  http.send();
  return http.status != 404;
}

function createTexColorData(color) {
  const uTexColorData = new Uint8Array(4);
  const uTexColor = new THREE.Color(color);

  const r = Math.floor(uTexColor.r * 255);
  const g = Math.floor(uTexColor.g * 255);
  const b = Math.floor(uTexColor.b * 255);

  uTexColorData[0] = r;
  uTexColorData[1] = g;
  uTexColorData[2] = b;
  uTexColorData[3] = 255;

  return uTexColorData;
}

const SetNormalMap = (model, suffix, ext) => {
  let url = `/modeles/${model}/${model}_${suffix}.${ext}`;
  let fetchUrl;
  //let result;
  let checkUrl = checkIfImageExists(url);

  if (!checkUrl) {
    console.log(`pas de normales pour ${model}`);
    fetchUrl = `/modeles/placeholders/normal_blank.png`;
  } else {
    console.log(`normales trouvées pour ${model}`);
    fetchUrl = `/modeles/${model}/${model}_${suffix}.${ext}`;
  }

  useTexture.preload(fetchUrl);
  //const tex = useTexture(fetchUrl);
  //tex.flipY = false;
  //tex.name = `${model}_${suffix}`;
  //result = tex;

  //return tex;
  return fetchUrl;
};

const SetTexture = (model, layer, ext, encoding) => {
  let url = `/modeles/${model}/${model}_${layer}.${ext}`;
  let size = 256;

  let result;

  let checkUrl = checkIfImageExists(url);

  if (checkUrl === false) {
    console.log(`pas de couche ${layer} pour ${model}`);
    let placeHolderUrl = `/modeles/placeholders/${layer}_blank.${ext}`;
    useTexture.preload(placeHolderUrl);
    const tex = useTexture(placeHolderUrl);
    tex.encoding =
      encoding === "linear" ? THREE.LinearEncoding : THREE.sRGBEncoding;
    tex.flipY = false;
    tex.name = `${model}_${layer}`;
    result = tex;
  } else {
    useTexture.preload(url);
    const tex = useTexture(url);
    tex.encoding =
      encoding === "linear" ? THREE.LinearEncoding : THREE.sRGBEncoding;
    tex.flipY = false;
    tex.name = `${model}_${layer}`;
    result = tex;
  }

  return result;
};

const LoadTexture = (model, layer, ext, encoding) => {
  const loader = new THREE.TextureLoader();
  var tex = loader.load(
    `/modeles/${model}/${model}_${layer}.${ext}`,
    function (texture) {
      texture.encoding =
        encoding === "linear" ? THREE.LinearEncoding : THREE.sRGBEncoding;
      texture.flipY = false;
      texture.name = `${model}_${layer}`;
      texture.needsUpdate = true;
      return texture;
    }
  );
  return tex;
};

const useSetTexture = (model, layer, ext, encoding) => {
  return useEffect(() => {
    const tex = useTexture(`/modeles/${model}/${model}_${layer}.${ext}`);

    tex.encoding =
      encoding === "linear" ? THREE.LinearEncoding : THREE.sRGBEncoding;

    tex.flipY = false;
    tex.name = `${model}_${layer}`;
    return tex;
  }, [model, layer]);
};

function SetTextureBlank(model) {
  const tex = new THREE.DataTexture(createTexColorData(0xffffff), 1, 1);
  tex.name = `${model}_blank`;
  tex.needsUpdate = true;
  return tex;
}

export {
  SetTextureBlank,
  SetTexture,
  useSetTexture,
  LoadTexture,
  SetNormalMap,
};
