import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Triglyphe(props) {
  const { dimension, index, geometry } = props;
  const group = useRef();
  const { nodes, materials } = useGLTF(
    `/modeles/tholos/${dimension}_triglyphe.glb`
  );
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        name={`${dimension}-triglyphe_${index}_mesh`}
        castShadow
        receiveShadow
        geometry={nodes[`${geometry}`].geometry}
        material={materials.marbre}
        position={[0, 6.694, 0]}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/grand_triglyphe.glb");
