import React, { useState, useRef, useEffect } from "react";
import Head from "./head";
import Paragraphe from "./paragraphe";
import { Typography, Box } from "@mui/material";
import { isArray } from "../../../outils";

const Section3 = (props) => {
  // const textRef = useRef();
  // useEffect(() => {
  //   console.log(textRef.current);
  // }, []);

  return (
    <Box
      sx={{ mb: 1 }}
      key={props[`@_xml:id`]}
      id={props[`@_xml:id`]}
      type={props[`@_type`]}
    >
      {props.head ? <Head text={props.head} variant="overline" /> : null}
      {props.p && isArray(props.p) ? (
        props.p.map((paragraphe, i) => (
          <Paragraphe
            // ref={textRef}
            key={paragraphe[`@_xml:id`]}
            text={paragraphe[`#text`]}
            id={paragraphe[`@_xml:id`]}
          />
        ))
      ) : props.p ? (
        <Paragraphe
          // ref={textRef}
          key={props.p[`@_xml:id`]}
          text={props.p[`#text`]}
          id={props.p[`@_xml:id`]}
        />
      ) : null}
    </Box>
  );
};

export default Section3;
