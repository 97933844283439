import React from "react";
import { useRoute, useLocation, Route } from "wouter";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Button,
  Toolbar,
  Stack,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import Paragraphe from "../../notices/paragraphe";

import { truncateSentence } from "../../../../outils";

const CardText = (props) => {
  const { head, p: paragraphes, closeText } = props;

  // const env = import.meta.env.VITE_NODE_ENV;
  // const prefix = {
  //   production: "#",
  //   development: "",
  // };

  const [location, setLocation] = useLocation();

  const handleClick = (e, item) => {
    console.log(location);

    // setLocation(`${location}/${item}`);
    // setLocation(`${prefix[env]}/tholos/${item}`);
    // setLocation(`/tholos/${item}`);
    setLocation(`/${item}`);
  };

  const handleClose = () => {
    // console.log("close");
    setLocation("/tholos");
  };

  const StyleContent = {
    maxHeight: 500,
    pr: 2,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#00000050",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "00000050",
      backgroundColor: "#555",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#888",
    },
  };

  //   <IconButton size="small" onClick={handleClose}>
  //   <CloseIcon />
  // </IconButton>

  return (
    <Box
      sx={{
        position: "absolute",
        width: 450,
        maxHeight: 500,
        // overflowY: "scroll",
      }}
    >
      <Card>
        <CardHeader
          title={<Typography variant="h6">{head}</Typography>}
          action={
            <IconButton size="small" onClick={closeText}>
              <CloseIcon />
            </IconButton>
          }
        ></CardHeader>
        <CardContent>
          <Box sx={StyleContent}>
            {paragraphes?.map((p, i) => (
              <Paragraphe key={i} id={p.id} text={p[`#text`]} />
            ))}
          </Box>
        </CardContent>
        <CardActions>
          <Box>
            {props?.div &&
              props?.div.map((div, i) => (
                <Button
                  key={i}
                  size="small"
                  onClick={(e) => handleClick(e, div.subtype)}
                >
                  {truncateSentence(div.head, 4)}
                </Button>
              ))}
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default CardText;
