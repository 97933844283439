import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

const baseUrl = import.meta.env.VITE_SERVER_API;

const useNoticesStore = create((set) => ({
  marcade: {},
}));

fetch(`${baseUrl}/marcade`)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error("pb");
  })
  .then((marcade) =>
    useNoticesStore.setState((state) => ({
      ...state,
      marcade,
    }))
  )
  .catch((error) => {
    console.log(error);
  });

if (import.meta.env.VITE_NODE_ENV === "development") {
  mountStoreDevtool("NoticesStore", useNoticesStore);
}

export default useNoticesStore;
