import React, { useMemo, useState, useRef } from "react";
import * as THREE from "three";
import { useFrame, createPortal } from "@react-three/fiber";
import { useFBO } from "@react-three/drei";

import { vertexShader, fragmentShader } from "../../../shaders/renderTarget";
import getCode from "./getCode";
import { RepartitionRounded } from "@mui/icons-material";
import useDefinitionStore from "../../../stores/useDefinitionStore";

const RenderTarget = (props) => {
  const { map, mouseActive, mouse, couche } = props;
  const size = map.source.data.height;
  const scene = new THREE.Scene();
  scene.name = "RenderTarget";
  const camera = new THREE.OrthographicCamera();
  camera.position.z = 1;

  const renderTarget = useFBO(size, size, {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat,
    type: THREE.UnsignedByteType, // avec new Uint8Array(4)
    generateMipmaps: false,
    colorSpace: THREE.SRGBColorSpace,
  });





  // const [currentColor, setCurrentColor] = useState(new THREE.Color());

  const activeColor = useDefinitionStore(state => state.activeColor)
  const previousColor = useDefinitionStore(state => state.previousColor)
  const noticeActive = useDefinitionStore(state => state.noticeActive)


  const pixel = useMemo(() => {
    return { value: new Uint8Array(4) };
  });

  // const previousPixel = useMemo(() => {
  //   return { value: new Uint8Array(4) };
  // });

  // const previousPixel = { value: new Uint8Array(4) };
  const previousPixel = useRef(new Uint8Array(4));
  const color = new THREE.Color();
  const colorRef = useRef(new THREE.Color());
  const previousColorRef = useRef(new THREE.Color());

  // const activeColor = useMemo(() => {
  //   return new THREE.Color();
  // });
  // const previousColor = new THREE.Color();

  let colorList = [];
  let deltaList = [];

  function normalize(value, min, max) {
    return (value - min) / (max - min);
  }

  useFrame((state, delta) => {
    const { gl, clock } = state;

    if (mouseActive) {

      if(!noticeActive){        
      const { x, y } = mouse.value;
      let cursor = {
        x: Math.floor(size * x) + 0.5,
        y: Math.floor(size * (1 - y)) + 0.5,
      };

      camera.setViewOffset(size, size, cursor.x, cursor.y, 1, 1);
      gl.setRenderTarget(renderTarget);
      gl.clear();
      gl.render(scene, camera);
      gl.readRenderTargetPixels(renderTarget, 0, 0, 1, 1, pixel.value);
      let [r, g, b, a] = pixel.value;
      let [R, G, B] = [r, g, b].map((comp) => normalize(comp, 0, 255));

      colorRef.current.fromArray([R, G, B]);

      if (
        colorRef.current.r !== previousColorRef.current.r ||
        colorRef.current.g !== previousColorRef.current.g ||
        colorRef.current.b !== previousColorRef.current.b
      ) {
        getCode(colorRef.current, previousColorRef.current, couche);
      }

      previousColorRef.current.copy(colorRef.current);

      gl.setRenderTarget(null);
    }
  } 
  
  // else {
  //     colorRef.current.set(0, 0, 0);
  //     previousColorRef.current.set(0, 0, 0);
    
  //   }   
      
  }


);


  const uniforms = {
    uCouche: { value: map },
    uColor : {value : colorRef.current },
    uNoticeActive : {value : noticeActive ? 1.0 : 0.0}
  };

  return (
    <>
      {createPortal(
        <mesh name="RenderTarget_mesh">
          <planeGeometry args={[2, 2, 2, 2]} name="RenderTarget_geom" />
          <shaderMaterial
            fragmentShader={fragmentShader}
            vertexShader={vertexShader}
            uniforms={uniforms}
            needsUpdate={true}
          />
        </mesh>,
        scene
      )}
    </>
  );
};

export default RenderTarget;
