import { Canvas, useThree } from "@react-three/fiber";
import Canvas2d from "../CanvasElement/canvas2d";
import Viewer_sansLeva from "./viewer_sansLeva";
import SelectFields from "./selectFields";
import { Link } from "react-router-dom";

import CustomLoader from "../model/customLoader";

const today = new Date().toISOString().split("T")[0].replace(/\-/g, ".");

const Canvas3d_sansLeva = () => {
  return (
    <>
      {/* <div id="retour" style={{ margin: "5px" }}>
        <Link to="/">retour</Link>
      </div>
      <header>Visionneuse 3D | Projet THOLOS.</header> */}
      <div id="label"></div>
      <div id="list">
        <ul id="descripteurs"></ul>
      </div>
      <div id="scene3D" className="webgl">
        <Canvas
          flat
          shadows
          dpr={[1, 2]}
          camera={{
            fov: 20,
            near: 0.01,
            far: 50,
            position: [0, 0.3, 1.5],
          }}
        >
          <Viewer_sansLeva name="Viewer 3D sans Leva" />
        </Canvas>
      </div>
      <Canvas2d id="picking-canvas" size={256} />

      <footer>
        <div className="footer">
          <SelectFields />
          <span className="alignLeft">© EfA | ArScAn | archeo3D</span>
          <span className="alignRight">version {today}</span>
        </div>
      </footer>
    </>
  );
};

export default Canvas3d_sansLeva;
