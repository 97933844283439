import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Mur(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/modeles/tholos/mur.glb");

  return (
    <group ref={group} {...props} dispose={null} name="mur">
      <mesh
        name="mur001"
        castShadow
        receiveShadow
        geometry={nodes.mur001.geometry}
        material={materials.marbre}
        position={[0, 1.104, 0]}
        scale={2.017}
      />
    </group>
  );
}

// useGLTF.preload("/modeles/tholos/mur.glb");
