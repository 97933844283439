import React, { useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { Html, useCursor } from "@react-three/drei";

import useDefinitionStore from "../../../../stores/useDefinitionStore";

const Annotation = (props) => {
  const { hex, descripteur, position, couche, setMouseActive } = props;

  let colorID = hex.slice(1, 7);
  let blankID = ["ffffff", "000000", "FFFFFF"];

  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const annotRef = useRef();

  const currentColor = useDefinitionStore((state) => state.activeColor[couche]);
  const annotationActive = useDefinitionStore(
    (state) => state.annotationActive
  );

  const setAnnotationActive = useDefinitionStore(
    (state) => state.setAnnotationActive
  );

  useCursor(hovered);

  const { camera } = useThree();

  const raycaster = useMemo(() => new THREE.Raycaster(), []);
  const point = useMemo(() => new THREE.Vector3().copy(position));
  point.project(camera);
  raycaster.setFromCamera(point, camera);

  //   const intersect = raycaster.intersectObjects();
  // console.log(point);

  const handleClick = (e) => {
    e.stopPropagation();
    console.log(e);
  };

  const handleDoubleClick = (e, hex) => {
    e.stopPropagation();
    navigate(`/fragments/${couche}/${colorID}`);
  };

  const handlePointerMove = (e, hex) => {
    e.stopPropagation();
    let col = hex.slice(1, 7);
    useDefinitionStore.setState((state) => ({
      ...state,
      activeColor: { ...state.activeColor, [couche]: col },
      previousColor: { ...state.previousColor, [couche]: "ffffff" },

      annotationActive: true,
      mouseActive: true,
    }));
    // setMouseActive(true);

    setHovered(true);
  };

  const handlePointerOut = (e) => {
    e.stopPropagation();

    useDefinitionStore.setState((state) => ({
      ...state,
      activeColor: { ...state.activeColor, [couche]: "ffffff" },
      previousColor: { ...state.previousColor, [couche]: "ffffff" },
      annotationActive: false,
      mouseActive: false,
    }));

    // setMouseActive(false);
    setHovered(false);
  };

  // useFrame((state) => {
  //   const { clock } = state;
  // });

  return (
    <Html position={position} ref={annotRef}>
      <div
        onClick={handleClick}
        onDoubleClick={(e) => handleDoubleClick(e, hex)}
        onPointerMove={(e) => handlePointerMove(e, hex)}
        onPointerOut={handlePointerOut}
        style={{
          color: currentColor === colorID ? "orange" : "white",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          padding: "5px 10px",
          margin: "5px 10px",
          borderRadius: "20px",
          maxWidth: "150px",
          visibility: blankID.includes(colorID) ? "hidden" : "visible",
        }}
      >
        {descripteur}
      </div>
    </Html>
  );
};

export default Annotation;
