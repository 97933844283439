import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";

import MyBreadcrumbs from "../breadcrumbs/breadcrumbs";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { styled } from "@mui/material/styles";

const MyAppBar = styled(AppBar)(() => ({
  pointerEvents: "none",
  // overflow: "hidden",
  // "& .MuiModal-backdrop": {
  //   backgroundColor: "rgba(0,0,0,0.0)",
  // },
}));

const Header = (props) => {
  const { handleOpen } = props;

  const HeaderContent = () => {
    const navigate = useNavigate();

    const handleHomeClick = () => {
      navigate("/");
    };
    return (
      <MyAppBar color="transparent" elevation={0}>
        <Toolbar variant="dense">
          <Box sx={{ flexGrow: 1 }}>
            {/* <IconButton size="small" onClick={handleHomeClick} sx={{ mr: 1 }}>
              <AccountBalanceIcon />
            </IconButton> */}
            {/* <Typography
              variant="button"
              sx={{
                color: "white",
                // fontSize: 20
              }}
              component={Link}
              to="/"
            >
              THOLOS
            </Typography> */}
            <MyBreadcrumbs />
          </Box>

          {/* <Box sx={{ flexGrow: 1 }}>
   
          </Box> */}

          <Box sx={{ flexGrow: 0, pointerEvents: "auto" }}>
            <IconButton size="small" onClick={handleOpen}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </MyAppBar>
    );
  };

  return (
    <Box sx={{ position: "absolute", width: "600px" }}>
      <HeaderContent />
    </Box>
  );
};

export default Header;
